import React, { useEffect, useState } from 'react'

import api from '../../services/api'
import { useMobile } from '../../utils/useMobile'
import BackOfficeAdmMobile from './Mobile'
import BackOfficeAdmWeb from './Web'

const BackOfficeAdm = () => {
  const [usersCounters, setUsersCounters] = useState({})
  const fetchUsersCounters = () => {
    api
      .get('/users_counters')
      .then((res) => {
        setUsersCounters(res.data)
        console.log('res.data:', res.data)
      })
      .catch((err) => console.error(err))
  }

  useEffect(() => {
    fetchUsersCounters()
  }, [])

  if (useMobile()) {
    return <BackOfficeAdmMobile usersCounters={usersCounters} />
  } else {
    return <BackOfficeAdmWeb usersCounters={usersCounters} />
  }
}

export default BackOfficeAdm
