import Badge from '@mui/material/Badge'
import { React, useEffect, useState } from 'react'
import { AiFillHome, AiFillPlusCircle } from 'react-icons/ai'
import { BsFillPersonFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'

import { useAuth } from '../../contexts/AuthContext'
import api from '../../services/api'
import { FooterDiv } from './styles'

const Footer = () => {
  const { signed, user } = useAuth()
  const [newPost, setNewPost] = useState(false)
  const [postNumber, setPostNumber] = useState(0)

  const reloadWindow = () => {
    if (window.location.pathname === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      window.location.reload()
    }
  }

  const getUnreadPostsQuantity = () => {
    api
      .get('/check_unread_posts', {
        params: user?.id && { user_id: user.id }
      })
      .then((res) => res.data)
      .then((unreadPosts) => {
        if (unreadPosts) setNewPost(true)
        setPostNumber(unreadPosts)
      })
      .catch(console.log)
  }

  useEffect(() => {
    getUnreadPostsQuantity()
    const intervalId = setInterval(getUnreadPostsQuantity, 1000 * 30)
    return () => clearInterval(intervalId)
  }, [])

  if (signed) {
    return (
      <FooterDiv>
        <Link to="/" onClick={reloadWindow}>
          {newPost ? (
            <Badge color="warning" badgeContent={postNumber} max={20}>
              <AiFillHome size="25" color="white" padding="25" />
            </Badge>
          ) : (
            <AiFillHome size="25" color="white" padding="25" />
          )}
        </Link>
        <Link to="/post/new">
          <AiFillPlusCircle
            size="25"
            color="white"
            padding="25"
          ></AiFillPlusCircle>
        </Link>
        <Link to="/profile">
          <BsFillPersonFill
            size="25"
            color="white"
            padding="25"
          ></BsFillPersonFill>
        </Link>
      </FooterDiv>
    )
  } else {
    return (
      <FooterDiv>
        <Link to="/" onClick={reloadWindow}>
          {newPost ? (
            <Badge color="warning" badgeContent={postNumber} max={20}>
              <AiFillHome size="25" color="white" padding="25" />
            </Badge>
          ) : (
            <AiFillHome size="25" color="white" padding="25" />
          )}
        </Link>
      </FooterDiv>
    )
  }
}

export default Footer
