import React, { useState } from 'react'

import * as styled from './styles'

const Input = ({ type, label, responsive, ...rest }) => {
  const [showPassword, setShowPassword] = useState(false)
  return (
    <styled.InputContainer responsive={responsive}>
      {label && <styled.Label>{label}</styled.Label>}
      <div style={{ width: '100%' }}>
        <styled.Input
          type={
            type === 'password' && !showPassword ? 'password' : 'text'
          }
          {...rest}
        />
        {type === 'password' ? (
          <styled.PasswordEyeIcon
            onClick={() => setShowPassword(!showPassword)}
          />
        ) : null}
      </div>
    </styled.InputContainer>
  )
}

export default Input
