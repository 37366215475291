import React, { useState, useEffect } from 'react'

import ConfirmationModalMobile from '../../components/ConfirmationModal/Mobile'
import ConfirmationModalWeb from '../../components/ConfirmationModal/Web'
import DisseminatorCard from '../../components/DisseminatorCard'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import SearchInput from '../../components/SearchInput'
import { useToast } from '../../hooks/useToast'
import api from '../../services/api'
import { useMobile } from '../../utils/useMobile'
import * as styled from './styles'

const BackOfficePending = () => {
  const [pending, setPending] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [selDisseminator, setSelDisseminator] = useState({})
  const { addToast } = useToast()
  const mobile = useMobile()

  const fetchUsers = async (params = '') => {
    try {
      const response = await api.get(
        '/users?q[is_disseminator_eq]=1&q[approved_disseminator_eq]=0&',
        {
          params: new URLSearchParams(params)
        }
      )
      if (response.status === 200) setPending(response.data)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  const handleApprove = async (id) => {
    try {
      const response = await api.post('/users/' + id + '/approve')

      if (response.status === 200) {
        addToast({
          type: 'sucess',
          title: 'Divulgador aprovado com sucesso!',
          description: 'Agora esse divugador pode publicar no MurOn.'
        })

        setPending((prevState) =>
          prevState.filter((user) => user.id !== id)
        )
      }
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao aprovar esse divulgador',
        description: 'Não foi possível aprovar esse divulgador.'
      })
    }
  }

  const handleShowModal = (disseminator) => {
    setSelDisseminator({
      id: disseminator.id,
      full_name: disseminator.full_name
    })
    setShowModal(true)
  }

  const handleUnapprove = async (id) => {
    try {
      const response = await api.post('/users/' + id + '/unapprove')

      if (response.status === 200) {
        addToast({
          type: 'sucess',
          title: 'Divulgador desaprovado com sucesso!',
          description: 'A conta desse divulgador foi removida.'
        })

        setPending((prevState) =>
          prevState.filter((user) => user.id !== id)
        )
        setShowModal(false)
      }
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao rejeitar esse divulgador',
        description: 'Não foi possível rejeitar esse divulgador.'
      })
    }
  }

  return (
    <>
      <Header
        onSearch={(query) =>
          fetchUsers('q[full_name_or_partner_cont]=' + query)
        }
      />
      <styled.PageContainer>
        <styled.ContentContainer>
          <styled.Title mobile={mobile}>
            Divulgadores Pendentes
          </styled.Title>
          {!mobile && (
            <SearchInput
              placeholder={'Pesquise por divulgadores'}
              onSearch={(query) =>
                fetchUsers('q[full_name_or_partner_cont]=' + query)
              }
            />
          )}
          <styled.CardsContainer>
            {pending.length > 0 ? (
              pending.map((disseminator) => (
                <DisseminatorCard
                  key={disseminator.id}
                  disseminator={disseminator}
                  onApprove={() => handleApprove(disseminator.id)}
                  onDelete={() => handleShowModal(disseminator)}
                />
              ))
            ) : (
              <styled.Text>Não há usuários pendentes.</styled.Text>
            )}
          </styled.CardsContainer>
        </styled.ContentContainer>
      </styled.PageContainer>
      {mobile ? (
        <ConfirmationModalMobile
          isOpen={showModal}
          handleCloseConfirmationModal={() => setShowModal(false)}
          name={selDisseminator.full_name}
          id={selDisseminator.id}
          onDelete={() => handleUnapprove(selDisseminator.id)}
        />
      ) : (
        <ConfirmationModalWeb
          isOpen={showModal}
          handleClosedConfirmationModal={() => setShowModal(false)}
          name={selDisseminator.full_name}
          id={selDisseminator.id}
          onDelete={() => handleUnapprove(selDisseminator.id)}
        />
      )}
      {mobile ? <Footer></Footer> : <></>}
    </>
  )
}

export default BackOfficePending
