import styled from 'styled-components'

export const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ImageContainer = styled.div`
  width: 60%;
  margin-bottom: 1.2rem;
`

export const Image = styled.img`
  width: 100%;
  height: auto;
`
export const TextContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
`

export const Text = styled.p`
  font-size: 1.2rem;
  text-align: center;
  width: 90%;
`
