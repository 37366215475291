import CameraAltIcon from '@mui/icons-material/CameraAlt'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import React, { useState, useEffect, useRef } from 'react'

import PostImages from '../PostImages'
import * as styled from './styles'

const PostPreviewer = ({ files, onDelete }) => {
  const postImages = useRef()
  const [previewUrls, setPreviewUrls] = useState([])

  const getPreviewUrl = () => {
    return files.map((file) => file.previewUrl)
  }

  useEffect(() => {
    setPreviewUrls(getPreviewUrl())
  }, [files])

  return (
    <styled.Container>
      <styled.PreviewContainer>
        {files && files.length > 0 ? (
          <PostImages ref={postImages} files={previewUrls} />
        ) : (
          <styled.NoImage>
            <CameraAltIcon style={{ fontSize: '100px' }} />
          </styled.NoImage>
        )}
      </styled.PreviewContainer>
      {files && files.length > 0 && (
        <styled.DeleteButton
          onClick={() => onDelete(postImages.current.getPage())}
        >
          <DeleteForeverIcon style={{ fontSize: '40px' }} />
        </styled.DeleteButton>
      )}
    </styled.Container>
  )
}

export default PostPreviewer
