import React, { useState, useEffect } from 'react'

import ConfirmationModalMobile from '../../components/ConfirmationModal/Mobile'
import ConfirmationModalWeb from '../../components/ConfirmationModal/Web'
import DisseminatorCard from '../../components/DisseminatorCard'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import SearchInput from '../../components/SearchInput'
import { useToast } from '../../hooks/useToast'
import api from '../../services/api'
import { useMobile } from '../../utils/useMobile'
import * as styled from './styles'

const BackOfficeApproved = () => {
  const [approved, setApproved] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [selDisseminator, setSelDisseminator] = useState({})
  const { addToast } = useToast()
  const mobile = useMobile()

  const fetchUsers = async (params = '') => {
    try {
      const response = await api.get(
        '/users?q[approved_disseminator_eq]=1&',
        {
          params: new URLSearchParams(params)
        }
      )
      if (response.status === 200) setApproved(response.data)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  const handleShowModal = (disseminator) => {
    setSelDisseminator({
      id: disseminator.id,
      full_name: disseminator.full_name
    })
    setShowModal(true)
  }

  const handleDelete = async (id) => {
    try {
      const response = await api.delete('/users/' + id)

      if (response.status === 200) {
        addToast({
          type: 'sucess',
          title: 'Divulgador removido com sucesso!',
          description: 'A conta desse divulgador foi removida.'
        })

        setApproved((prevState) =>
          prevState.filter((user) => user.id !== id)
        )
        setShowModal(false)
      }
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao remover divulgador',
        description: 'Não foi possível remover esse divulgador.'
      })
    }
  }

  return (
    <>
      <Header
        onSearch={(query) =>
          fetchUsers('q[full_name_or_partner_cont]=' + query)
        }
      />
      <styled.PageContainer>
        <styled.ContentContainer>
          <styled.Title mobile={mobile}>
            Divulgadores Aprovados
          </styled.Title>
          {!mobile && (
            <SearchInput
              placeholder={'Pesquise por divulgadores'}
              onSearch={(query) =>
                fetchUsers('q[full_name_or_partner_cont]=' + query)
              }
            />
          )}
          <styled.CardsContainer>
            {approved.length > 0 ? (
              approved.map((disseminator) => (
                <DisseminatorCard
                  key={disseminator.id}
                  disseminator={disseminator}
                  onDelete={() => handleShowModal(disseminator)}
                />
              ))
            ) : (
              <styled.Text>Não há usuários aprovados.</styled.Text>
            )}
          </styled.CardsContainer>
        </styled.ContentContainer>
      </styled.PageContainer>
      {mobile ? (
        <ConfirmationModalMobile
          isOpen={showModal}
          handleCloseConfirmationModal={() => setShowModal(false)}
          name={selDisseminator.full_name}
          id={selDisseminator.id}
          onDelete={() => handleDelete(selDisseminator.id)}
        />
      ) : (
        <ConfirmationModalWeb
          isOpen={showModal}
          handleClosedConfirmationModal={() => setShowModal(false)}
          name={selDisseminator.full_name}
          id={selDisseminator.id}
          onDelete={() => handleDelete(selDisseminator.id)}
        />
      )}
      {mobile ? <Footer></Footer> : <></>}
    </>
  )
}

export default BackOfficeApproved
