import styled from 'styled-components'

export const CardContainer = styled.div`
  max-width: 700px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  overflow: hidden;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 25px 25px 20px 25px;
`

export const DataContainer = styled.div`
  display: flex;
  text-decoration: none;
  color: unset;
`

export const DataWrapper = styled.div`
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.6rem;
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`

export const ImageContainer = styled.div`
  height: 125px;
  width: 125px;
  aspect-ratio: 1;
  background-color: var(--azul-escuro);
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`

export const Image = styled.img`
  object-fit: contain;
`

export const ArrowWrapper = styled.div`
  color: var(--cinza-escuro);
  display: inline-flex;
  align-items: center;
  font-size: 1rem;
  margin-top: 20px;
  cursor: pointer;
  transition: all 200ms ease;

  &:hover {
    filter: brightness(1.6);
  }
  svg {
    transition: transform 200ms ease;
    transform: ${(props) => (props.rotateAt ? 'rotate(180deg)' : '')};
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 65px;
`

const Button = styled.button`
  color: white;
  height: ${(props) => (props.single ? '100%' : '50%')};
  border: none;
  cursor: pointer;
  transition: all 300ms ease;

  &:hover {
    filter: brightness(0.9);
  }
`

export const DeleteButton = styled(Button)`
  background-color: var(--vermelho);
`

export const ApproveButton = styled(Button)`
  background-color: var(--azul-escuro);
`

export const Title = styled.h1`
  color: var(--azul-escuro);
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 0.9;
  transition: all 300ms ease;
  word-break: break-word;
`

export const Name = styled.h1`
  font-weight: 500;
  font-size: 1.4rem;
  word-break: break-word;
`

export const Text = styled.p`
  color: var(--cinza-escuro);
  font-size: 1.1rem;
`

export const Description = styled.div`
  color: var(--cinza-escuro);
  font-size: 1.1rem;
  text-align: justify;
  word-break: break-word;
  margin: 15px 0 0 15px;
`
