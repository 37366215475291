import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0px);
  margin-top: 3rem;
  padding: 1rem;
  overflow: hidden;

  button {
    transition: 0.2s;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
`
