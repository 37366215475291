/* global Image */
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import DeleteIcon from '@mui/icons-material/Delete'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import PersonIcon from '@mui/icons-material/Person'
import React, { useEffect, useState } from 'react'
import AnimateHeight from 'react-animate-height'
import { Link } from 'react-router-dom'

import * as styled from './styles'

const DisseminatorCard = ({ disseminator, onDelete, onApprove }) => {
  const [showDesc, setShowDesc] = useState(false)
  const [imgDimensions, setImgDimensions] = useState({ height: '100%' })
  const [descHeight, setDescHeight] = useState(0)

  // Toggles between hidden and shown description
  const toggleShowDesc = () => {
    setDescHeight(showDesc ? 0 : 'auto')
    setShowDesc(!showDesc)
  }

  useEffect(() => {
    // Creates a event listener that changes the profile image
    // styles according to it's size
    const img = new Image()
    img.src = disseminator.photo

    const listener = () => {
      setImgDimensions(
        img.naturalHeight > img.naturalWidth
          ? { width: '100%' }
          : { height: '100%' }
      )
    }
    img.addEventListener('load', listener)

    // useEffect cleanup function, removing event listener
    return () => {
      img.removeEventListener('load', listener)
    }
  }, [])

  return (
    <styled.CardContainer>
      <styled.ContentContainer>
        <styled.DataContainer
          as={Link}
          to={`/profile/${disseminator.id}`}
        >
          <styled.ImageContainer>
            {disseminator.photo ? (
              <styled.Image
                src={disseminator.photo}
                style={imgDimensions}
              />
            ) : (
              <PersonIcon style={{ fontSize: '60px' }} />
            )}
          </styled.ImageContainer>
          <styled.DataWrapper>
            <styled.Title>{disseminator.partner}</styled.Title>
            <styled.Name>{disseminator.full_name}</styled.Name>
          </styled.DataWrapper>
        </styled.DataContainer>
        <styled.InfoWrapper>
          <styled.Text>Telefone: {disseminator.number}</styled.Text>
          <styled.Text>Email: {disseminator.email}</styled.Text>
          <styled.Text>Site: {disseminator.site}</styled.Text>
        </styled.InfoWrapper>
        <styled.ArrowWrapper
          onClick={toggleShowDesc}
          rotateAt={showDesc}
        >
          <ArrowDropDownIcon />
          {showDesc ? 'Esconder descrição' : 'Exibir descrição'}
        </styled.ArrowWrapper>
        <AnimateHeight
          id="card-animation"
          duration={300}
          height={descHeight}
        >
          <styled.Description>
            {disseminator.description}
          </styled.Description>
        </AnimateHeight>
      </styled.ContentContainer>
      <styled.ButtonsContainer>
        {!disseminator.approved_disseminator ? (
          <>
            <styled.ApproveButton onClick={onApprove}>
              <CheckCircleOutlineIcon style={{ fontSize: '2.5rem' }} />
            </styled.ApproveButton>
            <styled.DeleteButton onClick={onDelete}>
              <HighlightOffIcon style={{ fontSize: '2.5rem' }} />
            </styled.DeleteButton>
          </>
        ) : (
          <styled.ApproveButton onClick={onDelete} single>
            <DeleteIcon style={{ fontSize: '2.5rem' }} />
          </styled.ApproveButton>
        )}
      </styled.ButtonsContainer>
    </styled.CardContainer>
  )
}

export default DisseminatorCard
