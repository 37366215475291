import styled from 'styled-components'

export const ImageAuthor = styled.img`
  border-radius: 50%;
  margin-right: 10px;
  width: 2rem;
  height: 2rem;
  box-shadow: 0 0 5px grey;
  object-fit: cover;
`

export const NameAuthor = styled.label`
  color: var(--cinza-escuro);
  font-size: 0.9rem;
  font-weight: 500;
`

export const ContainerPerfil = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`
export const LinkComponent = styled.button`
  border-style: none;
  background-color: white;
  cursor: pointer;
  margin-bottom: 1rem;
`
