import DeleteIcon from '@mui/icons-material/Delete'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import commentsImage from '../../../assets/commentsImage.svg'
import UserInfo from '../../../components/UserInfo'
import { useAuth } from '../../../contexts/AuthContext'
import formatDate from '../../../utils/formatDate.js'
import ConfirmationModalWeb from '../../ConfirmationModal/Web'
import PostImages from '../../PostImages'
import * as styled from './styles.js'

const Posts = ({ post }) => {
  const { user } = useAuth()
  const date = formatDate(post?.created_at)
  const [modalConfirmation, setModalConfirmation] = useState(false)

  const handleDeletePostModal = () => {
    setModalConfirmation(true)
  }

  const handleDeletePostModalClose = () => {
    setModalConfirmation(false)
  }

  return (
    <>
      <styled.Container>
        <styled.Content>
          <styled.ContainerPerfilHeader>
            <UserInfo user={post.creator} />
            <styled.ContainerIcons>
              {!!user && user.is_admin && (
                <styled.DeleteButton onClick={handleDeletePostModal}>
                  <DeleteIcon
                    style={{
                      color: 'var(--azul-escuro)',
                      fontSize: '2.25rem'
                    }}
                  />
                </styled.DeleteButton>
              )}
              <Link to={`/post/${post.id}#comment`}>
                <styled.CommentsImage src={commentsImage} />
              </Link>
            </styled.ContainerIcons>
          </styled.ContainerPerfilHeader>
          <styled.ContainerImage>
            <PostImages files={post.attachments} />
          </styled.ContainerImage>
          <styled.Title>
            <Link to={`/post/${post.id}`}>{post.title}</Link>
          </styled.Title>
          <styled.PostText>
            {post.content.length > 290
              ? post.content.substring(0, 290) + '...'
              : post.content}
          </styled.PostText>
        </styled.Content>
        <styled.ContainerFooter>
          <styled.PostDate>{date}</styled.PostDate>
          <Link to={`/post/${post.id}`}>Ver publicação</Link>
        </styled.ContainerFooter>
      </styled.Container>
      <ConfirmationModalWeb
        isOpen={modalConfirmation}
        post={post}
        handleDeletePostModalClose={handleDeletePostModalClose}
        text={'Deseja mesmo deletar esse post?'}
      />
    </>
  )
}

export default Posts
