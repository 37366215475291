import React from 'react'

import ToastComponentMobile from '../ToastComponent/Mobile/'
import { Container } from './styles.js'

const ToastMobile = ({ messages }) => {
  return (
    <Container>
      {messages.map((message) => (
        <ToastComponentMobile key={message.id} message={message} />
      ))}
    </Container>
  )
}

export default ToastMobile
