import Chrome from './Chrome'
import Edge from './Edge'
import Firefox from './Firefox'
import Safari from './Safari'

const Install = ({ browser }) => {
  const BrowsersComponents = {
    Chrome: <Chrome />,
    Firefox: <Firefox />,
    Safari: <Safari />,
    Edge: <Edge />
  }

  return BrowsersComponents[browser] || <Chrome />
}

export default Install
