import React from 'react'
import { Link } from 'react-router-dom'

import Button from '../../../components/Button'
import Footer from '../../../components/Footer'
import Header from '../../../components/Header/Mobile'
import * as Styled from './styles.js'

const BackOfficeAdmMobile = ({ usersCounters }) => {
  return (
    <>
      <Header line={false} />
      <Styled.Container>
        <Styled.Content>
          <Styled.Title>Área de administrador:</Styled.Title>
          <Button
            ghost
            responsive
            as={Link}
            to="/backoffice/users"
            style={{ textDecoration: 'none' }}
          >
            Usuários/Murers - {usersCounters.users_quantity}
          </Button>
          <Button
            ghost
            responsive
            as={Link}
            to="/backoffice/approved"
            style={{ textDecoration: 'none' }}
          >
            Divulgadores Aprovados -{' '}
            {usersCounters.approved_disseminators_quantity}
          </Button>
          <Button
            ghost
            responsive
            as={Link}
            to="/backoffice/pending"
            style={{ textDecoration: 'none' }}
          >
            Divulgadores Pendentes -{' '}
            {usersCounters.pending_disseminators_quantity}
          </Button>
        </Styled.Content>
      </Styled.Container>
      <Footer></Footer>
    </>
  )
}

export default BackOfficeAdmMobile
