import React from 'react'
import Modal from 'react-modal'

import { useAuth } from '../../../contexts/AuthContext'
import { useToast } from '../../../hooks/useToast.jsx'
import api from '../../../services/api'
import Button from '../../Button'
import * as Styled from './styles.js'

const ConfirmationModalMobile = ({
  isOpen,
  handleClosedConfirmationModal,
  id,
  name,
  text,
  post,
  handleDeletePostModalClose,
  onDelete
}) => {
  const { addToast } = useToast()
  const { user } = useAuth()

  const handleDeletePost = async () => {
    await api
      .delete(`/posts/${post.id}`)
      .then((res) => {
        if (res.status === 200 && user.is_admin) {
          addToast({
            type: 'sucess',
            title: 'O post foi deletado com sucesso!',
            description:
              'O post foi deletado com sucesso e um email de notificação foi para o dono do post..'
          })
        } else if (res.status === 200) {
          addToast({
            type: 'sucess',
            title: 'O post foi deletado com sucesso!',
            description:
              'O post foi deletado com sucesso e um email foi enviado.'
          })
        }
        handleDeletePostModalClose()
        window.location.reload()
      })
      .catch((err) =>
        addToast({
          type: 'error',
          title: 'Ocorreu um erro ao deletar o post',
          description: err.error
        })
      )
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClosedConfirmationModal}
      overlayClassName="modal-confirmation-mobile"
      className="confirmation-modal-mobile"
    >
      <Styled.Container>
        {name ? (
          <Styled.UserName>
            Deseja mesmo deletar o usuario: <strong>{name}</strong> ?
          </Styled.UserName>
        ) : (
          <Styled.UserName>{text}</Styled.UserName>
        )}
        <Styled.ButtonContainer>
          {name ? (
            <>
              <Button onClick={onDelete}>Confirmar</Button>
              <Button ghost onClick={handleClosedConfirmationModal}>
                Cancelar
              </Button>
            </>
          ) : (
            <>
              <Button onClick={handleDeletePost}>Confirmar</Button>
              <Button ghost onClick={handleDeletePostModalClose}>
                Cancelar
              </Button>
            </>
          )}
        </Styled.ButtonContainer>
      </Styled.Container>
    </Modal>
  )
}

export default ConfirmationModalMobile
