import React from 'react'

import { useMobile } from '../../utils/useMobile'
import DisseminatorCardMobile from './Mobile'
import DisseminatorCardWeb from './Web'

const Header = (props) => {
  if (useMobile()) {
    return <DisseminatorCardMobile {...props} />
  } else {
    return <DisseminatorCardWeb {...props} />
  }
}

export default Header
