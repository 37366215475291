import React from 'react'
import { Link } from 'react-router-dom'

import Cellphone from '../../../assets/Cellphonegirl.png'
import logoImg from '../../../assets/logo.png'
import Button from '../../../components/Button'
import { history } from '../../../utils/history'
import {
  Content,
  Container,
  Image,
  RegisterDiv,
  Title,
  LinkDiv,
  ContainerFooter,
  ContainerButton,
  ContainerFirstLink,
  Image2
} from './styles.js'

const RegisterTypeWeb = () => {
  const callRegister = () => {
    history.push('/register')
    window.location.reload()
  }
  const callDisseminator = () => {
    history.push('/disseminator')
    window.location.reload()
  }

  return (
    <>
      <Container>
        <Content>
          <RegisterDiv>
            <Link to="/">
              <Image src={logoImg} />
            </Link>
            <Title>Como deseja se cadastrar?</Title>
            <ContainerButton>
              <Button responsive ghost onClick={callRegister}>
                Usuário/MurEr
              </Button>
              <Button responsive ghost onClick={callDisseminator}>
                Divulgador
              </Button>
            </ContainerButton>
            <LinkDiv>
              <p>Lembrou sua senha?</p>
              <Link to="/login">Faça login</Link>
            </LinkDiv>
          </RegisterDiv>
          <Image2 src={Cellphone} />
        </Content>
      </Container>
      <ContainerFooter>
        <ContainerFirstLink>
          <Link to="/about">Saiba mais sobre o Muron</Link>
        </ContainerFirstLink>
        <Link to="/contact">Fale Conosco</Link>
      </ContainerFooter>
    </>
  )
}

export default RegisterTypeWeb
