import InstalarAndroid from '../../../../assets/instalarAndroid.png'
import InstalarComputador from '../../../../assets/instalarComputador.png'
import {
  Image,
  ImageInstallContainer,
  InstallContainer,
  Text,
  Title
} from '../styles.js'

const Chrome = () => {
  return (
    <InstallContainer>
      <Title>Adicione o MurOn ao seu dispositivo</Title>
      <Text>
        Para instalar em seu celular, clique nos três pontinhos no canto
        superior direito e <i>Instalar aplicativo</i> em seguida. Os
        nomes podem variar de acordo com o sistema operacional e
        navegador usado.
      </Text>
      <br></br>
      <ImageInstallContainer>
        <Image
          src={InstalarAndroid}
          alt="Imagem de instrução para adicionar atalho no Android."
          width="80%"
        />
      </ImageInstallContainer>
      <br></br>
      <Text>
        Também é possível instalar no seu computador seguindo os passos
        acima {'(três pontinhos -> instalar MurOn)'} ou clicando no
        botão mostrado abaixo
      </Text>
      <br></br>
      <ImageInstallContainer>
        <Image
          src={InstalarComputador}
          alt="Imagem de instrução para adicionar atalhro no Computador."
          width="80%"
        />
      </ImageInstallContainer>
    </InstallContainer>
  )
}

export default Chrome
