import React from 'react'
import ReactPlayer from 'react-player'

import Video from '../../../assets/Muron.mp4'
import Thumbnail from '../../../assets/video-thumbnail.png'
import Header from '../../../components/Header'
import { getUserBrowser } from '../../../utils/getUserBrowser'
import Install from './components/Install'
import {
  Container,
  Content,
  MurOn,
  Text,
  TextContainer,
  Title,
  Types,
  About,
  VideoDiv
} from './styles.js'

const AboutUsWeb = () => {
  return (
    <>
      <Header Line={false} />
      <Container>
        <About>
          <VideoDiv>
            <ReactPlayer
              controls={true}
              autoPlay={false}
              config={{
                file: {
                  attributes: {
                    poster: Thumbnail
                  }
                }
              }}
              volume={1}
              url={Video}
              width="100%"
              height="100%"
            />
          </VideoDiv>
          <Content>
            <Title>MurOn- Sua Rede Universitária!</Title>
            <TextContainer>
              <Text>
                O <MurOn>MurOn</MurOn> é a rede social para conectar
                você com os assuntos da vida universitária.
              </Text>
              <Text>
                Somos o mural digital inteligente e criativo. Aproveite!
              </Text>
              <br></br>
              <Text>
                Quer divulgar algo legal? Poste e compartilhe conosco!
              </Text>
              <Text>
                Quer pesquisar sobre estágio/trabalho ou encontrar
                pessoas relevantes para você? Procure no{' '}
                <MurOn>MurOn</MurOn>!
              </Text>
              <br></br>
              <Text>
                Se você for aluno, ex-aluno, professor ou colaborador de
                alguma faculdade, é só se cadastrar como{' '}
                <i>Usuário-MurEr</i>.
              </Text>
              <Text>
                Mas se não for MurEr, não tem problema. Você também pode
                ser nosso parceiro, marcando a opção{' '}
                <i>Usuário-divulgador </i>
                para compartilhar algo interessante com o mundo
                universitário.
              </Text>
              <br></br>
              <Text>
                No <MurOn>MurOn</MurOn>, há várias funcionalidades:
              </Text>
              <Types>
                DIVULGAÇÃO - Se você quiser ir direto para as
                divulgações em geral;
                <br></br>
                ESTÁGIO/TRABALHO - É o ambiente adequado para conectar
                você com empregadores;
                <br></br>
                PROJETOS - Onde você visualiza os projetos e ideias da
                comunidade;
                <br></br>
                PUBLICAR – Onde você compartilha o que quiser com o
                mundo universitário.
              </Types>
              <br></br>
              <Text>
                É isso... Nossa missão é conectar pessoas, registrar
                momentos, compartilhar projetos e serviços, e tornar
                nossa comunicação mais eficiente e divertida!
              </Text>
              <a name="instalar"></a>
              <Text>Experimente! Junte-se a nós e divirta-se!</Text>
            </TextContainer>
          </Content>
        </About>
        <div>
          <Install browser={getUserBrowser()} />
        </div>
      </Container>
    </>
  )
}

export default AboutUsWeb
