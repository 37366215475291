import styled from 'styled-components'

export const Title = styled.h1`
  color: var(--azul-escuro);
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  padding: 2rem;
  padding-bottom: 1.5rem;
`
export const PostContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 4rem;
  margin-bottom: 2rem;
  cursor: default;
`

export const Date = styled.p`
  color: var(--cinza-escuro);
  margin-bottom: 0.5rem;
`

export const UserInfoWrapper = styled.div`
  margin-bottom: 2rem;
`

export const Content = styled.div`
  text-align: justify;
  max-width: 500px;
  width: 85%;
  margin-top: 1rem;
`

export const Container = styled.div`
  margin-top: 2rem;
  width: 85%;
  max-width: 500px;
`

export const CommentBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0 2rem 0;
  gap: 0.75rem;

  p {
    font-size: 1.1rem;
  }
`

export const CommentsContainer = styled.div`
  gap: 0.5rem;
`

export const CommentTitle = styled.div`
  svg {
    color: var(--cinza-medio);
    font-size: 1.8rem;
  }

  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--cinza-escuro);
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 85%;
  margin-bottom: 1rem;
  z-index: 1;
`
export const Icons = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

export const NoComments = styled.p`
  color: var(--cinza-escuro);
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 3rem;
`
