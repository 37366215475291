import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  place-content: center;
  margin-top: 1.5rem;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
`

export const Title = styled.h1`
  color: var(--azul-escuro);
  font-weight: 500;
  margin-bottom: 1rem;
`

export const ContainerUsers = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2.5rem;
  margin-top: 2rem;
`

export const TextTotal = styled.p`
  margin-top: 1rem;
  margin-right: 10rem;
  align-self: flex-end;
  font-weight: 500;
  font-size: 1.25rem;
`

export const InputDiv = styled.div`
  max-width: 22.375rem;
  width: 100%;
  display: flex;
  border-bottom: 2px solid var(--cinza-escuro);
`

export const InputSearch = styled.input`
  padding: 0 0 0.5rem 0.5rem;
  border-style: none;
  outline: none;
  font-size: 1rem;
  ::placeholder {
    align-self: center;
    color: --var(--cinza-claro);
    font-size: 16px;
  }
`
export const Text = styled.h2`
  text-align: center;
  word-break: break-word;
  font-size: ${(props) => (props.mobile ? '1rem' : '1.3rem')};
  font-weight: 400;
  color: var(--cinza-escuro);
  margin-top: 1rem;
`
