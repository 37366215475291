import FileUploadIcon from '@mui/icons-material/FileUpload'
import { CircularProgress } from '@mui/material'
import React, { useRef } from 'react'

import Button from '../../../components/Button'
import Footer from '../../../components/Footer'
import Header from '../../../components/Header'
import Input from '../../../components/Input'
import Option from '../../../components/Option'
import PostPreviewer from '../../../components/PostPreviewer'
import Select from '../../../components/Select'
import Textarea from '../../../components/Textarea'
import * as styled from './styles'

const CreatePost = ({ state, methods }) => {
  const inputFileRef = useRef(null)

  const openFileBrowser = () => {
    inputFileRef.current.click()
  }

  const submitHandler = () => {
    methods.handleSubmit()
  }

  return (
    <>
      <Header />
      <styled.PageContainer>
        <styled.Container>
          <styled.AttachmentsContainer>
            <PostPreviewer
              files={state.postInfo.attachments}
              onDelete={methods.deleteEntry}
            />
            <Button
              onClick={openFileBrowser}
              disabled={state.isSubmitting}
            >
              <input
                ref={inputFileRef}
                type="file"
                multiple
                accept="image/*,video/*"
                onChange={methods.onFileChange}
                style={{ display: 'none' }}
              />
              <FileUploadIcon />
              Adicionar arquivos
            </Button>
          </styled.AttachmentsContainer>
          <styled.InputContainer>
            <Input
              disabled={state.isSubmitting}
              responsive
              label="Título da postagem:"
              maxLength={70}
              value={state.postInfo.title}
              onChange={(e) =>
                methods.setPostInfo((prevState) => ({
                  ...prevState,
                  title: e.target.value
                }))
              }
            />
            <Textarea
              disabled={state.isSubmitting}
              label="Conteúdo da postagem:"
              value={state.postInfo.content}
              onChange={(e) =>
                methods.setPostInfo((prevState) => ({
                  ...prevState,
                  content: e.target.value
                }))
              }
            />
            <Select
              responsive
              label="Escolha a tag:"
              value={!!state.postInfo.tag && state.postInfo.tag.name}
              disabled={state.isSubmitting}
            >
              {state.tags.map((tag) => (
                <Option
                  key={tag.id}
                  onClick={() =>
                    methods.setPostInfo((prevState) => ({
                      ...prevState,
                      tag: tag
                    }))
                  }
                >
                  {tag.name}
                </Option>
              ))}
            </Select>
            <Button
              style={{
                alignSelf: 'center',
                marginTop: '1.2rem',
                width: '100%'
              }}
              onClick={submitHandler}
              disabled={state.isSubmitting}
            >
              {state.isSubmitting ? (
                <>
                  <CircularProgress color="inherit" size={20} />
                  Aguarde
                </>
              ) : (
                <>Publicar</>
              )}
            </Button>
          </styled.InputContainer>
        </styled.Container>
      </styled.PageContainer>
      <Footer />
    </>
  )
}

export default CreatePost
