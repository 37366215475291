import Visibility from '@mui/icons-material/Visibility'
import styled from 'styled-components'

export const InputContainer = styled.div`
  width: 100%;
  ${(props) => !props.responsive && 'max-width: 350px;'}
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
`

export const Label = styled.h1`
  font-size: 18px;
  font-weight: 400;
`

export const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  height: 3rem;
  font-size: 18px;
  border: 2px solid var(--cinza-medio);
  border-radius: 5px;
  padding: 0 0.5rem;

  &:focus {
    outline: none;
  }
`

export const PasswordEyeIcon = styled(Visibility)`
  cursor: pointer;
  margin-left: -2.5rem;
  margin-bottom: -0.5rem;
`
