import Camera from '@mui/icons-material/CameraAlt'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import SaveIcon from '@mui/icons-material/Save'
import { MenuItem } from '@mui/material'
import { csv } from 'd3'
import FormData from 'form-data'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import cursosGraduação from '../../../assets/csv/cursos_graduacao_unb.csv'
import iesCSV from '../../../assets/csv/ies.csv'
import NoImage from '../../../assets/Unimage.png'
import Autocomplete from '../../../components/AutocompleteInput'
import Button from '../../../components/Button'
import ConfirmationModal from '../../../components/ConfirmationModal/Web'
import Footer from '../../../components/Footer'
import Header from '../../../components/Header'
import Input from '../../../components/Input'
import SelectInput from '../../../components/SelectInput'
import Textarea from '../../../components/Textarea'
import TextInput from '../../../components/TextInput'
import { useAuth } from '../../../contexts/AuthContext'
import { useToast } from '../../../hooks/useToast'
import api from '../../../services/api'
import {
  ButtonDiv,
  Container,
  Image,
  ImageDiv,
  InputsDiv,
  Label,
  PhotoSend,
  Username
} from './styles'

const ProfileMobile = () => {
  const { control, handleSubmit, getValues } = useForm({ mode: 'all' })
  const history = useHistory()
  const { user, logoutUser } = useAuth()
  const [url, setUrl] = useState('')
  const { addToast } = useToast()
  const [photo, setPhoto] = useState()
  const [modalConfirmation, setConfirmationModal] = useState(false)
  const [faculdades, setFaculdades] = useState([])
  const [cursos, setCursos] = useState([])
  const [infosHasChanged, setInfosHasChanged] = useState(false)
  const [userData, setUserData] = useState({
    full_name: user.full_name,
    partner: user.partner,
    number: user.number,
    site: user.site,
    university: user.university,
    course: user.course,
    situation: user.situation,
    old_password: '',
    password: '',
    password_confirmation: ''
  })

  useEffect(() => {
    csv(cursosGraduação).then((data) => setCursos(data))
    csv(iesCSV).then((data) => setFaculdades(data))
  }, [])

  const handleDeleteUser = () => {
    api
      .delete(`/users/${user.id}`)
      .then(() => {
        addToast({
          type: 'sucess',
          title: 'Usuário deletado com sucesso',
          description:
            'O usuário foi deletado com sucesso do nosso banco de dados.'
        })
        history.push('/')
        logoutUser()
        handleCloseConfirmationModal()
      })
      .catch((err) => {
        console.log(err)
        addToast({
          type: 'error',
          title: 'Ocorreu um erro ao deletar o usuário',
          description: err.error
        })
      })
  }
  const handleOpenConfirmationModal = () => {
    setConfirmationModal(true)
  }

  const handleCloseConfirmationModal = () => {
    setConfirmationModal(false)
  }

  const updateProfile = async (values, e) => {
    e.preventDefault()
    const formData = new FormData()
    if (photo) {
      formData.append('photo', photo)
    }
    formData.append('full_name', userData.full_name)
    formData.append('partner', userData.partner)
    formData.append('number', userData.number)
    formData.append('site', userData.site)

    if (user.is_admin && !values.Faculdade) {
      formData.append('university', '')
    } else if (values.Curso === null) {
      addToast({
        type: 'error',
        title: 'Erro ao alterar informações!',
        description: 'Selecione uma faculdade válida!'
      })
      return
    } else if (values.Faculdade !== undefined) {
      formData.append('university', values.Faculdade.nome)
    } else {
      formData.append('university', userData.university)
    }

    if (user.is_admin && !values.Curso) {
      formData.append('course', '')
    } else if (values.Curso === null) {
      addToast({
        type: 'error',
        title: 'Erro ao alterar informações!',
        description: 'Selecione um curso válido!'
      })
      return
    } else if (values.Curso !== undefined) {
      formData.append('course', values.Curso.nome)
    } else {
      formData.append('course', userData.course)
    }

    if (values.Situação === null) {
      addToast({
        type: 'error',
        title: 'Erro ao alterar informações!',
        description: 'Selecione uma situação válido!'
      })
      return
    } else if (values.Situação !== undefined) {
      formData.append('situation', values.Situação)
    } else {
      formData.append('situation', userData.situation)
    }

    formData.append('password', userData.password)
    formData.append(
      'password_confirmation',
      userData.password_confirmation
    )
    formData.append('old_password', userData.old_password)
    if (userData.password === userData.password_confirmation) {
      await api
        .put(`/users/${user.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(() => {
          addToast({
            type: 'sucess',
            title: 'Informações alteradas!',
            description: 'Suas informações foram alteradas com sucesso.'
          })
        })
        .catch(() => {
          addToast({
            type: 'error',
            title: 'Erro ao alterar informações!',
            description: 'Cheque suas informações e tente novamente.'
          })
        })
    } else {
      addToast({
        type: 'error',
        title: 'Erro ao alterar informações!',
        description: 'As senhas devem ser iguais!'
      })
    }
  }

  const imageUrl = (e) => {
    setInfosHasChanged(true)
    setUrl(URL.createObjectURL(e.target.files[0]))
    setPhoto(e.target.files[0])
  }

  const handleChange = (e) => {
    setInfosHasChanged(true)
    const { name, value } = e.target
    setUserData((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  return (
    <>
      <Header />
      <Container>
        <Label>Alterar perfil</Label>
        {user.photo ? (
          <>
            <ImageDiv>
              <Username>{user.username}</Username>
              {photo ? (
                <Image src={url}></Image>
              ) : (
                <Image src={user.photo}></Image>
              )}

              <PhotoSend>
                <Camera></Camera>
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={imageUrl}
                ></input>
                Alterar foto de perfil
              </PhotoSend>
            </ImageDiv>
          </>
        ) : (
          <ImageDiv>
            <Username>{user.username}</Username>
            {url ? (
              <Image src={url} alt="user photo"></Image>
            ) : (
              <Image src={NoImage} alt="use image"></Image>
            )}
            <PhotoSend>
              <Camera></Camera>
              <input
                type="file"
                accept="image/png, image/jpeg"
                onChange={imageUrl}
              ></input>
              Alterar foto de perfil
            </PhotoSend>
          </ImageDiv>
        )}
        {user.is_disseminator ? (
          <InputsDiv>
            <Input
              label="Nome Divulgador/Empresa"
              responsive
              defaultValue={user.full_name}
              name="full_name"
              onChange={handleChange}
            ></Input>
            <Input
              label="Nome do responsável"
              responsive
              name="partner"
              defaultValue={user.partner}
              onChange={handleChange}
            ></Input>
            <Input
              label="Telefone"
              responsive
              name="number"
              defaultValue={user.number}
              onChange={handleChange}
            ></Input>
            <Input
              label="Site"
              responsive
              defaultValue={user.site}
              name="site"
              onChange={handleChange}
            ></Input>
            <Textarea
              label="Descrição"
              responsive
              defaultValue={user.description}
              disabled
            ></Textarea>
          </InputsDiv>
        ) : (
          <InputsDiv>
            <TextInput
              mobile
              control={control}
              type="text"
              label="Nome"
              name="full_name"
              defaultValue={user.full_name}
              onChange={handleChange}
            />
            <div onClick={() => setInfosHasChanged(true)}>
              <Autocomplete
                name="Faculdade"
                label="Faculdade"
                defaultValue={{ nome: userData.university }}
                control={control}
                options={faculdades}
                getOptionLabel={(option) => option.nome}
              />
            </div>
            <div onClick={() => setInfosHasChanged(true)}>
              <Autocomplete
                name="Curso"
                label="Curso"
                control={control}
                defaultValue={{
                  nome: userData.course,
                  turno: '',
                  grau_academico: ''
                }}
                options={cursos}
                getOptionLabel={(option) =>
                  `${option.nome}  ${option.turno} ${option.grau_academico}`
                }
              />
            </div>
            <div onClick={() => setInfosHasChanged(true)}>
              <SelectInput
                name="Situação"
                label="Situação"
                value={getValues('Situação')}
                defaultValue={userData.situation}
                control={control}
              >
                {user.is_admin && <MenuItem value={''}></MenuItem>}
                <MenuItem value={'Aluno'}>Aluno</MenuItem>
                <MenuItem value={'Ex-aluno'}>Ex-aluno</MenuItem>
                <MenuItem value={'Professor'}>Professor</MenuItem>
                <MenuItem value={'Colaborador'}>Colaborador</MenuItem>
              </SelectInput>
            </div>
          </InputsDiv>
        )}
        <Label>Editar Senha:</Label>
        <InputsDiv>
          <TextInput
            mobile
            control={control}
            type="password"
            label="Senha atual"
            responsive
            name="old_password"
            onChange={handleChange}
          />
        </InputsDiv>
        <InputsDiv>
          <TextInput
            mobile
            control={control}
            type="password"
            label="Nova senha"
            responsive
            name="password"
            onChange={handleChange}
          />
          <TextInput
            mobile
            control={control}
            type="password"
            label="Confirmar nova senha"
            responsive
            name="password_confirmation"
            onChange={handleChange}
          />
        </InputsDiv>
        <ButtonDiv>
          <Button red onClick={handleOpenConfirmationModal}>
            <DeleteForeverIcon />
            Deletar conta
          </Button>
          <Button
            disabled={!infosHasChanged}
            onClick={handleSubmit(updateProfile)}
          >
            <SaveIcon />
            Salvar alterações
          </Button>
        </ButtonDiv>
      </Container>
      <Footer />
      <ConfirmationModal
        isOpen={modalConfirmation}
        name={user.username}
        id={user.id}
        onDelete={handleDeleteUser}
        handleClosedConfirmationModal={handleCloseConfirmationModal}
      ></ConfirmationModal>
    </>
  )
}

export default ProfileMobile
