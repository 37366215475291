import React from 'react'

import { useMobile } from '../../utils/useMobile.js'
import AboutUsMobile from './Mobile'
import AboutUsWeb from './Web'

const AboutUs = () => {
  if (useMobile()) {
    return <AboutUsMobile />
  } else {
    return <AboutUsWeb />
  }
}

export default AboutUs
