import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 350px;
  width: 90%;
  cursor: default;
  :last-child {
    margin-bottom: 100px;
    border-bottom: none;
  }
  margin: 0 auto;
  margin-top: 3rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid var(--cinza-medio);
`

export const UserContainer = styled.div`
  margin-bottom: 0.75rem;
`

export const FooterContainer = styled.div`
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  a {
    color: var(--azul-escuro);
    text-decoration: none;
    transition: color 0.2s ease;
    &:hover {
      color: #2c59ae;
    }
  }
`
export const ImageSide = styled.div`
  width: 85%;
`
export const ImageSideOne = styled.div`
  width: 100%;
`
export const ImageAjust = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const ContainerPerfil = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
`

export const CommentsImage = styled.img`
  width: 2rem;
  height: 2rem;
  color: var(--azul-escuro);
`

export const Title = styled.h2`
  margin-top: 1rem;
  font-weight: 500;
  color: var(--azul-escuro);
  font-size: 1.5rem;
  word-break: break-word;
  a {
    color: var(--azul-escuro);
    text-decoration: none;
    transition: color 0.2s ease;
    &:hover {
      color: #2c59ae;
    }
  }
`

export const PostText = styled.p`
  color: var(--cinza-escuro);
  font-size: 1rem;
  margin-top: 0.5rem;
`

export const PostDate = styled.span`
  color: var(--cinza-medio);
  font-size: 0.8rem;
  margin-top: 0.4rem;
`

export const DeleteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: var(--azul-escuro);
  width: fit-content;
  transition: 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`
export const Icons = styled.div`
  gap: 2rem;
`
