import React, {
  createContext,
  useContext,
  useEffect,
  useState
} from 'react'

import { useToast } from '../hooks/useToast'
import api from '../services/api.js'

const defaults = {
  signed: false,
  user: {}
}

const TOKEN_KEY = '@murOn-authToken'
const removeToken = () => localStorage.removeItem(TOKEN_KEY)
const getToken = () => localStorage.getItem(TOKEN_KEY)

const AuthContext = createContext(defaults)

export const AuthContextProvider = ({ children }) => {
  const { addToast } = useToast()
  const [user, setUser] = useState(null)
  const [fetchingUser, setFetchingUser] = useState(true)

  // Auto-login on reload using the token
  useEffect(() => {
    const token = getToken()

    if (token && !user) {
      api.defaults.headers.Authorization = `Bearer ${token}`
      api
        .post('/authorization/auto_login')
        .then((res) => {
          setUser(res.data)
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) removeToken()
          else {
            addToast({
              type: 'error',
              title: 'Erro do sistema.',
              description: 'Erro ao conectar com o servidor.'
            })
          }
        })
        .finally(() => setFetchingUser(false))
    } else {
      setFetchingUser(false)
    }
  }, [user])

  const loginUser = async (credentials, cb) => {
    try {
      const response = await api.post('/authorization', credentials)

      if (response.status === 200) {
        setUser(response.data.user)
        api.defaults.headers.Authorization = `Bearer ${response.data.token}`
        localStorage.setItem(TOKEN_KEY, response.data.token)

        addToast({
          type: 'sucess',
          title: 'Login feito com sucesso',
          description: 'Bem-vinda(o) ao MurOn'
        })
        cb()
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        addToast({
          type: 'error',
          title: 'Erro na autenticação.',
          description: 'Cheque suas credenciais!'
        })
      } else {
        addToast({
          type: 'error',
          title: 'Erro do sistema.',
          description: 'Erro ao conectar com o servidor.'
        })
      }
    }
  }

  const logoutUser = () => {
    setUser(null)
    removeToken()
  }

  return (
    <>
      {!fetchingUser && (
        <AuthContext.Provider
          value={{ signed: !!user, user, loginUser, logoutUser }}
        >
          {children}
        </AuthContext.Provider>
      )}
    </>
  )
}

export const useAuth = () => useContext(AuthContext)

export default AuthContextProvider
