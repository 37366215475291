import styled from 'styled-components'

export const ImageContainer = styled.div`
  max-height: 150vh;
  width: 100%;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.15));
  border-radius: 5px;
  overflow: hidden;
`

export const Image = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`
