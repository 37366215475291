import { Autocomplete, TextField } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'

import * as Styled from './styles.js'

export default function AutocompleteInput({
  name,
  label,
  control,
  options,
  ...props
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ...field } }) => (
        <Styled.Container>
          <Styled.Label>{label}</Styled.Label>
          <Autocomplete
            {...props}
            {...field}
            size="small"
            fullWidth
            options={options}
            renderInput={(params) => <TextField {...params} />}
            onChange={(e, data) => onChange(data)}
          />
        </Styled.Container>
      )}
    />
  )
}
