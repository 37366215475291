import React from 'react'

import { useAuth } from '../../contexts/AuthContext'
import { useMobile } from '../../utils/useMobile'
import AskNotificationsMobile from './Mobile'
import AskNotificationsWeb from './Web'

const AskNotifications = (props) => {
  const { signed } = useAuth()

  if (!signed) return null

  if (useMobile()) {
    return <AskNotificationsMobile {...props} />
  } else {
    return <AskNotificationsWeb {...props} />
  }
}

export default AskNotifications
