import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: space-evenly;
`

export const Content = styled.div`
  display: flex;
  width: 100rem;
  align-items: center;
  justify-content: space-evenly;
`

export const Image = styled.img`
  width: 60%;
  height: 100%;
  object-fit: contain;
`

export const Form = styled.div`
  width: 40%;
  height: 90%;
  display: flex;
  margin-right: 3rem;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
`

export const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: 500;
  color: var(--azul-escuro);
`

export const Input = styled.input`
  height: 2.5rem;
  width: 70%;
  font-size: 1.125rem;
  border: 2px solid var(--cinza-medio);
  border-radius: 5px;
  padding-left: 0.5rem;
  &:focus {
    border: 2px solid black;
    transition: border-color 0.3s ease-in-out;
    outline: 0;
  }
`

export const TextArea = styled.textarea`
  height: 20rem;
  width: 70%;
  font-size: 1.125rem;
  border: 2px solid var(--cinza-medio);
  border-radius: 5px;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  resize: none;

  &:focus {
    border: 2px solid black;
    transition: border-color 0.3s ease-in-out;
    outline: 0;
  }
`

export const TitleDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Button = styled.button`
  width: 10rem;
  height: 4rem;
  background-color: var(--azul-escuro);
  border-radius: 5px;
  color: white;
  border-color: var(--azul-escuro);
  border-width: 0.01rem;
  font-size: 25px;
  font-weight: 500;
  :hover {
    background-color: white;
    color: var(--azul-escuro);
  }
`
export const Field = styled.div`
  min-height: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Label = styled.label`
  font-size: 18px;
`
