import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
`

export const RegisterDiv = styled.div`
  height: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`

export const Image = styled.img`
  width: 10rem;
  height: 3rem;
  object-fit: contain;
`

export const Field = styled.div`
  min-height: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Label = styled.label`
  font-size: 18px;
`

export const Input = styled.input`
  width: 18rem;
  height: 2.5rem;
  font-size: 18px;
  padding-left: 0.5rem;
`

export const ButtonDiv = styled.div`
  min-width: 12rem;
  max-width: 20rem;
  display: flex;
  justify-content: center;
`

export const Button = styled.button`
  width: 14rem;
  height: 2.5rem;
  background-color: white;
  border-radius: 3px;
  color: #3370e5;
  border: 1px solid #3370e5;
  font-size: 18px;
  :hover {
    background-color: #3370e5;
    color: white;
  }
`

export const Title = styled.p`
  margin: 0;
  text-align: center;
  font-size: 21px;
`

export const LinkDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const LinkButton = styled(Link)`
  color: #3370e5;
  text-decoration: none;
`
