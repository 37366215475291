import React from 'react'
import { useHistory } from 'react-router-dom'

import { useAuth } from '../../contexts/AuthContext'
import AvatarThumbnail from '../AvatarThumbnail'
import { ContainerPerfil, LinkComponent, NameAuthor } from './styles.js'

const UserImage = ({ username, photo }) => {
  const history = useHistory()
  const { signed } = useAuth()
  return (
    <LinkComponent
      onClick={() => {
        if (signed) {
          history.push('/profile')
        }
      }}
    >
      <ContainerPerfil>
        <AvatarThumbnail
          src={photo}
          alt={`Avatar de ${username}`}
        ></AvatarThumbnail>
        <NameAuthor>{username}</NameAuthor>
      </ContainerPerfil>
    </LinkComponent>
  )
}

export default UserImage
