import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import NoImage from '../../../assets/Unimage.png'
import Footer from '../../../components/Footer'
import Header from '../../../components/Header'
import Input from '../../../components/Input'
import Textarea from '../../../components/Textarea'
import api from '../../../services/api'
import {
  Container,
  Image,
  ImageDiv,
  Label,
  PasswordDiv
} from './styles'

const ViewProfileMobile = () => {
  const [userData, setUserData] = useState([])
  const { id } = useParams()

  const handleUser = () => {
    api.get(`/users/${id}`).then((res) => {
      setUserData(res.data)
    })
  }

  useEffect(() => {
    handleUser()
  }, [])

  return (
    <>
      <Header />
      <Container>
        <Label>Perfil</Label>
        {userData.photo ? (
          <ImageDiv>
            <Image src={userData.photo}></Image>
          </ImageDiv>
        ) : (
          <ImageDiv>
            <Image src={NoImage}></Image>
          </ImageDiv>
        )}
        {userData.is_disseminator ? (
          <PasswordDiv>
            <Input
              label="Nome Divulgador/Empresa"
              disabled
              responsive
              defaultValue={userData.full_name}
            ></Input>
            <Input
              label="Nome do responsável"
              responsive
              disabled
              defaultValue={userData.partner}
            ></Input>
            <Input
              label="Telefone"
              responsive
              disabled
              defaultValue={userData.number}
            ></Input>
            <Input
              label="Site"
              responsive
              defaultValue={userData.site}
              disabled
            ></Input>
            <Textarea
              label="Descrição"
              responsive
              defaultValue={userData.description}
              disabled
            ></Textarea>
          </PasswordDiv>
        ) : (
          <PasswordDiv>
            <Input
              label="Nome"
              responsive
              disabled
              defaultValue={userData.full_name}
            ></Input>
            <Input
              label="Faculdade"
              responsive
              defaultValue={userData.university}
              disabled
            ></Input>
            <Input
              label="Curso"
              responsive
              disabled
              defaultValue={userData.course}
            ></Input>
            <Input
              label="Situação"
              name="situation"
              disabled
              responsive
              defaultValue={userData.situation}
            ></Input>
          </PasswordDiv>
        )}
      </Container>
      <Footer />
    </>
  )
}

export default ViewProfileMobile
