import styled from 'styled-components'

export const Container = styled.div`
  border-bottom: 1px solid var(--cinza-medio);
  max-width: 35rem;
  width: 90%;
  cursor: default;
  margin: 0 auto;
  :last-child {
    margin-bottom: 3rem;
    border-bottom: none;
  }

  margin-top: 3rem;
  padding-bottom: 1.5rem;
`

export const ContainerImage = styled.div`
  max-width: 38rem;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const ContainerPerfilHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CommentsImage = styled.img`
  height: 2.1rem;
  color: var(--azul-escuro);
`

export const Title = styled.h2`
  font-weight: 500;
  color: var(--azul-escuro);
  font-size: 1.5rem;
  word-break: break-word;
  a {
    color: var(--azul-escuro);
    text-decoration: none;
    transition: color 0.2s ease;
    &:hover {
      color: #2c59ae;
    }
  }
`

export const PostText = styled.p`
  color: var(--cinza-escuro);
`

export const PostDate = styled.span`
  color: (--cinza-medio);
  font-size: 0.9rem;
  font-weight: 700;
`

export const Button = styled.button`
  border: none;
  background-color: white;
  cursor: pointer;
  color: var(--azul-escuro);
  font-size: 1rem;
  font-weight: bold;
  transition: color 0.2s ease;
  &:hover {
    color: #2c59ae;
  }
`

export const ContainerIcons = styled.div`
  display: flex;
  align-items: center;
`

export const DeleteButton = styled.button`
  background-color: transparent;
  justify-self: flex-end;
  border: none;
  cursor: pointer;
`

export const ContainerFooter = styled.div`
  padding-top: 1rem;
  color: var(--cinza-escuro);
  display: flex;
  justify-content: space-between;
  a {
    color: var(--azul-escuro);
    text-decoration: none;
    transition: color 0.2s ease;
    &:hover {
      color: #2c59ae;
    }
  }
`
export const PostFooter = styled.div`
  padding-top: 1rem;
  width: 100%;
`
