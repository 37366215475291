import React from 'react'

import { useMobile } from '../../utils/useMobile.js'
import RegisterTypeMobile from './Mobile'
import RegisterTypeWeb from './Web'

const RegisterType = () => {
  if (useMobile()) {
    return <RegisterTypeMobile />
  }
  return <RegisterTypeWeb />
}

export default RegisterType
