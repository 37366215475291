import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { useToast } from '../../hooks/useToast'
import api from '../../services/api'
import { useMobile } from '../../utils/useMobile'
import PostPageMobile from './Mobile'
import PostPageWeb from './Web'

const PostPage = () => {
  const history = useHistory()
  const [postInfo, setPostInfo] = useState(null)
  const [open, setOpen] = useState(false)
  const [comments, setComments] = useState(null)
  const [replyText, setReplyText] = useState('')
  const [commentText, setCommentText] = useState('')

  const { id } = useParams()
  const { addToast } = useToast()

  const publishComment = () => {
    api
      .post(`/posts/${id}/comments`, {
        description: commentText
      })
      .then((res) => {
        addToast({
          type: 'sucess',
          title: 'Comentário criado',
          description: 'comentário foi criado com sucesso'
        })
        setComments((state) => [...state, res.data])
      })
      .catch((_err) => {
        addToast({
          type: 'error',
          title: 'Erro ao criar comentário',
          description: 'Não foi possível criar o comentário!'
        })
      })
  }

  const publishReply = (id) => {
    api
      .post(`/comments/${id}/replies`, {
        description: replyText
      })
      .then((res) => {
        addToast({
          type: 'sucess',
          title: 'Resposta criada',
          description: 'Resposta foi criada com sucesso'
        })
        setComments((state) =>
          state.map((comment) =>
            comment.id === id
              ? {
                  ...comment,
                  replies: [...comment.replies, res.data]
                }
              : comment
          )
        )
      })
      .catch((_err) => {
        addToast({
          type: 'error',
          title: 'Erro ao criar resposta',
          description: 'Não foi possível criar sua resposta!'
        })
      })
  }

  const getPost = async () => {
    await api.get(`/posts/${id}`).then((res) => {
      setPostInfo(res.data)
    })
  }

  const getComments = () => {
    api.get(`/posts/${id}/comments`).then((res) => {
      setComments(res.data)
    })
  }

  const deletePost = () => {
    api.delete(`/posts/${postInfo.id}`).then((res) => {
      history.push('/')
      addToast({
        type: 'sucess',
        title: 'Post deletado',
        description: 'Seu post foi deletado com sucesso!'
      })
    })
  }

  const deleteComment = (id) => {
    api.delete(`/comments/${id}`).then((res) => {
      setComments(comments.filter((element) => element.id !== id))
      addToast({
        type: 'sucess',
        title: 'Comentário deletado',
        description: 'Comentário foi deletado com sucesso'
      })
    })
  }

  const deleteReply = (id, commentId) => {
    api.delete(`/replies/${id}`).then((res) => {
      addToast({
        type: 'sucess',
        title: 'Resposta deletada',
        description: 'Resposta foi deletada com sucesso'
      })
      setComments((prevState) =>
        prevState.map((comment) =>
          comment.id === commentId
            ? {
                ...comment,
                replies: comment.replies.filter(
                  (reply) => reply.id !== id
                )
              }
            : comment
        )
      )
    })
  }

  useEffect(() => {
    getPost()
    getComments()
  }, [])

  const state = {
    postInfo: postInfo,
    open: open,
    comments: comments,
    replyText: replyText,
    commentText: commentText
  }

  const methods = {
    publishComment: publishComment,
    publishReply: publishReply,
    deletePost: deletePost,
    deleteComment: deleteComment,
    deleteReply: deleteReply,
    setCommentText: setCommentText,
    setReplyText: setReplyText,
    setOpen: setOpen
  }

  if (useMobile()) {
    return <PostPageMobile methods={methods} state={state} />
  } else {
    return <PostPageWeb methods={methods} state={state} />
  }
}

export default PostPage
