import React, { useState } from 'react'

import { useAuth } from '../../../contexts/AuthContext'
import api from '../../../services/api'
import { urlBase64ToUint8Array } from '../../../utils/urlBase64ToUint8Array'
import * as styled from './styles'

function askPermission() {
  return new Promise(function (resolve, reject) {
    const permissionResult = window.Notification.requestPermission(
      function (result) {
        resolve(result)
      }
    )

    if (permissionResult) {
      permissionResult.then(resolve, reject)
    }
  }).then(function (permissionResult) {
    if (permissionResult !== 'granted') {
      throw new Error("We weren't granted permission.")
    }
  })
}

const AskNotificationsWeb = () => {
  const { user } = useAuth()
  const [pushBannerViewed, setPushBannerViewed] = useState(() => {
    return JSON.parse(
      localStorage.getItem(`push-banner-viewed:${user.id}`)
    )
  })

  const setBannerViewed = () => {
    localStorage.setItem(`push-banner-viewed:${user.id}`, true)
    setPushBannerViewed(true)
  }

  const registerPush = async () => {
    const APP_SERVER_KEY =
      'BBGiAZK9MPO3YV_9xI0DQJsX3pI2u6WR9_580tpQvC-N0YUMH6CSX1RhmFNLJc3GTS0fyGVyO89ewWbWnhzAWzU='
    try {
      const registration =
        await navigator.serviceWorker.getRegistration()

      if (registration) {
        askPermission()
          .then(() => {
            const options = {
              userVisibleOnly: true,
              applicationServerKey:
                urlBase64ToUint8Array(APP_SERVER_KEY)
            }
            return registration.pushManager.subscribe(options)
          })
          .then((pushSubscription) => {
            const parsedPushSubscription = JSON.parse(
              JSON.stringify(pushSubscription)
            )
            api.post(`/users/${user.id}/vapid_keys`, {
              endpoint: parsedPushSubscription.endpoint,
              p256dh_key: parsedPushSubscription.keys.p256dh,
              auth_key: parsedPushSubscription.keys.auth
            })
          })
          .catch(console.error)
      }
    } catch (err) {
      console.error(err)
    } finally {
      setBannerViewed()
    }
  }

  if (pushBannerViewed) return null

  return (
    <styled.Container>
      <styled.Content>
        <styled.Text>
          Deseja ficar por dentro do que rola na universidade? Ative as
          notificações do MurOn!
        </styled.Text>
        <styled.Button onClick={setBannerViewed}>
          Agora não
        </styled.Button>
        <styled.Button primary onClick={registerPush}>
          Ativar
        </styled.Button>
      </styled.Content>
    </styled.Container>
  )
}

export default AskNotificationsWeb
