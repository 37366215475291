export const validatePost = (postData) => {
  let description = false
  const title = 'Erro ao criar nova publicação.'

  if (!postData.title && !postData.attachments.length)
    description = 'Insira um título ou imagens'
  else if (!postData.tag) description = 'Insira uma tag'

  if (description) return { title: title, description: description }
}
