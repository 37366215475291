import React from 'react'

import { useMobile } from '../../utils/useMobile'
import DisseminatorSignupMobile from './Mobile'
import DisseminatorSignupWeb from './Web'

const DisseminatorSignup = () => {
  if (useMobile()) {
    return <DisseminatorSignupMobile />
  } else {
    return <DisseminatorSignupWeb />
  }
}

export default DisseminatorSignup
