import React from 'react'

import { useMobile } from '../../utils/useMobile'
import ViewProfileMobile from './Mobile'
import ViewProfileWeb from './Web'

const ViewProfile = () => {
  if (useMobile()) {
    return <ViewProfileMobile />
  } else {
    return <ViewProfileWeb />
  }
}

export default ViewProfile
