import React, { useState } from 'react'
import { useParams, Link } from 'react-router-dom'

import ForgotPassword from '../../../assets/forgotpassword.png'
import Logo from '../../../assets/logo.png'
import Visibility from '../../../assets/visibility.svg'
import VisibilityOff from '../../../assets/visibilityOff.svg'
import Button from '../../../components/Button'
import { useToast } from '../../../hooks/useToast'
import api from '../../../services/api.js'
import { history } from '../../../utils/history.js'
import * as Styled from './styles'

const PasswordRecovery = () => {
  const [password, setPassword] = useState('')
  const [confirmation, setConfirmation] = useState('')
  const { addToast } = useToast()
  const token = useParams().token

  const handleSubmit = () => {
    api
      .post('/login/reset', {
        reset_token: token,
        password: password,
        password_confirmation: confirmation
      })
      .then((res) => {
        if (res.status === 200) {
          history.push('/login')
          addToast({
            type: 'sucess',
            title: 'Senha alterada com sucesso!',
            description: 'Suas senha foi alterada com sucesso.'
          })
        } else {
          addToast({
            type: 'error',
            title: 'Erro ao alterar senha!',
            description: 'Cheque sua senha e tente novamente.'
          })
        }
      })
  }

  // Lógica para mostrar e escondar a senha digitada
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const handleClickShowPassword = (showPassword) => {
    setShowPassword(!showPassword)
  }
  const handleClickShowConfirmPassword = (showConfirmPassword) => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  return (
    <Styled.Div>
      <Styled.SubDiv>
        <Styled.RegisterDiv>
          <Link to="/">
            <Styled.Logo src={Logo} />
          </Link>
          <Styled.Paragraph>Informe sua nova senha:</Styled.Paragraph>
          <Styled.Form>
            <Styled.InputDiv>
              <Styled.Label>Senha</Styled.Label>
              <div>
                <Styled.Input
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Styled.PasswordEyeIcon
                  src={showPassword ? VisibilityOff : Visibility}
                  onClick={() => handleClickShowPassword(showPassword)}
                />
              </div>
            </Styled.InputDiv>
            <Styled.InputDiv>
              <Styled.Label>Confirmar senha</Styled.Label>
              <div>
                <Styled.Input
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={confirmation}
                  onChange={(e) => setConfirmation(e.target.value)}
                />
                <Styled.PasswordEyeIcon
                  src={showConfirmPassword ? VisibilityOff : Visibility}
                  onClick={() =>
                    handleClickShowConfirmPassword(showConfirmPassword)
                  }
                />
              </div>
            </Styled.InputDiv>
            <Styled.ButtonDiv>
              <Button responsive type="button" onClick={handleSubmit}>
                Alterar senha
              </Button>
            </Styled.ButtonDiv>
          </Styled.Form>
        </Styled.RegisterDiv>
        <Styled.Image src={ForgotPassword} />
      </Styled.SubDiv>
    </Styled.Div>
  )
}
export default PasswordRecovery
