import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 5rem;
`
export const Label = styled.p`
  font-size: 2rem;
  font-weight: bold;
  color: var(--azul-escuro);
  margin-top: 2rem;
  margin-bottom: 1rem;
`
export const ImageDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  gap: 1rem;
`
export const Username = styled.p`
  color: var(--azul-escuro);
  font-size: 1.2rem;
  font-weight: bold;
`
export const Image = styled.img`
  width: 12rem;
  height: 12rem;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
  border: 1px solid gray;
`
export const PhotoSend = styled.label`
  input[type='file'] {
    display: none;
  }
  color: var(--azul-escuro);
  display: flex;
  justify-content: center;
  padding: 6px 12px;
  font-size: 1.2rem;
  cursor: pointer;
  font-weight: bold;
  gap: 0.5rem;
`
export const InputsDiv = styled.div`
  width: 85vw;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  gap: 1rem;
`
export const ButtonDiv = styled.div`
  display: flex;
  gap: 1.5rem;
  width: 85vw;
  justify-content: space-between;
`
export const IconDiv = styled.div`
  height: auto;
  border: 1px solid gray;
  border-radius: 50%;
`
export const Field = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  align-self: center;
`
