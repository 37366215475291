import Select from '@mui/material/Select'
import React from 'react'
import { Controller } from 'react-hook-form'

import * as Styled from './styles.js'

export default function SelectInput({
  name,
  label,
  control,
  children,
  ...props
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Styled.Container>
          <Styled.Label>{label}</Styled.Label>
          <Select {...props} {...field} size="small" fullWidth>
            {children}
          </Select>
        </Styled.Container>
      )}
    />
  )
}
