import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: space-evenly;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`

export const RegisterDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
`

export const Image = styled.img`
  width: 10rem;
  height: 3rem;
  object-fit: contain;
`

export const Title = styled.p`
  margin: 0;
  text-align: center;
  font-size: 21px;
  margin-bottom: 3rem;
  margin-top: 1.5rem;
`

export const ContainerButton = styled.div`
  width: 100%;
  button:last-child {
    margin-top: 1rem;
  }
`

export const LinkDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  a {
    margin-left: 0.2rem;
    color: var(--azul-escuro);
    text-decoration: none;
  }
`

export const FooterLinkDiv = styled.div`
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  place-content: center;
`
export const FooterLink = styled.a`
  font-size: 13px;
  color: var(--cinza-escuro);
`
export const Bar = styled.div`
  width: 0rem;
  height: 1.5rem;
  border: 0.2px solid grey;
  margin: 1rem;
`
export const Image2 = styled.img`
  width: 40%;
  height: 100%;
  object-fit: contain;
`

export const ContainerFooter = styled.div`
  height: 10vh;
  width: 100%;
  display: flex;

  a {
    color: #6b6b6b;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }

  a:last-child {
    margin-left: 1rem;
    height: 1rem;
  }

  justify-content: center;
  align-items: center;
  place-content: center;
`

export const ContainerFirstLink = styled.div`
  padding-right: 1rem;
  border-right: 2px solid #6b6b6b;
  height: 1rem;
`
