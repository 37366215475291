import React from 'react'

import { useMobile } from '../../utils/useMobile'
import RecoverPassword1Mobile from './Mobile'
import RecoverPassword1Web from './Web'

const RecoverPassword1 = () => {
  if (useMobile()) return <RecoverPassword1Mobile />
  else return <RecoverPassword1Web />
}

export default RecoverPassword1
