import React from 'react'

import { useMobile } from '../../utils/useMobile.js'
import RegisterSignUpMobile from './Mobile'
import RegisterSignUpWeb from './Web'

const RegisterSignUp = () => {
  if (useMobile()) {
    return <RegisterSignUpMobile />
  }
  return <RegisterSignUpWeb />
}

export default RegisterSignUp
