import styled from 'styled-components'

export const Option = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  padding: 0 0.5rem;
  align-items: center;
  height: 2.5rem;
  transition: all 300ms ease;
  border-radius: 5px;

  &:hover {
    background-color: #0000000e;
  }

  &:after {
    position: absolute;
    content: '';
    background-color: var(--cinza-medio);
    width: 100%;
    height: 1px;
    left: 0;
    bottom: -2px;
  }
`
