import styled from 'styled-components'

export const FooterDiv = styled.div`
  height: 5.6vh;
  width: 100%;
  background-color: var(--azul-escuro);
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: space-evenly;
  bottom: 0;
  z-index: 4;
`
