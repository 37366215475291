/* eslint-disable react/react-in-jsx-scope */

import { useMobile } from '../../utils/useMobile'
import Footer from '../Footer'
import HeaderMobile from './Mobile'
import HeaderWeb from './Web'

const Header = ({ line, onSearch }) => {
  if (useMobile()) {
    return (
      <>
        <HeaderMobile onSearch={onSearch} />
        <Footer />
      </>
    )
  } else {
    return <HeaderWeb line={line} onSearch={onSearch} />
  }
}

export default Header
