import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: space-evenly;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Image = styled.img`
  width: 10rem;
  height: 3.5rem;
  object-fit: contain;
`

export const Background = styled.img`
  width: 50%;
  height: 100%;
  object-fit: contain;
`

export const ParagraphLink = styled.p`
  margin-bottom: 1rem;
  a {
    text-decoration: none;
    transition: 0.2s;
    color: #3370e5;

    &:hover {
      filter: brightness(0.9);
    }
  }
`

// export const ContainerBackground = styled.div`
// display: flex;
// width: 50%;
// align-items: center;
// justify-content: center;
// place-content: center;
// `

export const ContainerFooter = styled.div`
  height: 10vh;
  width: 100%;
  display: flex;

  a {
    color: #6b6b6b;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }

  a:last-child {
    margin-left: 1rem;
    height: 1rem;
  }

  justify-content: center;
  align-items: center;
  place-content: center;
`

export const ContainerButton = styled.div`
  width: 50%;
`

export const ContainerFirstLink = styled.div`
  padding-right: 1rem;
  border-right: 2px solid #6b6b6b;
  height: 1rem;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
`

export const PasswordEyeIcon = styled.img`
  cursor: pointer;
  margin-left: -2.5rem;
  margin-bottom: -0.5rem;
`

export const Input = styled.input`
  height: 2.5rem;
  font-size: 1.125rem;
  border: 2px solid var(--cinza-medio);
  border-radius: 5px;
  padding-left: 0.5rem;
  padding-right: 3rem;
`

export const Field = styled.div`
  min-height: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Label = styled.label`
  font-size: 18px;
`
