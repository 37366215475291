import React from 'react'

import Footer from '../../../components/Footer'
import Header from '../../../components/Header/Mobile'
import SearchInput from '../../../components/SearchInput'
import Users from '../../../components/Users/Web'
import * as Styled from './styles.js'

const BackOfficeUsersMobile = ({ state, methods, onDelete }) => (
  <>
    <Header
      onSearch={(query) =>
        methods.fetchUsers(
          'q[username_or_course_or_university_or_full_name_cont]=' +
            query
        )
      }
    />
    <Styled.Container>
      <Styled.Content>
        <Styled.Title>Usuários:</Styled.Title>
        <SearchInput
          placeholder={'Pesquise por usuários'}
          onSearch={(query) =>
            methods.fetchUsers(
              'q[username_or_course_or_university_or_full_name_cont]=' +
                query
            )
          }
        />
        <Styled.ContainerUsers>
          {state.users.length > 0 ? (
            state.users.map((user) => (
              <Users
                key={user.id}
                user={user}
                id={user.id}
                onDelete={onDelete}
              />
            ))
          ) : (
            <Styled.Text>Não há usuários pendentes.</Styled.Text>
          )}
        </Styled.ContainerUsers>
      </Styled.Content>
    </Styled.Container>
    <Footer></Footer>
  </>
)

export default BackOfficeUsersMobile
