import { React, useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'

import { useAuth } from '../../contexts/AuthContext'
import { useToast } from '../../hooks/useToast'
import api from '../../services/api'
import { useMobile } from '../../utils/useMobile.js'
import HomeMobile from './Mobile'
import HomeWeb from './Web'

const Home = () => {
  const { addToast } = useToast()
  const [posts, setPosts] = useState([])
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const [query, setQuery] = useState('')

  const { tagId } = useParams()
  const { user } = useAuth()
  const page = useRef(1)

  const userId = user?.id && { user_id: user.id }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    fetchPosts({ reset: true })
  }, [tagId, query])

  const fetchPosts = (options) => {
    setLoading(true)
    if (options?.reset) page.current = 1
    api
      .get('/posts', {
        params: new URLSearchParams({
          tag: tagId || '',
          page: page.current,
          query,
          ...userId
        })
      })
      .then((res) => {
        if (res.status === 200) {
          if (options?.reset) setPosts([...res.data.posts])
          else setPosts([...posts, ...res.data.posts])
          page.current = res.data.next_page
          setHasMore(res.data.next_page != null)
          setLoading(false)
        }
      })
      .catch((err) => {
        console.error(err)
        addToast({
          type: 'error',
          title: 'Erro ao conectar com o servidor',
          description: 'Não foi possível conectar com o servidor.'
        })
        setLoading(true)
      })
  }

  const state = {
    posts,
    loading,
    hasMore,
    page
  }

  const methods = {
    fetchPosts,
    setQuery
  }

  if (useMobile()) {
    return <HomeMobile state={state} methods={methods} />
  } else {
    return <HomeWeb state={state} methods={methods} />
  }
}

export default Home
