import InstalarComputador from '../../../../assets/installation_edge.png'
import {
  Image,
  ImageInstallContainer,
  InstallContainer,
  Text,
  Title
} from '../styles.js'

const Chrome = () => {
  return (
    <InstallContainer>
      <Title>Adicione o MurOn ao seu dispositivo</Title>
      <Text>
        Para instalar o MurOn em seu dispositivo, clique no ícone de
        aplicativos que aparece no canto direito da barra de pesquisa,
        como mostrado na imagem abaixo.
      </Text>
      <br></br>
      <ImageInstallContainer>
        <Image
          src={InstalarComputador}
          alt="Imagem de instrução para adicionar atalho no computador."
          width="100%"
        />
      </ImageInstallContainer>
    </InstallContainer>
  )
}

export default Chrome
