import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useToast } from '../../hooks/useToast'
import api from '../../services/api'
import { useMobile } from '../../utils/useMobile'
import ConfirmEmailMobile from './Mobile'
import ConfirmEmailWeb from './Web'

const ConfirmEmail = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [success, setSuccess] = useState()
  const { id, token } = useParams()
  const { addToast } = useToast()

  // Sends a verification request to the server using
  // the token provided in the params of the url
  // If the request is successful, a success page is
  // otherwise, an failure page is rendered, providing
  // a option to resend a confirmation email
  useEffect(() => {
    api
      .post(`/verify`, { verification_token: token, id: id })
      .then(() => setSuccess(true))
      .catch((err) => {
        setSuccess(false)
        if (err.response) {
          if (err.response.status === 400)
            addToast({
              type: 'error',
              title: 'Erro ao verificar email',
              description: 'O token de verificação está expirado.'
            })
          if (err.response.status === 403) {
            addToast({
              type: 'error',
              title: 'Erro ao verificar email',
              description: 'Este usuário já possui email verificado.'
            })
            history.replace('/login')
          }
          if (err.response.status === 404) {
            addToast({
              type: 'error',
              title: 'Erro ao verificar email',
              description: 'Este usuário não existe.'
            })
          }
        } else {
          addToast({
            type: 'error',
            title: 'Erro conectar com o servidor',
            description: 'Ocorreu eu erro ao conectar com o servidor.'
          })
        }
      })
      .finally(() => setLoading(false))
  }, [])

  // Refreshes token and expiration time, sending another email
  // to the users email
  const resendToken = () => {
    api
      .post('/refresh_verification', {
        verification_token: token,
        id: id
      })
      .then(() => {
        addToast({
          type: 'sucess',
          title: 'Email de confirmação reenviado',
          description:
            'Confira seu email para usar a nova confirmação de email!'
        })
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 404) {
            addToast({
              type: 'error',
              title: 'Não foi possível reenviar email',
              description: 'Este usuário não existe.'
            })
          } else {
            addToast({
              type: 'error',
              title: 'Não foi possível reenviar email',
              description: 'O token de validação é inválido'
            })
          }
        } else {
          addToast({
            type: 'error',
            title: 'Erro conectar com o servidor!',
            description: 'Ocorreu eu erro ao conectar com o servidor.'
          })
        }
      })
  }

  const state = {
    loading: loading,
    success: success
  }

  const methods = {
    resendToken: resendToken
  }

  if (useMobile()) {
    return <ConfirmEmailMobile state={state} methods={methods} />
  } else {
    return <ConfirmEmailWeb state={state} methods={methods} />
  }
}

export default ConfirmEmail
