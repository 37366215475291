import Camera from '@mui/icons-material/CameraAlt'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import SaveIcon from '@mui/icons-material/Save'
import MenuItem from '@mui/material/MenuItem'
import { csv } from 'd3'
import FormData from 'form-data'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import cursosGraduação from '../../../assets/csv/cursos_graduacao_unb.csv'
import iesCSV from '../../../assets/csv/ies.csv'
import NoImage from '../../../assets/Unimage.png'
import Autocomplete from '../../../components/AutocompleteInput'
import Button from '../../../components/Button'
import ConfirmationModal from '../../../components/ConfirmationModal/Web'
import Header from '../../../components/Header'
import SelectInput from '../../../components/SelectInput'
import Textarea from '../../../components/Textarea'
import TextInput from '../../../components/TextInput'
import { useAuth } from '../../../contexts/AuthContext'
import { useToast } from '../../../hooks/useToast'
import api from '../../../services/api'
import {
  ButtonDiv,
  Container,
  Content,
  FirstLine,
  FormDiv,
  Image,
  ImageDiv,
  Inputs,
  Label,
  PhotoSend,
  Username
} from './styles'

const ProfileWeb = () => {
  const { user, logoutUser } = useAuth()
  const [url, setUrl] = useState('')
  const history = useHistory()
  const { control, handleSubmit, getValues } = useForm({ mode: 'all' })
  const [photo, setPhoto] = useState()
  const { addToast } = useToast()
  const [modalConfirmation, setConfirmationModal] = useState(false)
  const [infosHasChanged, setInfosHasChanged] = useState(false)
  const [cursos, setCursos] = useState([])
  const [faculdades, setFaculdades] = useState([])
  const [userData, setUserData] = useState({
    full_name: user.full_name,
    partner: user.partner,
    site: user.site,
    university: user.university,
    course: user.course,
    situation: user.situation,
    photo: user.photo,
    old_password: '',
    password: '',
    password_confirmation: ''
  })

  useEffect(() => {
    csv(cursosGraduação).then((data) => setCursos(data))
    csv(iesCSV).then((data) => setFaculdades(data))
  }, [])

  const handleDeleteUser = () => {
    api
      .delete(`/users/${user.id}`)
      .then(() => {
        addToast({
          type: 'sucess',
          title: 'Usuário deletado com sucesso',
          description:
            'O usuário foi deletado com sucesso do nosso banco de dados.'
        })
        history.push('/')
        logoutUser()
        handleCloseConfirmationModal()
      })
      .catch((err) => {
        console.log(err)
        addToast({
          type: 'error',
          title: 'Ocorreu um erro ao deletar o usuário',
          description: err.error
        })
      })
  }
  const handleOpenConfirmationModal = () => {
    setConfirmationModal(true)
  }

  const handleCloseConfirmationModal = () => {
    setConfirmationModal(false)
  }

  const updateProfile = async (values, e) => {
    e.preventDefault()
    const formData = new FormData()
    if (photo) {
      formData.append('photo', photo)
    }
    formData.append('full_name', userData.full_name)
    formData.append('partner', userData.partner)
    formData.append('number', userData.number)
    formData.append('site', userData.site)

    if (user.is_admin && !values.Curso) {
      formData.append('university', '')
    } else if (values.Faculdade === null) {
      addToast({
        type: 'error',
        title: 'Erro ao alterar informações!',
        description: 'Selecione uma faculdade válida!'
      })
      return
    } else if (values.Faculdade !== undefined) {
      formData.append('university', values.Faculdade.nome)
    } else {
      formData.append('university', userData.university)
    }

    if (user.is_admin && !values.Curso) {
      formData.append('course', '')
    } else if (values.Curso === null) {
      addToast({
        type: 'error',
        title: 'Erro ao alterar informações!',
        description: 'Selecione um curso válido!'
      })
      return
    } else if (values.Curso !== undefined) {
      formData.append('course', values.Curso.nome)
    } else {
      formData.append('course', userData.course)
    }

    if (values.Situação === null) {
      addToast({
        type: 'error',
        title: 'Erro ao alterar informações!',
        description: 'Selecione uma situação válido!'
      })
      return
    } else if (values.Situação !== undefined) {
      formData.append('situation', values.Situação)
    } else {
      formData.append('situation', userData.situation)
    }

    formData.append('password', userData.password)
    formData.append(
      'password_confirmation',
      userData.password_confirmation
    )
    formData.append('old_password', userData.old_password)
    if (userData.password === userData.password_confirmation) {
      await api
        .put(`/users/${user.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(() => {
          addToast({
            type: 'sucess',
            title: 'Informações alteradas!',
            description: 'Suas informações foram alteradas com sucesso.'
          })
        })
        .catch(() => {
          addToast({
            type: 'error',
            title: 'Erro ao alterar informações!',
            description: 'Cheque suas informações e tente novamente.'
          })
        })
        .catch((err) => console.error(err.response))
    } else {
      addToast({
        type: 'error',
        title: 'Erro ao alterar informações!',
        description: 'As senhas devem ser iguais!'
      })
    }
  }
  const imageUrl = (e) => {
    setInfosHasChanged(true)
    setUrl(URL.createObjectURL(e.target.files[0]))
    setPhoto(e.target.files[0])
  }

  const handleChange = (e) => {
    setInfosHasChanged(true)
    const { name, value } = e.target
    setUserData((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  return (
    <>
      <Header />
      <Container>
        <Content>
          {user.photo ? (
            <ImageDiv>
              <Username>{user.username}</Username>
              {photo ? (
                <Image src={url} />
              ) : (
                <Image src={user.photo} alt="user photo" />
              )}
              <PhotoSend>
                <Camera />
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={imageUrl}
                ></input>
                Alterar foto de perfil
              </PhotoSend>
            </ImageDiv>
          ) : (
            <ImageDiv>
              <Username>{user.username}</Username>
              {url ? (
                <Image src={url} alt="user photo"></Image>
              ) : (
                <Image src={NoImage}></Image>
              )}
              <PhotoSend>
                <Camera />
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={imageUrl}
                ></input>
                Alterar foto de perfil
              </PhotoSend>
            </ImageDiv>
          )}
          <FormDiv>
            <div>
              <Label>Alterar perfil</Label>
              {user.is_disseminator ? (
                <Inputs>
                  <FirstLine>
                    <TextInput
                      style={{ width: '100%' }}
                      control={control}
                      label="Nome Divulgador/Empresa"
                      responsive
                      type="text"
                      defaultValue={user.full_name}
                      name="full_name"
                      onChange={handleChange}
                    />
                    <TextInput
                      style={{ width: '100%' }}
                      control={control}
                      type="text"
                      label="Nome do responsável"
                      responsive
                      name="partner"
                      defaultValue={user.partner}
                      onChange={handleChange}
                    />
                  </FirstLine>
                  <FirstLine>
                    <TextInput
                      style={{ width: '100%' }}
                      control={control}
                      type="text"
                      label="Telefone"
                      responsive
                      name="number"
                      defaultValue={user.number}
                      onChange={handleChange}
                    />
                    <TextInput
                      style={{ width: '100%' }}
                      control={control}
                      type="text"
                      label="Site"
                      responsive
                      defaultValue={user.site}
                      name="site"
                      onChange={handleChange}
                    />
                  </FirstLine>
                  <Textarea
                    type="text"
                    label="Descrição"
                    responsive
                    style={{ maxWidth: '70%' }}
                    defaultValue={user.description}
                    disabled
                  />
                </Inputs>
              ) : (
                <Inputs>
                  <section>
                    <FirstLine>
                      <div style={{ width: '100%' }}>
                        <TextInput
                          style={{ width: '100%' }}
                          control={control}
                          type="text"
                          label="Nome"
                          name="full_name"
                          defaultValue={user.full_name}
                          onChange={handleChange}
                        />
                      </div>
                      <div
                        style={{ width: '100%' }}
                        onClick={() => setInfosHasChanged(true)}
                      >
                        <Autocomplete
                          name="Faculdade"
                          label="Faculdade"
                          control={control}
                          defaultValue={{ nome: userData.university }}
                          getOptionLabel={(option) => option.nome}
                          options={faculdades}
                        />
                      </div>
                    </FirstLine>
                  </section>
                  <section>
                    <FirstLine>
                      <div
                        style={{ width: '100%' }}
                        onClick={() => setInfosHasChanged(true)}
                      >
                        <Autocomplete
                          name="Curso"
                          label="Curso"
                          control={control}
                          defaultValue={{
                            nome: userData.course,
                            turno: '',
                            grau_academico: ''
                          }}
                          getOptionLabel={(option) =>
                            `${option.nome}  ${option.turno} ${option.grau_academico}`
                          }
                          options={cursos}
                        />
                      </div>
                      <div
                        style={{ width: '100%' }}
                        onClick={() => setInfosHasChanged(true)}
                      >
                        <SelectInput
                          name="Situação"
                          label="Situação"
                          value={getValues('Situação')}
                          defaultValue={userData.situation}
                          control={control}
                        >
                          {user.is_admin && (
                            <MenuItem value={''}></MenuItem>
                          )}
                          <MenuItem value={'Aluno'}>Aluno</MenuItem>
                          <MenuItem value={'Ex-aluno'}>
                            Ex-aluno
                          </MenuItem>
                          <MenuItem value={'Professor'}>
                            Professor
                          </MenuItem>
                          <MenuItem value={'Colaborador'}>
                            Colaborador
                          </MenuItem>
                        </SelectInput>
                      </div>
                    </FirstLine>
                  </section>
                </Inputs>
              )}
            </div>
            <div>
              <Label>Alterar senha</Label>
              <FirstLine>
                <TextInput
                  style={{ width: 'calc(50% - 0.75rem)' }}
                  control={control}
                  type="password"
                  label="Senha atual"
                  name="old_password"
                  onChange={handleChange}
                />
              </FirstLine>

              <FirstLine>
                <TextInput
                  style={{ width: '100%' }}
                  control={control}
                  type="password"
                  label="Nova senha"
                  responsive
                  name="password"
                  onChange={handleChange}
                />
                <TextInput
                  style={{ width: '100%' }}
                  control={control}
                  type="password"
                  label="Confirmar nova senha"
                  responsive
                  name="password_confirmation"
                  onChange={handleChange}
                />
              </FirstLine>
              <ButtonDiv>
                <Button red onClick={handleOpenConfirmationModal}>
                  <DeleteForeverIcon />
                  Deletar conta
                </Button>
                <Button
                  disabled={!infosHasChanged}
                  onClick={handleSubmit(updateProfile)}
                >
                  <SaveIcon />
                  Salvar alterações
                </Button>
              </ButtonDiv>
            </div>
          </FormDiv>
        </Content>
        <ConfirmationModal
          isOpen={modalConfirmation}
          name={user.username}
          id={user.id}
          onDelete={handleDeleteUser}
          handleClosedConfirmationModal={handleCloseConfirmationModal}
        ></ConfirmationModal>
      </Container>
    </>
  )
}

export default ProfileWeb
