import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import React, { useState, useEffect, useImperativeHandle } from 'react'

import PostAttachment from '../PostAttachment'
import * as styled from './styles'

const PostImages = React.forwardRef(({ files }, ref) => {
  const [page, setPage] = useState(0)

  // Provides the current state of 'page'
  // while using a forwardRef
  useImperativeHandle(
    ref,
    () => ({
      getPage: () => page
    }),
    [page]
  )

  // Handles page change when the last page
  // of the image previewer is removed
  useEffect(() => {
    if (page > 0 && files.length - 1 < page)
      setPage((prevState) => prevState - 1)
  }, [files])

  return (
    <styled.ImageContainer>
      {files && page > 0 && (
        <styled.ArrowWrapper left onClick={() => setPage(page - 1)}>
          <KeyboardArrowLeftIcon />
        </styled.ArrowWrapper>
      )}
      {files && files.length > 0 && (
        <PostAttachment attachment={files[page]} />
      )}
      {files && page < files.length - 1 && (
        <styled.ArrowWrapper right onClick={() => setPage(page + 1)}>
          <KeyboardArrowRightIcon />
        </styled.ArrowWrapper>
      )}
      {files.length > 1 ? (
        <styled.CountImage>
          {page + 1} / {files.length}
        </styled.CountImage>
      ) : null}
    </styled.ImageContainer>
  )
})

export default PostImages
