/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import NoImage from '../../../assets/Unimage.png'
import Header from '../../../components/Header'
import Textarea from '../../../components/Textarea'
import TextInput from '../../../components/TextInput'
import api from '../../../services/api'
import {
  Container,
  Content,
  FirstLine,
  FormDiv,
  Image,
  ImageDiv,
  Inputs,
  Label,
  Username
} from './styles'

const ViewProfileWeb = () => {
  const [userData, setUserData] = useState()
  const [loading, setLoading] = useState(true)
  const { control } = useForm({ mode: 'all' })
  const { id } = useParams()

  const handleUser = () => {
    api.get(`/users/${id}`).then((res) => {
      setUserData(res.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    handleUser()
  }, [])

  return (
    <div>
      {loading ? (
        <></>
      ) : (
        <>
          <Header />
          <Container>
            <Content>
              <ImageDiv>
                <Username>{userData.username}</Username>
                {userData.photo ? (
                  <Image src={userData.photo} />
                ) : (
                  <Image src={NoImage} />
                )}
              </ImageDiv>
              <FormDiv>
                <div>
                  <Label>Perfil</Label>
                  {userData.is_disseminator ? (
                    <Inputs>
                      <FirstLine>
                        <TextInput
                          control={control}
                          label="Nome Divulgador/Empresa"
                          responsive
                          disabled
                          defaultValue={userData.full_name}
                          name="full_name"
                        />
                        <TextInput
                          control={control}
                          label="Nome do responsável"
                          responsive
                          disabled
                          name="partner"
                          defaultValue={userData.partner}
                        />
                      </FirstLine>
                      <FirstLine>
                        <TextInput
                          control={control}
                          label="Telefone"
                          responsive
                          disabled
                          name="number"
                          minlength="9"
                          maxLength="11"
                          defaultValue={userData.number}
                        />
                        <TextInput
                          control={control}
                          label="Site"
                          responsive
                          defaultValue={userData.site}
                          name="site"
                          disabled
                        />
                      </FirstLine>
                      <Textarea
                        label="Descrição"
                        responsive
                        style={{ maxWidth: '70%' }}
                        defaultValue={userData.description}
                        disabled
                      />
                    </Inputs>
                  ) : (
                    <Inputs>
                      <FirstLine>
                        <TextInput
                          control={control}
                          label="Nome"
                          responsive
                          name="full_name"
                          disabled
                          defaultValue={userData.full_name}
                        />
                        <TextInput
                          control={control}
                          label="Faculdade"
                          responsive
                          disabled
                          defaultValue={userData.university}
                          name="university"
                        />
                      </FirstLine>
                      <FirstLine>
                        <TextInput
                          control={control}
                          label="Curso"
                          responsive
                          disabled
                          name="course"
                          defaultValue={userData.course}
                        />
                        <TextInput
                          control={control}
                          label="Situação"
                          name="situation"
                          disabled
                          responsive
                          defaultValue={userData.situation}
                        />
                      </FirstLine>
                    </Inputs>
                  )}
                </div>
              </FormDiv>
            </Content>
          </Container>
        </>
      )}
    </div>
  )
}

export default ViewProfileWeb
