import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'

import backgroundImg from '../../../assets/LoginImage.png'
import logoImg from '../../../assets/logo.png'
import Button from '../../../components/Button'
import Input from '../../../components/Input'
import { useAuth } from '../../../contexts/AuthContext'
import {
  Background,
  Container,
  ContainerButton,
  ContainerFirstLink,
  ContainerFooter,
  Content,
  Form,
  Image,
  LinkContainer,
  ParagraphLink
} from './styles.js'

const LoginPageWeb = () => {
  const history = useHistory()
  const { state } = useLocation()
  const { signed, loginUser } = useAuth()

  const [password, setPassword] = useState('')
  const [user, setUser] = useState('')

  useEffect(() => {
    if (signed) {
      history.replace('/')
    }
  }, [])

  const handleLogin = (e) => {
    e.preventDefault()
    loginUser({ username_or_email: user, password: password }, () => {
      if (!!state && state.fromAuthedRoute)
        history.replace(state.prevPathname)
      else history.replace('/')
    })
  }

  return (
    <>
      <Container>
        <Content>
          <Form>
            <Link to="/">
              <Image src={logoImg} alt="Logo" />
            </Link>
            <Input
              responsive
              label="Email ou Usuário"
              type="text"
              onChange={(e) => setUser(e.target.value)}
            />
            <Input
              responsive
              label="Senha"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <ContainerButton>
              <Button responsive type="submit" onClick={handleLogin}>
                Entrar
              </Button>
            </ContainerButton>
            <LinkContainer>
              <ParagraphLink>
                Ainda não tem uma conta? <a href="/type">Cadastre-se</a>
              </ParagraphLink>
              <ParagraphLink>
                Esqueceu sua senha? <a href="/forgot">Clique aqui</a>
              </ParagraphLink>
            </LinkContainer>
          </Form>
          <Background src={backgroundImg} alt="Background" />
        </Content>
      </Container>
      <ContainerFooter>
        <ContainerFirstLink>
          <Link to="/about">Saiba mais sobre o Muron</Link>
        </ContainerFirstLink>
        <Link to="/contact">Fale Conosco</Link>
      </ContainerFooter>
    </>
  )
}

export default LoginPageWeb
