import { FaTrash } from 'react-icons/fa'
import styled from 'styled-components'

export const CommentContainer = styled.div`
  margin-bottom: 1.5rem;
`

export const UserContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Comment = styled.div`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`

export const ReplyButton = styled.button`
  border: none;
  outline: none;
  color: var(--azul-escuro);
  font-weight: bold;
  font-size: 1.1rem;
  background-color: transparent;
  margin: 0.5rem 0 1rem 0;
  cursor: pointer;
`

export const ReplyBoxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 1.5rem;
`

export const TrashIcon = styled(FaTrash)`
  color: var(--azul-escuro);
  cursor: pointer;
`
