import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, Link } from 'react-router-dom'

import Img from '../../../assets/Cellphonegirl.png'
import logoImg from '../../../assets/logo.png'
import Button from '../../../components/Button'
import TextInput from '../../../components/TextInput'
import { useToast } from '../../../hooks/useToast'
import api from '../../../services/api.js'
import * as Styled from './styles.js'

const DisseminatorSignupWeb = () => {
  const history = useHistory()
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'all' })
  const [page, setPage] = useState(0)
  const { addToast } = useToast()
  const returnType = () => {
    history.push('type')
  }

  const renderButton = () => {
    if (page < 2) {
      return (
        <Button
          type="button"
          onClick={() => {
            setPage(page + 1)
          }}
        >
          Próximo
        </Button>
      )
    } else if (page === 2) {
      return (
        <Button type="button" onClick={handleSubmit(submitForm)}>
          Cadastrar
        </Button>
      )
    }
  }

  const submitForm = async (values) => {
    await api
      .post('/users', {
        username: values.userName,
        full_name: values.fullName,
        email: values.EmailDivulgador,
        password: values.password,
        password_confirmation: values.passwordConfirmation,
        number: values.Telefone,
        partner: values.NomeDivulgador,
        site: values.site,
        is_disseminator: true,
        description: values.description
      })
      .then((res) => {
        if (res.status === 201) {
          addToast({
            type: 'sucess',
            title: 'Cadastro feito com sucesso',
            description: 'Bem-vinda(o) ao MurOn'
          })
          history.push('/email-confirmation/' + res.data.id, {
            fromRegisterPage: true
          })
        }
      })
      .catch(() =>
        addToast({
          type: 'error',
          title: 'Não foi possível efetuar o cadastro',
          description: 'Tente novamente.'
        })
      )
  }

  return (
    <Styled.Layout>
      <Styled.Container>
        <Styled.FormContainer>
          <Link to="/">
            <Styled.ImageLogo src={logoImg} />
          </Link>
          <form>
            {page === 0 && (
              <section>
                <TextInput
                  name="NomeDivulgador"
                  label="Nome Divulgador/Empresa"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Este campo não pode ficar em branco'
                    }
                  }}
                  error={errors.NomeDivulgador}
                  helperText={errors.NomeDivulgador?.message}
                />
                <TextInput
                  name="fullName"
                  label="Nome do Responsável"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Este campo não pode ficar em branco'
                    }
                  }}
                  error={errors.fullName}
                  helperText={errors.fullName?.message}
                />
                <TextInput
                  name="EmailDivulgador"
                  label="E-mail do Responsável"
                  control={control}
                  type="email"
                  rules={{
                    required: {
                      value: true,
                      message: 'Este campo não pode ficar em branco'
                    },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Endereço de email inválido'
                    }
                  }}
                  error={errors.EmailDivulgador}
                  helperText={errors.EmailDivulgador?.message}
                />
                <TextInput
                  name="Telefone"
                  label="Telefone"
                  placeholder="(61)99999-9999"
                  type="number"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Este campo não pode ficar em branco'
                    },
                    pattern: {
                      value: /[0-9]/,
                      message: 'Este campo só aceita números'
                    }
                  }}
                  error={errors.Telefone}
                  helperText={errors.Telefone?.message}
                />
                <TextInput
                  name="site"
                  label="Site"
                  control={control}
                  error={errors.site}
                  helperText={errors.site?.message}
                />
              </section>
            )}
            {page === 1 && (
              <section>
                <Styled.Title>
                  Agora fale um pouco sobre
                  <br />
                  as atividades da sua
                  <br />
                  empresa ou instituição:
                </Styled.Title>
                <TextInput
                  name="description"
                  multiline
                  rows={5}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Este campo não pode ficar em branco'
                    }
                  }}
                  error={errors.description}
                  helperText={errors.description?.message}
                />
              </section>
            )}
            {page === 2 && (
              <section>
                <Styled.Title>
                  Quase lá! Agora, insira
                  <br />
                  seus dados de Login:
                </Styled.Title>
                <TextInput
                  label="Nome de Usuário/MurEr"
                  name="userName"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Este campo não pode ficar em branco'
                    },
                    validate: (value) =>
                      value.search(/muron/i) === -1 &&
                      value.search(/admin/i) === -1 &&
                      value.search(/adm/i) === -1
                  }}
                  error={errors.userName}
                  helperText={
                    errors.userName?.message ||
                    (errors.userName?.type === 'validate' &&
                      'O nome de usuário não pode conter "muron","admin" ou "adm"')
                  }
                />
                <TextInput
                  name="password"
                  type="password"
                  label="Senha"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Este campo não pode ficar em branco'
                    },
                    minLength: {
                      value: 8,
                      message:
                        'A senha deve conter no mínimo 8 caracteres'
                    }
                  }}
                  error={errors.password}
                  helperText={errors.password?.message}
                />
                <TextInput
                  name="passwordConfirmation"
                  type="password"
                  label="Confirme sua Senha"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Este campo não pode ficar em branco'
                    },
                    minLength: {
                      value: 8,
                      message:
                        'A senha deve conter no mínimo 8 caracteres'
                    }
                  }}
                  error={errors.passwordConfirmation}
                  helperText={errors.passwordConfirmation?.message}
                />
              </section>
            )}
            <Styled.ButtonDiv>
              <Button
                ghost
                type="button"
                onClick={() => {
                  if (page === 0) {
                    returnType()
                  } else {
                    setPage(page - 1)
                  }
                }}
              >
                Voltar
              </Button>
              {renderButton()}
            </Styled.ButtonDiv>
          </form>
        </Styled.FormContainer>
        <Styled.Illustration src={Img} />
      </Styled.Container>
    </Styled.Layout>
  )
}

export default DisseminatorSignupWeb
