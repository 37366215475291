import React from 'react'
import {
  Route,
  Switch,
  BrowserRouter,
  Redirect,
  useLocation
} from 'react-router-dom'

import { useAuth } from './contexts/AuthContext'
import AboutUs from './pages/AboutUs'
import BackOfficeAdm from './pages/BackOfficeAdm'
import BackOfficeApproved from './pages/BackOfficeApproved'
import BackOfficePending from './pages/BackOfficePending'
import BackOfficeUsers from './pages/BackOfficeUsers'
import ConfirmEmail from './pages/ConfirmEmail'
import ContactUs from './pages/ContactUs'
import CreatePost from './pages/CreatePost'
import DisseminatorSignup from './pages/DisseminatorSignup'
import Home from './pages/Home'
import Login from './pages/LoginPage'
import PostPage from './pages/PostPage'
import Profile from './pages/Profile'
import RecoverPassword1 from './pages/RecoverPassword1'
import RecoverPassword2 from './pages/RecoverPassword2'
import Register from './pages/RegisterSignUp'
import Type from './pages/RegisterType'
import ResendEmailConfirmation from './pages/ResendEmailConfirmation'
import ViewProfile from './pages/ViewProfile'

const PrivateRoute = ({ component: Component, signed, ...rest }) => {
  const currLocation = useLocation()
  const nextLocation = {
    pathname: '/login',
    state: {
      fromAuthedRoute: true,
      prevPathname: currLocation.pathname
    }
  }

  return (
    <Route
      {...rest}
      render={() =>
        signed ? <Component /> : <Redirect to={nextLocation} />
      }
    />
  )
}

const AdminRoute = ({
  component: Component,
  signed,
  admin,
  ...rest
}) => {
  const currLocation = useLocation()
  const nextLocation = {
    pathname: '/login',
    state: {
      fromAuthedRoute: true,
      prevPathname: currLocation.pathname
    }
  }

  return (
    <Route
      {...rest}
      render={() =>
        signed ? (
          admin ? (
            <Component />
          ) : (
            <Redirect to="/" />
          )
        ) : (
          <Redirect to={nextLocation} />
        )
      }
    />
  )
}

const Routes = () => {
  const { signed, user } = useAuth()

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/about" component={AboutUs} exact />
        <Route
          path="/verify/:id/:token"
          component={ConfirmEmail}
          exact
        />
        <Route
          path="/email-confirmation/:id"
          component={ResendEmailConfirmation}
          exact
        />
        <Route path="/contact" component={ContactUs} exact />
        <Route path="/login" component={Login} exact />
        <Route path="/register" component={Register} exact />
        <Route path="/type" component={Type} exact />
        <Route
          path="/disseminator"
          component={DisseminatorSignup}
          exact
        />
        <Route path="/forgot" component={RecoverPassword1} exact />
        <Route
          path="/reset/:token"
          component={RecoverPassword2}
          exact
        />
        <PrivateRoute
          signed={signed}
          path="/post/new"
          component={CreatePost}
          exact
        />
        <Route path="/post/:id" component={PostPage} exact />
        <Route path="/profile/:id" component={ViewProfile} exact />
        <PrivateRoute
          signed={signed}
          path="/profile"
          component={Profile}
          exact
        />
        <AdminRoute
          signed={signed}
          admin={signed && user.is_admin}
          path="/backoffice/users"
          component={BackOfficeUsers}
          exact
        />
        <AdminRoute
          signed={signed}
          admin={signed && user.is_admin}
          path="/backoffice/pending"
          component={BackOfficePending}
          exact
        />
        <AdminRoute
          signed={signed}
          admin={signed && user.is_admin}
          path="/backoffice/approved"
          component={BackOfficeApproved}
          exact
        />
        <AdminRoute
          signed={signed}
          admin={signed && user.is_admin}
          path="/backoffice"
          component={BackOfficeAdm}
        />
        {/* TODO: NoMatchingRoute page component */}
        <Route path="/no-match" />
        <Route path="/" component={Home} exact />
        <Route path="/:tagId" component={Home} exact />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
