import InstallationFirefox1 from '../../../../assets/installation_firefox1.jpg'
import InstallationFirefox2 from '../../../../assets/installation_firefox2.jpg'
import InstallationFirefox3 from '../../../../assets/installation_firefox3.jpg'
import {
  Image,
  ImageInstallContainer,
  InstallContainer,
  Text,
  Title
} from '../styles.js'

const Firefox = () => {
  return (
    <InstallContainer>
      <Title>Adicione o MurOn ao seu dispositivo</Title>
      <Text>
        É possível instalar o MurOn no seu celular, assim fica mais
        fácil para você acessar o site e se manter atualizado.
        Infelizmente não é possível instalar o MurOn no seu computador,
        mas você pode acessar o site pelo navegador.
      </Text>
      <Text>
        Para instalar em seu celular, clique nos três pontinhos no canto
        inferior direito, depois em <i>Adicionar à tela</i> e por último
        em <i>Adicionar</i>.
      </Text>
      <br></br>
      <ImageInstallContainer>
        <Image
          src={InstallationFirefox1}
          alt="Imagem de instrução para adicionar atalho no Android."
          width="20%"
        />
        <Image
          src={InstallationFirefox2}
          alt="Imagem de instrução para adicionar atalho no Android."
          width="20%"
        />
        <Image
          src={InstallationFirefox3}
          alt="Imagem de instrução para adicionar atalho no Android."
          width="20%"
        />
      </ImageInstallContainer>
      <br></br>
    </InstallContainer>
  )
}

export default Firefox
