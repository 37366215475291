import React from 'react'

import { useMobile } from '../../utils/useMobile'
import RecoverPassword2Mobile from './Mobile'
import RecoverPassword2Web from './Web'

const RecoverPassword2 = () => {
  if (useMobile()) return <RecoverPassword2Mobile />
  else return <RecoverPassword2Web />
}

export default RecoverPassword2
