import styled from 'styled-components'

export const PageContainer = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 2rem;
`
export const ImageSide = styled.div`
  width: 45%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 3rem;
`

export const GoBack = styled.div`
  color: var(--azul-escuro);
  padding-top: 3rem;
  margin: 0 auto;
  margin-bottom: 3rem;
  width: 75%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`

export const TextSide = styled.div`
  width: 50%;
  height: 100%;
  padding-top: 3%;
  text-align: justify;
`
export const Image = styled.img`
  max-width: 600px;
  width: 30%;
  max-height: 600px;
  height: 60%;
  object-fit: contain;
  box-shadow: 0 0 0.8em gray;
  border-radius: 5px;
  position: fixed;
`
export const UserImage = styled.img`
  border-radius: 50%;
  width: 60px;
  height: 60px;
  object-fit: contain;
  margin-right: 1rem;
  border-color: gray;
  border-style: solid;
  border-width: 1px;
`

export const UserDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`
export const UserContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const Line = styled.div`
  width: 1px;
  background-color: var(--cinza-escuro);
  height: 75%;
  margin-top: 5rem;
  margin-left: 41.5%;
  position: fixed;
`
export const Title = styled.h1`
  color: var(--azul-escuro);
  margin-bottom: 1rem;
`

export const Date = styled.p`
  color: var(--cinza-escuro);
  margin-bottom: 1rem;
`

export const UserName = styled.p`
  color: var(--cinza-escuro);
`

export const CommentBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0 3rem 0;
  gap: 0.75rem;

  p {
    font-size: 1.1rem;
  }
`

export const CommentsContainer = styled.div`
  padding-top: 2rem;
  gap: 0.5rem;
`

export const CommentTitle = styled.div`
  svg {
    color: var(--cinza-medio);
    font-size: 1.8rem;
  }

  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--cinza-escuro);
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
  gap: 0.5rem;
`

export const Icons = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`
export const LinkComponent = styled.button`
  border-style: none;
  background-color: white;
  cursor: pointer;
`

export const Content = styled.div`
  text-align: justify;
  margin: 1.2rem 0 2.2rem 0;
`

export const NoComments = styled.p`
  color: var(--cinza-escuro);
  text-align: center;
  font-size: 1.2rem;
`
