import styled from 'styled-components'

export const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  padding-top: ${(props) => (props.mobile ? '0' : '2rem')};
  padding-bottom: ${(props) => (props.mobile ? '0' : '6rem')};
`

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
`

export const Title = styled.h1`
  text-align: center;
  word-break: break-word;
  font-size: ${(props) => (props.mobile ? '1.5rem' : '2rem')};
  color: var(--azul-escuro);
  margin-top: 2.5rem;
`

export const Text = styled.h2`
  text-align: center;
  word-break: break-word;
  font-size: ${(props) => (props.mobile ? '1rem' : '1.3rem')};
  font-weight: 400;
  color: var(--cinza-escuro);
  margin-top: 1rem;
`

export const CardsContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  margin-top: 1rem;
`
