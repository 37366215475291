import styled from 'styled-components'

const getSize = (size) => {
  return size === 'lg' ? '120px' : '45px'
}

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  height: ${(props) => getSize(props.size)};
  border-radius: 50%;
  background-color: var(--azul-escuro);
  object-fit: cover;
  filter: drop-shadow(0 2px 3px var(--cinza-medio));
  overflow: hidden;
`

export const Avatar = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`
