import styled from 'styled-components'

export const Button = styled.button`
  box-sizing: border-box;
  border: 0;
  width: ${(props) =>
    props.responsive === true ? '100%' : 'fit-content'};
  padding: 0 1.2rem;
  height: 3rem;
  background-color: ${(props) =>
    props.red === true ? 'red' : 'var(--azul-escuro)'};
  border-radius: 5px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  color: white;
  transition: all 200ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  &:hover {
    filter: brightness(0.9);
  }

  &:disabled {
    filter: brightness(0.8) opacity(0.9);
  }

  @media (max-width: 820px) {
    font-size: 16px;
  }
`

export const GhostButton = styled(Button)`
  border: 1px solid var(--azul-escuro);
  background-color: white;
  color: var(--azul-escuro);

  &:hover {
    background-color: var(--azul-escuro);
    filter: brightness(1);
    color: white;
  }
`
