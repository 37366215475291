import React from 'react'
import { useHistory } from 'react-router-dom'

import AvatarThumbnail from '../AvatarThumbnail'
import * as styled from './styles'

const UserInfo = ({ user }) => {
  const history = useHistory()

  return (
    <styled.UserContainer
      onClick={() => {
        history.push(`/profile/${user.id}`)
      }}
    >
      <AvatarThumbnail
        src={user.photo}
        alt={`Avatar de ${user.username}`}
      />
      <styled.UserName>{user.username}</styled.UserName>
    </styled.UserContainer>
  )
}

export default UserInfo
