import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: white;
`

export const Dropdown = styled.button`
  font-size: 20px;
  border-style: none;
  background-color: white;
  color: var(--cinza-escuro);
  cursor: pointer;
`
export const DropButton = styled.button`
  font-size: 20px;
  width: 9rem;
  border-style: none;
  background-color: white;
  color: var(--cinza-escuro);
  cursor: pointer;
  margin: 0.8rem;
`
export const LogOutButton = styled.button`
  font-size: 20px;
  border-style: none;
  background-color: white;
  color: red;
  cursor: pointer;
  margin-bottom: 0.4rem;
`
export const Dropdiv = styled.div`
  position: fixed;
  margin-top: 1.8rem;
  top: 1.8rem;
  display: flex;
  border-radius: 5px;
  background-color: white;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 3.5rem;
  border: 1px solid var(--cinza-escuro);
  box-shadow: inset;
  width: auto;
  height: auto;
`
export const Alldrop = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Image = styled.img`
  width: 160px;
  height: 44px;
  margin-left: 1.2rem;
`
export const Pages = styled.div`
  max-width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
  margin-right: 1.2rem;
`
export const Page = styled(NavLink)`
  font-size: 20px;
  text-decoration: none;
  color: var(--cinza-escuro);

  &:hover {
    color: var(--azul-escuro);
    opacity: 80%;
    transition: 0.3s;
  }
  @media (max-width: 820px) {
    font-size: 16px;
  }
`
export const ButtonStyle = styled.button`
  border: 1px solid var(--azul-escuro);
  padding: 0 1rem;
  height: 2.8rem;
  background-color: white;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  color: var(--azul-escuro);
  :hover {
    background-color: var(--azul-escuro);
    color: white;
    transition: 0.2s;
  }

  @media (max-width: 820px) {
    font-size: 16px;
  }
`
export const Line = styled.div`
  width: 93vw;
  border: 1px solid var(--cinza-medio);
`

export const Container = styled.div`
  min-width: 100%;
  margin: 0.8rem 0 0.55rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Navigation = styled.div`
  width: 93vw;
  height: 2rem;
  display: flex;
  justify-content: space-between;
  padding-top: 1.5rem;
`

export const NavInput = styled.input`
  border-style: none;
  width: 14rem;
  outline: none;
  ::placeholder {
    color: --var(--cinza-claro);
    font-size: 1rem;
  }
`
export const FilterLink = styled(NavLink)`
  font-size: 18px;
  text-decoration: none;
  color: #6b6b6b;

  &:hover {
    color: var(--azul-escuro);
    opacity: 80%;
    transition: 0.3s;
  }
`
export const Filter = styled.div`
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  width: 30rem;
`
export const InputDiv = styled.div`
  display: flex;
  width: 15rem;
  align-items: center;
  gap: 1rem;
`

export const BackDiv = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: transparent;
`
export const AdminButton = styled.button`
  font-size: 20px;
  border-style: none;
  margin-bottom: 1rem;
  background-color: white;
  color: var(--cinza-escuro);
  cursor: pointer;
`
