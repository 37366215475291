import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
`
export const RegisterDiv = styled.div`
  height: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
`

export const ContainerButton = styled.div`
  width: 85%;
  button:last-child {
    margin-top: 1rem;
  }
`

export const Image = styled.img`
  width: 10rem;
  height: 3rem;
  object-fit: contain;
`
export const Title = styled.p`
  margin: 0;
  text-align: center;
  font-size: 21px;
`
export const LinkDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const LinkButton = styled(Link)`
  color: #3370e5;
  text-decoration: none;
`

export const FooterLinkDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`
export const FooterLink = styled(Link)`
  font-size: 13px;
  color: var(--cinza-escuro);
`
export const Bar = styled.div`
  width: 0rem;
  height: 1.5rem;
  border: 0.2px solid grey;
`
