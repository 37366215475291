import React from 'react'

import Header from '../../../components/Header/Web'
import SearchInput from '../../../components/SearchInput'
import Users from '../../../components/Users/Web'
import * as Styled from './styles.js'

const BackOfficeUsersWeb = ({ state, methods, onDelete }) => {
  return (
    <>
      <Header />
      <Styled.Container>
        <Styled.Content>
          <Styled.Title>Usuários:</Styled.Title>
          <SearchInput
            placeholder={'Pesquise por usuários'}
            onSearch={(query) =>
              methods.fetchUsers(
                'q[username_or_course_or_university_or_full_name_cont]=' +
                  query
              )
            }
          />
          <Styled.ContainerUsers>
            {state.users.length > 0 ? (
              state.users.map((user) => (
                <Users
                  key={user.id}
                  user={user}
                  id={user.id}
                  onDelete={onDelete}
                />
              ))
            ) : (
              <Styled.Text>Não há usuários pendentes.</Styled.Text>
            )}
          </Styled.ContainerUsers>
        </Styled.Content>
      </Styled.Container>
    </>
  )
}

export default BackOfficeUsersWeb
