import styled from 'styled-components'

export const ComponentContainer = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
  max-width: ${(props) => (props.responsive ? 'none' : '300px')};
  border: none;
  border-bottom: 2px solid var(--cinza-medio);
  padding-right: 0.5rem;
  transition: all 300ms ease;

  &:focus-within {
    border-color: var(--cinza-medio-escuro);

    svg {
      color: var(--cinza-medio-escuro);
    }
  }
  svg {
    color: var(--cinza-medio);
    transition: all 300ms ease;
  }
`

export const Input = styled.input`
  box-sizing: border-box;
  height: 2.5rem;
  font-size: 18px;
  padding: 0 0.5rem;
  border: none;

  &:focus {
    outline: none;
  }
  ::placeholder {
    color: var(--cinza-escuro);
    font-weight: 500;
  }
`
