import styled from 'styled-components'

export const Container = styled.div`
  height: 9.5rem;
  background-color: white;
  position: fixed;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
  width: 100%;
  left: 0;
  bottom: 5.6vh;
`

export const Button = styled.button`
  color: ${(props) => (props.primary ? 'white' : 'var(--azul-escuro)')};
  background-color: ${(props) =>
    props.primary ? 'var(--azul-escuro)' : 'white'};
  padding: 0.5rem;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  display: block;
  min-width: 8rem;
  align-self: flex-end;
`

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`
