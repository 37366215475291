import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Anchor = styled(Link)`
  text-decoration: none;
  transition: all 0.2s ease;
  color: #3370e5;

  &:hover {
    filter: brightness(0.9);
  }
`
