import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Header = styled.div`
  width: 100%;
  height: 5.6vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid black;
  position: fixed;
  background-color: white;
  z-index: 4;

  .hamburguer {
    margin-left: 0.8rem;
  }

  .search {
    margin-right: 0.8rem;
  }
`

export const Image = styled.img`
  width: 6.7rem;
  height: 2rem;
`

export const SideDivLoged = styled.div`
  height: 100vh;
  width: 60vw;
  background-color: white;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 20px;
  align-items: left;
  border: 1px solid var(--azul-escuro);
  z-index: 5;
  animation: slidein 0.5s ease-in;
  cursor: default;

  @keyframes slidein {
    from {
      width: 0;
    }

    to {
      width: 60vw;
    }
  }
`
export const SideDivUnloged = styled.div`
  height: 100vh;
  width: 60vw;
  background-color: white;
  position: fixed;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  align-items: left;
  border: 1px solid var(--azul-escuro);
  z-index: 5;
  overflow: hidden;
  animation: slidein 0.5s ease-in;

  @keyframes slidein {
    from {
      width: 0;
    }

    to {
      width: 60vw;
    }
  }
`

export const FirstDiv = styled.div`
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  a {
    text-decoration: none;
    color: var(--azul-escuro);
  }
`
export const SecondDiv = styled.div`
  margin-top: 6rem;
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  p {
    color: var(--cinza-escuro);
  }
`
export const BackDiv = styled.div`
  height: 100vh;
  background-color: transparent;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 4;
`
export const ThirdDiv = styled.div`
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.6vh;
  border-top: 1px solid red;
  cursor: pointer;

  p {
    padding: 0px 10px;
    font-size: 20px;
    color: white;
  }
`
export const Name = styled.p`
  align-self: center;
`
export const User = styled.img`
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  object-fit: contain;
  border: 1px solid var(--cinza-escuro);
  align-self: center;
`
export const Search = styled.div`
  width: 100%;
  height: 5.6vh;
  position: absolute;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: slidein 0.5s ease-in-out;

  @keyframes slidein {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }
`
export const InputSearch = styled.input`
  background-color: white;
  width: 80%;
  height: 5.6vh;
  padding-left: 3px;
  border-style: none;
  z-index: 2;

  :focus {
    border: 0;
  }
  ::placeholder {
    color: var(--cinza-escuro);
    text-align: center;
  }
`

export const CloseSearch = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1;
`

export const SearchButton = styled.button`
  border-style: none;
  background-color: white;
`

export const FilterLink = styled(Link)`
  text-decoration: none;
  color: black;
`
export const FilterLink2 = styled(Link)`
  text-decoration: none;
  color: var(--azul-escuro);
`
