import styled from 'styled-components'

export const Container = styled.div`
  min-height: 80vh;
  width: 85vw;
  margin: 0 auto;
`
export const About = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

export const InstallContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2rem;
`

export const ImageInstallContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`

export const Image = styled.img`
  align-items: center;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);

  &.safari {
    border-radius: 40px;
  }
`

export const VideoDiv = styled.div`
  margin: 15rem 1.5rem 15rem 0;
  width: 50%;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%;
`

export const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  font-weight: 500;
  color: var(--azul-escuro);
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`

export const Text = styled.p`
  font-size: 1.25rem;
  margin-top: 0.2rem;
  line-height: 1.5;
  text-align: justify;
`

export const Types = styled.p`
  font-size: 1.25rem;
  margin-top: 0.2rem;
  line-height: 1.5;
  text-align: justify;
  padding-left: 2.5rem;
`
export const MurOn = styled.a`
  font-weight: bold;
  color: var(--azul-escuro);
`
