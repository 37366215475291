import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const UserName = styled.h1`
  color: grey;
  font-weight: 500;
  margin-bottom: 1.5rem;
  strong {
    color: var(--azul-escuro);
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  height: 100%;
  button:last-child {
    margin-left: 1.5rem;
  }
  align-items: flex-end;
`
