import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  place-content: center;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  place-content: center;
  gap: 0.5rem;
`

export const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: 500;
  color: var(--azul-escuro);
`

export const TitleDiv = styled.div`
  display: flex;
  align-items: center;
  justify-self: center;
`
export const Form = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-self: center;
  gap: 2rem;
`
export const Field = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
`

export const Label = styled.label`
  font-size: 18px;
`

export const Input = styled.input`
  width: 100%;
  height: 3rem;
  font-size: 1.125rem;
  border: 2px solid var(--cinza-medio);
  border-radius: 5px;
  padding-left: 0.5rem;
`

export const TextArea = styled.textarea`
  width: 100%;
  height: 20vh;
  font-size: 1.125rem;
  border: 2px solid var(--cinza-medio);
  border-radius: 5px;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  resize: none;
`
export const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
`
export const Button = styled.button`
  width: 10rem;
  height: 3rem;
  background-color: white;
  border-radius: 8px;
  color: #3370e5;
  border-color: #3370e5;
  border-width: 0.01rem;
  font-size: 18px;
  margin-bottom: 1.5rem;
  :hover {
    background-color: #3370e5;
    color: white;
  }
`
