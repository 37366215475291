import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  place-content: center;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  place-content: center;
`

export const Image = styled.img`
  width: 10rem;
  height: 3rem;
  object-fit: contain;
  margin-bottom: 2rem;
`

export const Label = styled.label`
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 0.25rem;
`

export const Input = styled.input`
  width: 20rem;
  height: 3rem;
  border: 2px solid var(--cinza-medio);
  border-radius: 0.313rem;
  font-size: 1rem;
  padding-left: 0.5rem;
  @media (max-width: 320px) {
    width: 17rem;
  }
`
export const PasswordEyeIcon = styled.img`
  cursor: pointer;
  margin-left: -2.5rem;
  margin-bottom: -0.75rem;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;
  gap: 1rem;
`

export const Button = styled.button`
  margin-top: 1.5rem;
  background-color: #3370e5;
  cursor: pointer;
  font-size: 1rem;
  color: white;
  width: 10rem;
  height: 3rem;
  border: none;
  border-radius: 0.313rem;
  transition: 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`

export const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
  width: 60%;
`

export const ParagraphLink = styled.p`
  margin-bottom: 2rem;
  text-align: center;

  a {
    text-decoration: none;
    transition: 0.2s;
    color: #3370e5;

    &:hover {
      filter: brightness(0.9);
    }
  }
`

export const ContainerFooter = styled.div`
  height: 10vh;
  width: 100%;
  display: flex;

  a {
    color: #6b6b6b;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }

  a:last-child {
    margin-left: 1rem;
    height: 1rem;
  }

  justify-content: center;
  align-items: center;
  place-content: center;
`

export const ContainerFirstLink = styled.div`
  padding-right: 1rem;
  border-right: 2px solid #6b6b6b;
  height: 1rem;
`

export const LinkContainer = styled.div`
  margin-top: 2rem;
`
