import DeleteIcon from '@mui/icons-material/Delete'
import PersonIcon from '@mui/icons-material/Person'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { useMobile } from '../../../utils/useMobile'
import ConfirmationModalMobile from '../../ConfirmationModal/Mobile'
import ConfirmationModalWeb from '../../ConfirmationModal/Web'
import * as Styled from './styles.js'

const UsersWeb = ({ user, onDelete }) => {
  const [modalConfirmation, setConfirmationModal] = useState(false)

  const handleOpenConfirmationModal = () => {
    setConfirmationModal(true)
  }

  const handleCloseConfirmationModal = () => {
    setConfirmationModal(false)
  }

  const mobile = useMobile()

  return (
    <>
      <Styled.Container>
        <Link to={{ pathname: `/profile/${user.id}` }}>
          <Styled.ImageContainer>
            {user.photo ? (
              <Styled.ImageProfile src={user.photo} />
            ) : (
              <PersonIcon
                style={{
                  color: 'white',
                  height: '5rem',
                  width: '5rem'
                }}
              />
            )}
          </Styled.ImageContainer>
        </Link>

        <Styled.Content>
          <Link
            to={{ pathname: `/profile/${user.id}` }}
            style={{ textDecoration: 'none' }}
          >
            <Styled.NameUser>
              MurEr:{' '}
              {user.username.length > 10
                ? user.username.substring(0, 10) + '...'
                : user.username}
            </Styled.NameUser>

            <Styled.UniversityUser>
              Faculdade: {user.university}
            </Styled.UniversityUser>

            <Styled.CourseUser>
              Curso:{' '}
              {user.course.length > 15
                ? user.course.substring(0, 15) + '...'
                : user.course}
            </Styled.CourseUser>

            <Styled.CourseUser>
              Situação: {user.situation}
            </Styled.CourseUser>
          </Link>
        </Styled.Content>

        <Styled.ButtonContainer onClick={handleOpenConfirmationModal}>
          <DeleteIcon
            style={{ color: 'white', width: '60%', height: '60%' }}
          />
        </Styled.ButtonContainer>
      </Styled.Container>
      {mobile ? (
        <ConfirmationModalMobile
          isOpen={modalConfirmation}
          name={user.username}
          id={user.id}
          handleClosedConfirmationModal={handleCloseConfirmationModal}
          onDelete={() => onDelete(user.id)}
        />
      ) : (
        <ConfirmationModalWeb
          isOpen={modalConfirmation}
          name={user.username}
          id={user.id}
          handleClosedConfirmationModal={handleCloseConfirmationModal}
          onDelete={() => onDelete(user.id)}
        />
      )}
    </>
  )
}

export default UsersWeb
