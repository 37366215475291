import React from 'react'

import { useMobile } from '../../utils/useMobile'
import ProfileMobile from './Mobile'
import ProfileWeb from './Web'

const Profile = () => {
  if (useMobile()) {
    return <ProfileMobile />
  } else {
    return <ProfileWeb />
  }
}

export default Profile
