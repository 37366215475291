import React from 'react'

import logo from '../../assets/logo.png'
import { LogoContainer, LogoImg } from './styles'

const Logo = (props) => {
  return (
    <LogoContainer {...props} to="/">
      <LogoImg src={logo} alt="Logo do MurOn" />
    </LogoContainer>
  )
}

export default Logo
