import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useAuth } from '../../contexts/AuthContext.jsx'
import { useToast } from '../../hooks/useToast.jsx'
import api from '../../services/api.js'
import { useMobile } from '../../utils/useMobile'
import BackOfficeUsersMobile from './Mobile'
import BackOfficeUsersWeb from './Web'

const BackOfficeUsers = () => {
  const [users, setUsers] = useState([])
  const { addToast } = useToast()
  const history = useHistory()
  const { user, logoutUser } = useAuth()

  const fetchUsers = (params = '') => {
    api
      .get(`users?q[is_disseminator_eq]=0&q[is_admin_eq]=0&`, {
        params: new URLSearchParams(params)
      })
      .then((res) => {
        setUsers(res.data)
      })
      .catch((err) => console.error(err))
  }

  const handleDeleteUser = (id) => {
    api
      .delete(`/users/${id}`)
      .then((res) => {
        if (res.status === 200) {
          addToast({
            type: 'sucess',
            title: 'Usuário deletado com sucesso',
            description:
              'O usuário foi deletado com sucesso do nosso banco de dados.'
          })
          if (!user.is_admin) {
            history.push('/')
            logoutUser()
          }
          setUsers((prevState) =>
            prevState.filter((user) => user.id !== id)
          )
        }
      })
      .catch((err) => {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro ao deletar o usuário',
          description: err.error
        })
      })
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  const state = {
    users: users
  }

  const methods = {
    fetchUsers: fetchUsers
  }

  if (useMobile()) {
    return (
      <BackOfficeUsersMobile
        state={state}
        methods={methods}
        onDelete={handleDeleteUser}
      />
    )
  } else {
    return (
      <BackOfficeUsersWeb
        state={state}
        methods={methods}
        onDelete={handleDeleteUser}
      />
    )
  }
}

export default BackOfficeUsers
