import React, { useEffect } from 'react'
import {
  FiAlertCircle,
  FiCheckCircle,
  FiInfo,
  FiXCircle
} from 'react-icons/fi'

import { useToast } from '../../../../hooks/useToast'
import * as Styled from './styles'

const icons = {
  info: <FiInfo />,
  error: <FiAlertCircle />,
  sucess: <FiCheckCircle />
}

const ToastComponentMobile = ({ message, style }) => {
  const { removeToast } = useToast()

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id)
    }, 6000)

    return () => {
      clearTimeout(timer)
    }
  }, [removeToast, message.id])

  return (
    <Styled.Container type={message.type} style={style}>
      {icons[message.type] || 'info'}
      <div>
        <strong>{message.title}</strong>
        <p>{message.description}</p>
      </div>

      <button type="button" onClick={() => removeToast(message.id)}>
        <FiXCircle size={16} />
      </button>
    </Styled.Container>
  )
}

export default ToastComponentMobile
