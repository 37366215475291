import InstallationSafari1 from '../../../../assets/installation_safari1.png'
import InstallationSafari2 from '../../../../assets/installation_safari2.png'
import InstallationSafari3 from '../../../../assets/installation_safari3.png'
import {
  Image,
  ImageInstallContainer,
  InstallContainer,
  Text,
  Title
} from '../styles.js'

const Safari = () => {
  return (
    <InstallContainer>
      <Title>Adicione o MurOn ao seu dispositivo</Title>
      <Text>
        Para instalar em seu celular, clique no botão de
        compartilhamento no canto inferior direito, depois em{' '}
        <i>Adicionar à tela inicial</i> e por último em <i>Adicionar</i>
        .
      </Text>
      <br></br>
      <ImageInstallContainer>
        <Image
          src={InstallationSafari1}
          className="safari"
          alt="Imagem de instrução para adicionar atalho no Android."
          width="70%"
        />
        <Image
          src={InstallationSafari2}
          className="safari"
          alt="Imagem de instrução para adicionar atalho no Android."
          width="70%"
        />
        <Image
          src={InstallationSafari3}
          className="safari"
          alt="Imagem de instrução para adicionar atalho no Android."
          width="70%"
        />
      </ImageInstallContainer>
      <br></br>
    </InstallContainer>
  )
}

export default Safari
