import styled from 'styled-components'

export const TextareaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
`

export const Label = styled.h1`
  font-size: 18px;
  font-weight: 400;
`

export const Textarea = styled.textarea`
  resize: none;
  width: 100%;
  box-sizing: border-box;
  height: ${(props) => (props.height ? props.height : '12rem')};
  font-size: 18px;
  border: 2px solid var(--cinza-medio);
  border-radius: 5px;
  padding: 0.5rem;

  &:focus {
    outline: none;
  }
`
