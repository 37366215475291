import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import Logo from '../../../assets/logo.png'
import Button from '../../../components/Button'
import Input from '../../../components/Input'
import { useToast } from '../../../hooks/useToast'
import api from '../../../services/api.js'
import {
  Container,
  Image,
  RegisterDiv,
  Field,
  ButtonDiv,
  Title,
  LinkDiv,
  LinkButton
} from './styles'

const RecoverPassword1WebMobile = () => {
  const [email, setEmail] = useState('')
  const { addToast } = useToast()

  const handleSubmit = () => {
    api
      .post('/login/forgot', { email: email })
      .then((res) => {
        addToast({
          type: 'info',
          title: 'Email enviado',
          description: 'Email enviado para sua caixa de entrada'
        })
      })
      .catch((_err) => {
        addToast({
          type: 'info',
          title: 'Email enviado',
          description: 'Email enviado para sua caixa de entrada'
        })
      })
  }

  return (
    <Container>
      <RegisterDiv>
        <Link to="/">
          <Image src={Logo}></Image>
        </Link>
        <Title>
          Esqueceu sua senha?
          <br />
          Informe seu email:
        </Title>
        <Field>
          <Input
            label="Email"
            type="text"
            onChange={(event) => setEmail(event.target.value)}
          />
        </Field>
        <ButtonDiv>
          <Button onClick={handleSubmit}>Recuperar senha</Button>
        </ButtonDiv>
        <LinkDiv>
          <p>Lembrou sua senha?</p>
          <LinkButton to="/login">Faça login</LinkButton>
        </LinkDiv>
      </RegisterDiv>
    </Container>
  )
}

export default RecoverPassword1WebMobile
