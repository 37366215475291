import React from 'react'
import { Link } from 'react-router-dom'

import logoImg from '../../../assets/logo.png'
import Button from '../../../components/Button'
import { history } from '../../../utils/history'
import {
  Container,
  RegisterDiv,
  Image,
  Title,
  LinkButton,
  ContainerButton,
  LinkDiv,
  FooterLinkDiv,
  FooterLink,
  Bar
} from './styles.js'

const RegisterTypeMobile = () => {
  const callRegister = () => {
    history.push('/register')
    window.location.reload()
  }
  const callDisseminator = () => {
    history.push('/disseminator')
    window.location.reload()
  }
  return (
    <Container>
      <RegisterDiv>
        <Link to="/">
          <Image src={logoImg} />
        </Link>
        <Title>Como deseja se cadastrar?</Title>
        <ContainerButton>
          <Button responsive ghost onClick={callRegister}>
            Usuário/MurEr
          </Button>
          <Button responsive ghost onClick={callDisseminator}>
            Divulgador
          </Button>
        </ContainerButton>
        <LinkDiv>
          <p>Lembrou sua senha?</p>
          <LinkButton to="/login">Faça login</LinkButton>
        </LinkDiv>
      </RegisterDiv>
      <FooterLinkDiv>
        <FooterLink to="/about">Saiba mais sobre o MurOn</FooterLink>
        <Bar></Bar>
        <FooterLink to="/contact">Fale conosco</FooterLink>
      </FooterLinkDiv>
    </Container>
  )
}

export default RegisterTypeMobile
