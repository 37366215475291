import PersonIcon from '@mui/icons-material/Person'
import React from 'react'

import * as styled from './styles'

const AvatarThumbnail = ({ src, alt, size }) => (
  <styled.AvatarWrapper size={size}>
    {src ? (
      <styled.Avatar src={src} alt="avatar" />
    ) : (
      <PersonIcon
        style={{
          fontSize: size === 'lg' ? '80px' : '30px',
          color: 'white'
        }}
      />
    )}
  </styled.AvatarWrapper>
)

export default AvatarThumbnail
