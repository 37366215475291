import styled from 'styled-components'

export const PageContainer = styled.div`
  min-height: 100vh;
  width: 100%;
`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 2.5rem;
`

export const AttachmentsContainer = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
`

export const PostDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  gap: 1.2rem;
  margin-bottom: 2rem;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4rem;
  width: 80%;
`
