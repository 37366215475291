import styled, { keyframes } from 'styled-components'

const slideDown = keyframes`
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0%);
  }
`

export const TransitionContainer = styled.div`
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  top: calc(${(props) => props.offset} - 0.5px);
  left: 0px;
  overflow: hidden;
  filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));
`

export const DropdownContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: white;
  border: 1px solid var(--cinza-medio);
  border-radius: 5px;
  animation: ${slideDown} 300ms ease;
  padding: 0.1rem;
  gap: 2px;
`
