import styled from 'styled-components'

export const Layout = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

export const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-right: 5vw;
`

export const ImageLogo = styled.img`
  width: 10rem;
  height: 3rem;
  object-fit: contain;
  margin-bottom: 40px;
`

export const Illustration = styled.img`
  max-height: 100%;
  width: 30%;
  object-fit: fill;
`

export const ButtonDiv = styled.div`
  width: 100%;
  button {
    width: 40%;
  }
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`

export const Terms = styled.p`
  font-size: 0.8rem;
  width: 22rem;
`

export const Button = styled.button`
  width: 8rem;
  height: 2.5rem;
  cursor: pointer;
  background-color: white;
  border-radius: 3px;
  color: #3370e5;
  border-color: #3370e5;
  border-width: 0.01rem;
  font-size: 18px;
  :hover {
    background-color: #3370e5;
    color: white;
  }
`

export const Title = styled.p`
  margin-bottom: 24px;
  text-align: center;
  font-size: 21px;
`

export const InputDiv = styled.div`
  width: 22rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1.1rem;
`
