import styled from 'styled-components'

export const Container = styled.div`
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 1rem;
  padding-bottom: 15rem;
`

export const InstallContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
`

export const ImageInstallContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  margin-top: 2rem;
`

export const Image = styled.img`
  align-items: center;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);

  &.safari {
    border-radius: 40px;
  }

  &:last-child {
    margin-bottom: 2rem;
  }
`

export const VideoDiv = styled.div`
  display: flex;
  width: 100%;
  margin: 3rem;
`

export const Title = styled.div`
  font-size: 1.6rem;
  color: var(--azul-escuro);
  font-weight: 500;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
`

export const Text = styled.p`
  margin-top: 1rem;
  line-height: 1.5;
`
export const Types = styled.p`
  margin-top: 1rem;
  line-height: 1.5;
  padding-left: 2.5rem;
`
export const MurOn = styled.a`
  font-weight: bold;
  color: var(--azul-escuro);
`
