import React from 'react'
import ReactDOM from 'react-dom'

import AuthContextProvider from './contexts/AuthContext'
import SearchProvider from './contexts/SearchContext'
import { ToastProvider } from './hooks/useToast'
import * as serviceWorkerRegistration from './registerServiceWorker.js'
import Routes from './routes'
import { GlobalStyle } from './styles/global.js'

import './index.css'

ReactDOM.render(
  <React.StrictMode>
    <ToastProvider>
      <AuthContextProvider>
        <SearchProvider>
          <Routes />
        </SearchProvider>
      </AuthContextProvider>
    </ToastProvider>
    <GlobalStyle />
  </React.StrictMode>,
  document.getElementById('root')
)

serviceWorkerRegistration.register()
