import styled from 'styled-components'

export const Div = styled.div`
  width: 100vw;
  height: 100vh;
`

export const RegisterDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 95%;
`

export const Logo = styled.img`
  width: 10rem;
  height: 3rem;
  object-fit: contain;
`

export const Paragraph = styled.p`
  font-size: 21px;
  text-align: center;
`

export const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 4rem;
  justify-content: space-between;
`

export const Label = styled.label`
  font-size: 18px;
`

export const Input = styled.input`
  height: 2.5rem;
  width: 99%;
  font-size: 18px;
  padding-left: 0.5rem;
  &:focus {
    border: 2px solid black;
    transition: border-color 0.3s ease-in-out;
    outline: 0;
  }
`

export const PasswordEyeIcon = styled.img`
  cursor: pointer;
  margin-left: -2.5rem;
  margin-bottom: -0.5rem;
`

export const ButtonDiv = styled.div`
  min-width: 12rem;
  max-width: 20rem;
  display: flex;
  justify-content: center;
`

export const Button = styled.button`
  min-width: 5rem;
  max-width: 10rem;
  min-height: 2.5rem;
  max-height: 4rem;
  width: 10rem;
  height: 3rem;
  background-color: var(--azul-escuro);
  border-radius: 3px;
  color: white;
  border: none;
  font-size: 18px;
  transition: 0.2s;
  :hover {
    filter: brightness(0.9);
  }
`
