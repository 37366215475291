import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import ClipLoader from 'react-spinners/ClipLoader'

import AskNotifications from '../../../components/AskNotifications'
import Header from '../../../components/Header'
import Posts from '../../../components/Posts/Mobile'
import { Container, Content } from './styles.js'

const HomeMobile = ({ state, methods }) => (
  <>
    <Header line onSearch={methods.setQuery} />
    <Container>
      {state.loading && state.page.current === 1 ? (
        <div className="loading-screen">
          <ClipLoader
            color={'#3370E5'}
            loading={state.loading}
            size={30}
          />
        </div>
      ) : (
        <>
          <Content>
            <InfiniteScroll
              dataLength={state.posts?.length}
              next={methods.fetchPosts}
              hasMore={state.hasMore}
              style={{ padding: '2rem', paddingTop: '0' }}
              loader={
                <p style={{ textAlign: 'center', margin: '1rem' }}>
                  <b>Carregando...</b>
                </p>
              }
              endMessage={
                state.posts?.length ? '' : 'Nenhum post encontrado'
              }
            >
              {state.posts?.map((post) => (
                <Posts post={post} key={post?.id} />
              ))}
            </InfiniteScroll>
          </Content>
          <AskNotifications />
        </>
      )}
    </Container>
  </>
)

export default HomeMobile
