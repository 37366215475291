import React, { useState } from 'react'

import Footer from '../../../components/Footer'
import Header from '../../../components/Header/Mobile'
import Input from '../../../components/Input'
import Textarea from '../../../components/Textarea'
import { useAuth } from '../../../contexts/AuthContext'
import { useToast } from '../../../hooks/useToast'
import api from '../../../services/api.js'
import { useMobile } from '../../../utils/useMobile'
import {
  Button,
  ButtonDiv,
  Container,
  Content,
  Field,
  Form,
  Title,
  TitleDiv
} from './styles.js'

const ContactMobile = () => {
  const { user } = useAuth()
  const [name, setName] = useState(user?.username)
  const [email, setEmail] = useState(user?.email)
  const [description, setDescription] = useState('')
  const { addToast } = useToast()

  const handleSubmit = () => {
    api
      .post('/contacts', {
        name: name,
        email: email,
        description: description
      })
      .then((res) => {
        if (res.status === 200) {
          setDescription('')
          addToast({
            type: 'sucess',
            title: 'Mensagem enviada',
            description: 'Sua mensagem foi enviada com sucesso'
          })
        } else {
          addToast({
            type: 'error',
            title: 'Mensagem não enviada',
            description: 'Falha em enviar sua mensagem'
          })
        }
      })
  }

  return (
    <>
      {useMobile() ? <Header Line={false} /> : <></>}
      <Container>
        <Content>
          <TitleDiv>
            <Title>Fale Conosco</Title>
          </TitleDiv>
          <Form>
            <Field>
              <Input
                label="Usuário/MurEr"
                responsive={true}
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                id="Nome"
              />
            </Field>
            <Field>
              <Input
                label="Email"
                responsive={true}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                id="Email"
              />
            </Field>
            <Field>
              <Textarea
                label="Mensagem"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                type="text"
                id="Mensagem"
              />
            </Field>
            <ButtonDiv>
              <Button onClick={handleSubmit}>Enviar</Button>
            </ButtonDiv>
          </Form>
        </Content>
      </Container>
      <Footer></Footer>
    </>
  )
}

export default ContactMobile
