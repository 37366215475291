import styled from 'styled-components'

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`

export const UserName = styled.div`
  color: var(--cinza-escuro);
  font-size: 1.05rem;
  font-weight: 500;
`
