import React from 'react'

import { useAuth } from '../../contexts/AuthContext'
import UserInfo from '../UserInfo'
import * as styled from './styles'

const Reply = ({ reply, onDelete }) => {
  const { user } = useAuth()

  const canDeleteReply = () => {
    return user?.id === reply?.user?.id
  }

  return (
    <>
      {reply && (
        <styled.ReplyContainer>
          <styled.UserContent>
            <UserInfo user={reply.user} />
            {canDeleteReply() && (
              <styled.TrashIcon size="20" onClick={onDelete} />
            )}
          </styled.UserContent>
          <styled.ReplyBody>{reply?.description}</styled.ReplyBody>
        </styled.ReplyContainer>
      )}
    </>
  )
}

export default Reply
