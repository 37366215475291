import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import Cellphone from '../../../assets/forgotpassword.png'
import Logo from '../../../assets/logo.png'
import Button from '../../../components/Button'
import Input from '../../../components/Input'
import { useToast } from '../../../hooks/useToast'
import api from '../../../services/api.js'
import { LinkDiv } from '../Mobile/styles'
import {
  Container,
  InputDiv,
  RegisterDiv,
  ButtonDiv,
  Field,
  Image,
  Image2,
  Content,
  Title,
  LinkButton
} from './styles'

const RecoverPassword1Web = () => {
  const [email, setEmail] = useState('')
  const { addToast } = useToast()

  const handleSubmit = () => {
    api
      .post('/login/forgot', { email: email })
      .then((res) => {
        addToast({
          type: 'info',
          title: 'Email enviado',
          description: 'Email enviado para sua caixa de entrada'
        })
      })
      .catch((_err) => {
        addToast({
          type: 'info',
          title: 'Email enviado',
          description: 'Email enviado para sua caixa de entrada'
        })
      })
  }

  return (
    <Container>
      <Content>
        <RegisterDiv>
          <Link to="/">
            <Image src={Logo}></Image>
          </Link>
          <Title>
            Esqueceu sua senha?
            <br />
            Informe seu email:
          </Title>
          <InputDiv>
            <Field>
              <Input
                label="Email"
                type="text"
                onChange={(event) => setEmail(event.target.value)}
              />
            </Field>
          </InputDiv>
          <ButtonDiv>
            <Button type="button" onClick={handleSubmit}>
              Recuperar senha
            </Button>
          </ButtonDiv>
          <LinkDiv>
            <p>Lembrou sua senha?</p>
            <LinkButton to="/login">Faça login</LinkButton>
          </LinkDiv>
        </RegisterDiv>
        <Image2 src={Cellphone} />
      </Content>
    </Container>
  )
}
export default RecoverPassword1Web
