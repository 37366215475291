import React from 'react'

import * as styled from './styles'

const Dropdown = ({
  children,
  offset,
  alignLeft // by default, the dropdown aligns to the right
}) => {
  return (
    <styled.TransitionContainer offset={offset} alignLeft>
      <styled.DropdownContainer>{children}</styled.DropdownContainer>
    </styled.TransitionContainer>
  )
}

export default Dropdown
