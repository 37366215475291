import React from 'react'

import { useMobile } from '../../utils/useMobile'
import LoginPageMobile from './Mobile'
import LoginPageWeb from './Web'

const RecoverPassword1 = () => {
  if (useMobile()) {
    return <LoginPageMobile />
  } else {
    return <LoginPageWeb />
  }
}

export default RecoverPassword1
