import React, {
  createContext,
  useContext,
  useCallback,
  useState
} from 'react'
import { uuid } from 'uuidv4'

import ToastMobile from '../components/Toast/Mobile'
import ToastWeb from '../components/Toast/Web'
import { useMobile } from '../utils/useMobile.js'

const ToastContext = createContext({})

const ToastProvider = ({ children }) => {
  const mobile = useMobile()
  const [messages, setMessages] = useState([])

  const addToast = useCallback(({ type, title, description }) => {
    const id = uuid()

    const toast = {
      id,
      type,
      title,
      description
    }

    setMessages((state) => [...state, toast])
  }, [])

  const removeToast = useCallback((id) => {
    setMessages((state) => state.filter((message) => message.id !== id))
  }, [])

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      {messages.length > 0 &&
        (mobile ? (
          <ToastMobile messages={messages} />
        ) : (
          <ToastWeb messages={messages} />
        ))}
    </ToastContext.Provider>
  )
}

function useToast() {
  const context = useContext(ToastContext)

  if (!context) {
    throw new Error('Não possui um ToastProvider')
  }

  return context
}

export { ToastProvider, useToast }
