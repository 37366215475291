import FileUploadIcon from '@mui/icons-material/FileUpload'
import { CircularProgress } from '@mui/material'
import React, { useRef } from 'react'

import Button from '../../../components/Button'
import Header from '../../../components/Header'
import Input from '../../../components/Input'
import Option from '../../../components/Option'
import PostPreviewer from '../../../components/PostPreviewer'
import Select from '../../../components/Select'
import Textarea from '../../../components/Textarea'
import * as styled from './styles'

const CreatePost = ({ state, methods }) => {
  const inputFileRef = useRef(null)

  const openFileBrowser = () => {
    inputFileRef.current.click()
  }

  const submitHandler = () => {
    methods.handleSubmit()
  }

  return (
    <styled.PageContainer>
      <Header />
      <styled.Container>
        <styled.AttachmentsContainer>
          <PostPreviewer
            files={state.postInfo.attachments}
            onDelete={methods.deleteEntry}
          />
          <Button
            onClick={openFileBrowser}
            disabled={state.isSubmitting}
          >
            <input
              ref={inputFileRef}
              type="file"
              multiple
              accept="image/*,video/*"
              onChange={methods.onFileChange}
              style={{ display: 'none' }}
            />
            <FileUploadIcon />
            Adicionar arquivos
          </Button>
        </styled.AttachmentsContainer>
        <styled.PostDataContainer>
          <styled.InputContainer>
            <Input
              id="title"
              label="Título da postagem:"
              value={state.postInfo.title}
              maxLength={70}
              onChange={(e) =>
                methods.setPostInfo((prevState) => ({
                  ...prevState,
                  title: e.target.value
                }))
              }
              disabled={state.isSubmitting}
            />
            <Textarea
              id="content"
              label="Conteúdo da postagem:"
              value={state.postInfo.content}
              onChange={(e) =>
                methods.setPostInfo((prevState) => ({
                  ...prevState,
                  content: e.target.value
                }))
              }
              disabled={state.isSubmitting}
            />
            <Select
              label="Escolha a tag:"
              value={!!state.postInfo.tag && state.postInfo.tag.name}
            >
              {state.tags.map((tag) => (
                <Option
                  key={tag.id}
                  onClick={() =>
                    methods.setPostInfo((prevState) => ({
                      ...prevState,
                      tag: tag
                    }))
                  }
                >
                  {tag.name}
                </Option>
              ))}
            </Select>
          </styled.InputContainer>
          <Button onClick={submitHandler} disabled={state.isSubmitting}>
            {state.isSubmitting ? (
              <>
                <CircularProgress color="inherit" size={20} />
                Aguarde
              </>
            ) : (
              <>Publicar</>
            )}
          </Button>
        </styled.PostDataContainer>
      </styled.Container>
    </styled.PageContainer>
  )
}

export default CreatePost
