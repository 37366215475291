import styled from 'styled-components'

export const Container = styled.div`
  width: 50%;
  height: 7rem;
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 25%;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
`

export const Button = styled.button`
  color: ${(props) => (props.primary ? 'white' : 'var(--azul-escuro)')};
  background-color: ${(props) =>
    props.primary ? 'var(--azul-escuro)' : 'white'};
  padding: 0.5rem;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  display: block;
  min-width: 8rem;
`

export const Text = styled.p``

export const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`
