/* eslint-disable no-undef */
import React from 'react'
import { FaTrash } from 'react-icons/fa'
import { RiQuestionAnswerFill } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'

import Button from '../../../components/Button'
import Comment from '../../../components/Comment'
import Footer from '../../../components/Footer'
import Header from '../../../components/Header'
import { ButtonStyle } from '../../../components/Header/Web/styles'
import PostImages from '../../../components/PostImages'
import Textarea from '../../../components/Textarea'
import UserInfo from '../../../components/UserInfo'
import { useAuth } from '../../../contexts/AuthContext'
import formatDate from '../../../utils/formatDate'
import * as styled from './styles'

const PostPageMobile = ({ methods, state }) => {
  const { signed, user } = useAuth()
  const history = useHistory()

  const canDeletePost = () =>
    user?.id === state.postInfo?.creator.id || user?.is_admin

  return (
    <>
      <Header />
      {state.postInfo && (
        <styled.PostContainer>
          <styled.Title>{state.postInfo.title}</styled.Title>
          <styled.Date>
            {formatDate(state.postInfo.created_at)}
          </styled.Date>
          <styled.UserInfoWrapper>
            <UserInfo user={state.postInfo.creator} />
          </styled.UserInfoWrapper>
          <styled.Wrapper>
            <PostImages files={state.postInfo.attachments} />
          </styled.Wrapper>
          <styled.Icons>
            {canDeletePost() && (
              <FaTrash
                size="25"
                color="#3370E5"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  methods.deletePost()
                }}
              />
            )}
            <a href="#comment">
              <RiQuestionAnswerFill
                size="30"
                color="#3370E5"
                padding="10px"
              ></RiQuestionAnswerFill>
            </a>
          </styled.Icons>
          <styled.Content>
            {state.postInfo.content.split('\n').map((text, index) => (
              <p key={index}>{text}</p>
            ))}
          </styled.Content>
          <styled.Container>
            <styled.CommentsContainer>
              <styled.CommentTitle>
                <RiQuestionAnswerFill />
                Comentários:
              </styled.CommentTitle>
              {signed ? (
                <styled.CommentBoxContainer id="comment">
                  <p>Faça um comentário:</p>
                  <Textarea
                    onChange={(e) => {
                      methods.setCommentText(e.target.value)
                    }}
                    value={state.commentText}
                    responsive
                    height="7rem"
                    disabled={!signed}
                  />
                  <Button
                    onClick={() => {
                      methods.setCommentText('')
                      methods.publishComment()
                    }}
                  >
                    Publicar
                  </Button>
                </styled.CommentBoxContainer>
              ) : (
                <styled.CommentBoxContainer id="comment">
                  <ButtonStyle
                    onClick={() => {
                      history.push('/login')
                    }}
                  >
                    Fazer login para comentar
                  </ButtonStyle>
                </styled.CommentBoxContainer>
              )}
              {state.comments && state.comments?.length > 0 ? (
                state.comments.map((comment) => (
                  <Comment
                    key={comment.id}
                    comment={comment}
                    setReplyText={methods.setReplyText}
                    onReply={methods.publishReply}
                    onDelete={() => methods.deleteComment(comment.id)}
                    onReplyDelete={methods.deleteReply}
                  />
                ))
              ) : (
                <styled.NoComments>
                  Ainda não há comentários nesta publicação
                </styled.NoComments>
              )}
            </styled.CommentsContainer>
          </styled.Container>
        </styled.PostContainer>
      )}
      <Footer />
    </>
  )
}

export default PostPageMobile
