import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  :root {
    --azul-escuro: #3370E5;
    --azul-claro: #84E1FA;
    --cinza-muito-claro: #F6F6F6;
    --cinza-medio: #C5C5C5;
    --cinza-medio-escuro: #8F8F8F;
    --cinza-escuro: #6B6B6B;
    --vermelho: #FF5454;
    --breakpoint: 720px;

    min-height: 100vh;
  }

  * {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;;
  }

  body {
    height: 100%;
    -webkit-font-smoothing: antialiased;
  }

  html {
    @media (max-width: 1280px) {
      font-size: 93.75%;
    }
    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }

  .modal-confirmation {
    background: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .confirmation-modal {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 30rem;
    background-color: white;
    padding: 3rem;
    min-height: 15rem;
    position: relative;
    border-radius: 0.5rem;
  }

  .modal-confirmation-mobile {
    background: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .confirmation-modal-mobile {
    display: flex;
    justify-content: center;
    width: 50%;
    background-color: white;
    padding: 3rem;
    height: auto;
    position: relative;
    border-radius: 0.5rem;
  }

  .loading-screen {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
