import Badge from '@mui/material/Badge'
import { React, useEffect, useState } from 'react'
import { FiSearch } from 'react-icons/fi'
import { Link, useHistory } from 'react-router-dom'

import Logo from '../../../assets/logo.png'
import { useAuth } from '../../../contexts/AuthContext'
import { useSearch } from '../../../contexts/SearchContext'
import api from '../../../services/api'
import {
  AdminButton,
  Alldrop,
  BackDiv,
  ButtonStyle,
  Container,
  DropButton,
  Dropdiv,
  Dropdown,
  Filter,
  FilterLink,
  Header,
  Image,
  InputDiv,
  Line,
  LogOutButton,
  Navigation,
  NavInput,
  Page,
  Pages
} from './styles'

const HeaderWeb = ({ line, onSearch }) => {
  const history = useHistory()
  const { signed, logoutUser, user } = useAuth()
  const [open, setOpen] = useState(false)

  const [newPost, setNewPost] = useState(false)
  const [postNumber, setPostNumber] = useState(0)

  const [query, setQuery] = useState('')

  const { callDebouncer } = useSearch()

  const reloadWindow = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const getUnreadPostsQuantity = () => {
    api
      .get('/check_unread_posts', {
        params: user?.id && { user_id: user.id }
      })
      .then((res) => res.data)
      .then((unreadPosts) => {
        if (unreadPosts) setNewPost(true)
        setPostNumber(unreadPosts)
      })
      .catch(console.log)
  }

  useEffect(() => {
    getUnreadPostsQuantity()
    const intervalId = setInterval(getUnreadPostsQuantity, 1000 * 30)
    return () => clearInterval(intervalId)
  }, [])

  const logOut = () => {
    setOpen(!open)
    logoutUser()
    history.push('/')
  }
  const handleClick = () => {
    setOpen(!open)
  }

  const profile = () => {
    setOpen(!open)
    history.push(`/profile`)
  }

  const admin = () => {
    setOpen(!open)
    history.push('/backoffice')
  }

  const handleChange = (e) => {
    setQuery(e.target.value)
    callDebouncer(() => onSearch(query))
  }

  const clearSearch = () => {
    setQuery('')
    if (onSearch) onSearch('')
  }

  if (signed) {
    return (
      <Header>
        <Container>
          <Link to="/" onClick={clearSearch}>
            <Image src={Logo}></Image>
          </Link>
          <Pages>
            <Page
              to="/contact"
              activeStyle={{
                fontWeight: 'bold',
                color: '#3370E5'
              }}
            >
              Fale conosco
            </Page>
            <Page
              to="/about"
              activeStyle={{
                fontWeight: 'bold',
                color: '#3370E5'
              }}
            >
              Sobre Nós
            </Page>
            <Page
              to="/post/new"
              activeStyle={{
                fontWeight: 'bold',
                color: '#3370E5'
              }}
            >
              Nova Publicação
            </Page>
            <Alldrop>
              <Dropdown onClick={handleClick}>Meu Perfil</Dropdown>
              {open && (
                <>
                  <BackDiv onClick={handleClick}></BackDiv>
                  <Dropdiv>
                    <DropButton onClick={profile}>
                      Editar Perfil
                    </DropButton>
                    {user.is_admin ? (
                      <AdminButton onClick={admin}>
                        Administração
                      </AdminButton>
                    ) : (
                      <></>
                    )}
                    <LogOutButton onClick={logOut}>Sair</LogOutButton>
                  </Dropdiv>
                </>
              )}
            </Alldrop>
          </Pages>
        </Container>
        {line ? (
          <Navigation>
            <Filter>
              <FilterLink
                exact
                to="/"
                activeStyle={{
                  fontWeight: 'bold',
                  color: '#3370E5',
                  borderBottom: '3px solid  #3370E5'
                }}
                onClick={() => {
                  clearSearch()
                  reloadWindow()
                }}
              >
                {newPost ? (
                  <Badge
                    color="primary"
                    badgeContent={postNumber}
                    max={20}
                  >
                    Mural
                  </Badge>
                ) : (
                  <p>Mural</p>
                )}
              </FilterLink>
              <FilterLink
                to="/1"
                onClick={clearSearch}
                activeStyle={{
                  fontWeight: 'bold',
                  color: '#3370E5',
                  borderBottom: '3px solid  #3370E5'
                }}
              >
                Divulgação
              </FilterLink>
              <FilterLink
                to="/2"
                onClick={clearSearch}
                activeStyle={{
                  fontWeight: 'bold',
                  color: '#3370E5',
                  borderBottom: '3px solid  #3370E5'
                }}
              >
                Estágio/Trabalho
              </FilterLink>
              <FilterLink
                exact
                to="/3"
                onClick={clearSearch}
                activeStyle={{
                  fontWeight: 'bold',
                  color: '#3370E5',
                  borderBottom: '3px solid  #3370E5'
                }}
              >
                Projetos
              </FilterLink>
            </Filter>
            <InputDiv>
              <FiSearch size="22px" color="#C5C5C5"></FiSearch>
              <NavInput
                placeholder="Faça aqui sua pesquisa"
                type="text"
                value={query}
                onChange={handleChange}
              ></NavInput>
            </InputDiv>
          </Navigation>
        ) : null}
        <Line />
      </Header>
    )
  } else {
    return (
      <>
        <Header>
          <Container>
            <Link to="/" onClick={clearSearch}>
              <Image src={Logo}></Image>
            </Link>
            <Pages>
              <Page
                exact
                to="/contact"
                activeStyle={{
                  fontWeight: 'bold',
                  color: '#3370E5'
                }}
              >
                Fale conosco
              </Page>
              <Page
                to="/about"
                activeStyle={{
                  fontWeight: 'bold',
                  color: '#3370E5'
                }}
              >
                Sobre Nós
              </Page>
              <ButtonStyle
                onClick={() => {
                  history.push('/login')
                }}
              >
                Faça Login
              </ButtonStyle>
              <ButtonStyle
                onClick={() => {
                  history.push('/type')
                }}
              >
                Cadastre-se
              </ButtonStyle>
            </Pages>
          </Container>
          {line ? (
            <Navigation>
              <Filter>
                <FilterLink
                  exact
                  to="/"
                  onClick={clearSearch}
                  activeStyle={{
                    fontWeight: 'bold',
                    color: '#3370E5',
                    borderBottom: '3px solid  #3370E5'
                  }}
                >
                  {newPost ? (
                    <Badge
                      color="primary"
                      badgeContent={postNumber}
                      max={20}
                    >
                      Mural
                    </Badge>
                  ) : (
                    <p>Mural</p>
                  )}
                </FilterLink>
                <FilterLink
                  to="/1"
                  onClick={clearSearch}
                  activeStyle={{
                    fontWeight: 'bold',
                    color: '#3370E5',
                    borderBottom: '3px solid  #3370E5'
                  }}
                >
                  Divulgação
                </FilterLink>
                <FilterLink
                  to="/2"
                  onClick={clearSearch}
                  activeStyle={{
                    fontWeight: 'bold',
                    color: '#3370E5',
                    borderBottom: '3px solid  #3370E5'
                  }}
                >
                  Estágio/Trabalho
                </FilterLink>
                <FilterLink
                  to="/3"
                  onClick={clearSearch}
                  activeStyle={{
                    fontWeight: 'bold',
                    color: '#3370E5',
                    borderBottom: '3px solid  #3370E5'
                  }}
                >
                  Projetos
                </FilterLink>
              </Filter>
              <InputDiv>
                <FiSearch size="22px" color="#C5C5C5"></FiSearch>
                <NavInput
                  placeholder="Faça aqui sua pesquisa"
                  type="text"
                  value={query}
                  onChange={handleChange}
                />
              </InputDiv>
            </Navigation>
          ) : null}
          <Line />
        </Header>
      </>
    )
  }
}

export default HeaderWeb
