import React from 'react'
import { useParams, useLocation, Redirect } from 'react-router-dom'

import { useToast } from '../../hooks/useToast'
import api from '../../services/api'
import { useMobile } from '../../utils/useMobile'
import ResendEmailMobile from './Mobile'
import ResendEmailWeb from './Web'

const ConfirmEmail = () => {
  const { addToast } = useToast()
  const { id } = useParams()
  const { state } = useLocation()

  const handleResend = () => {
    api
      .post('/users/' + id + '/resend-email')
      .then((res) => {
        if (res.status === 200) {
          addToast({
            type: 'sucess',
            title: 'Email reenviado com sucesso',
            description: 'Verifique o email utilizado para o cadastro.'
          })
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 404) {
            addToast({
              type: 'error',
              title: 'Não foi possível reenviar o email',
              description: 'Usuário não encontrado.'
            })
          }
          if (err.response.status === 403) {
            addToast({
              type: 'error',
              title: 'Não foi possível reenviar o email',
              description: 'Usuário já verificado.'
            })
          }
        } else {
          addToast({
            type: 'error',
            title: 'Erro conectar com o servidor',
            description: 'Ocorreu eu erro ao conectar com o servidor.'
          })
        }
      })
  }

  const methods = {
    handleResend: handleResend
  }

  return (
    <>
      {!!state && state.fromRegisterPage === true ? (
        <>
          {useMobile() ? (
            <ResendEmailMobile methods={methods} />
          ) : (
            <ResendEmailWeb methods={methods} />
          )}
        </>
      ) : (
        <Redirect to="/no-match" />
      )}
    </>
  )
}

export default ConfirmEmail
