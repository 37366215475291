import React from 'react'

import ToastComponentWeb from '../ToastComponent/Web'
import { Container } from './styles.js'

const ToastWeb = ({ messages }) => {
  /*
  const transition = useTransition(messages, {
    from: { left: '-120%', opacity: 0 },
    enter: { left: '0%', opacity: 1 },
    leave: { left: '-120%', opacity: 0 }
  })
    * */

  return (
    <Container>
      {messages.map((message) => (
        <ToastComponentWeb key={message.id} message={message} />
      ))}
    </Container>
  )
}

export default ToastWeb
