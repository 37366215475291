import imageCompression from 'browser-image-compression'
import FormData from 'form-data'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useToast } from '../../hooks/useToast'
import api from '../../services/api.js'
import { useMobile } from '../../utils/useMobile'
import { validatePost } from '../../utils/validatePost'
import CreatePostMobile from './Mobile'
import CreatePostWeb from './Web'

const CreatePost = () => {
  const defaults = {
    attachments: [],
    tag: undefined,
    title: '',
    content: ''
  }
  const [postInfo, setPostInfo] = useState(defaults)
  const [tags, setTags] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { addToast } = useToast()
  const history = useHistory()

  const fetchTags = async () => {
    try {
      const response = await api.get('/tags')

      if (response.status === 200) setTags(response.data)
    } catch (err) {
      if (err.response) {
        addToast({
          type: 'error',
          title: 'Erro ao buscar tags para postagens',
          description: 'Não foi possível buscar as tags no servidor'
        })
      } else {
        addToast({
          type: 'error',
          title: 'Erro ao conectar com o servidor',
          description: 'Não foi possível conectar com o servidor.'
        })
      }
    }
  }

  useEffect(() => {
    fetchTags()
  }, [])

  const onFileChange = (e) => {
    e.preventDefault()

    const files = Array.from(e.target.files)

    files.forEach((file) => {
      const reader = new window.FileReader()

      reader.onload = () => {
        setPostInfo((prevState) => ({
          ...prevState,
          attachments: [
            ...prevState.attachments,
            {
              file: file,
              previewUrl: reader.result,
              isVideo: file.type.includes('video')
            }
          ]
        }))
      }
      reader.readAsDataURL(file)
    })
  }

  const deleteEntry = (idx) => {
    setPostInfo((prevState) => ({
      ...prevState,
      attachments: prevState.attachments.filter((file, i) => i !== idx)
    }))
  }

  const handleSubmit = async () => {
    setIsSubmitting(true)
    const error = validatePost(postInfo)

    if (error) {
      addToast({
        type: 'error',
        title: error.title,
        description: error.description
      })
      setIsSubmitting(false)
      return
    }

    const formData = new FormData()
    formData.append('title', postInfo.title)
    formData.append('content', postInfo.content)
    formData.append('tag_id', postInfo.tag.id)
    const postAttachments = await Promise.all(
      postInfo.attachments.map(async (attachment) => {
        if (attachment.isVideo || attachment.file.size < 524288) {
          return attachment.file
        } else {
          try {
            const compressedFile = await imageCompression(
              attachment.file,
              {
                maxSizeMB: 0.5
              }
            )
            return compressedFile
          } catch (err) {
            return attachment.file
          }
        }
      })
    )
    postAttachments.forEach((attachment) => {
      formData.append('attachments[]', attachment)
    })

    try {
      await api.post('/posts', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      addToast({
        type: 'sucess',
        title: 'Publicação criada',
        description: 'A postagem foi criada com sucesso!'
      })
      setPostInfo(defaults)
      setIsSubmitting(false)
      history.push('/')
    } catch (err) {
      setIsSubmitting(false)
      if (err.response && err.response.status === 401) {
        addToast({
          type: 'info',
          title: 'Não foi possível criar publicação',
          description:
            'Sua conta ainda não foi autorizada a criar posts.'
        })
      } else {
        addToast({
          type: 'error',
          title: 'Não foi possível criar publicação'
        })
      }
    }
  }

  const state = {
    postInfo,
    tags,
    isSubmitting
  }

  const methods = {
    setPostInfo,
    handleSubmit,
    onFileChange,
    deleteEntry
  }

  if (useMobile()) {
    return <CreatePostMobile state={state} methods={methods} />
  } else {
    return <CreatePostWeb state={state} methods={methods} />
  }
}

export default CreatePost
