import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'

import logoImg from '../../../assets/logo.png'
import Button from '../../../components/Button'
import Input from '../../../components/Input'
import { useAuth } from '../../../contexts/AuthContext'
// import { useToast } from '../../../hooks/useToast'
import {
  Container,
  ContainerButton,
  ContainerFirstLink,
  ContainerFooter,
  Content,
  Form,
  Image,
  LinkContainer,
  ParagraphLink
} from './styles.js'

const LoginPageMobile = () => {
  const history = useHistory()
  const { state } = useLocation()
  const { signed, loginUser } = useAuth()

  const [password, setPassword] = useState('')
  const [user, setUser] = useState('')
  // const { addToast } = useToast()

  useEffect(() => {
    if (signed) {
      history.replace('/')
    }
  }, [])

  const handleLogin = (e) => {
    e.preventDefault()
    loginUser({ username_or_email: user, password: password }, () => {
      if (!!state && state.fromAuthedRoute)
        history.replace(state.prevPathname)
      else history.replace('/')
    })
  }

  return (
    <>
      <Container>
        <Content>
          <Link to="/">
            <Image src={logoImg} alt="Logo" />
          </Link>
          <Form>
            <Input
              label="Email ou Usuário"
              type="text"
              onChange={(e) => setUser(e.target.value)}
            />
            <Input
              label="Senha"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <ContainerButton>
              <Button responsive type="submit" onClick={handleLogin}>
                Entrar
              </Button>
            </ContainerButton>
          </Form>
          <LinkContainer>
            <ParagraphLink>
              Ainda não tem uma conta?
              <br />
              <Link to="/type">Cadastre-se</Link>
            </ParagraphLink>
            <ParagraphLink>
              Esqueceu sua senha? <br />
              <Link to="/forgot">Clique aqui</Link>
            </ParagraphLink>
          </LinkContainer>
        </Content>
      </Container>
      <ContainerFooter>
        <ContainerFirstLink>
          <Link to="/about">Saiba mais sobre o Muron</Link>
        </ContainerFirstLink>
        <Link to="/contact">Fale Conosco</Link>
      </ContainerFooter>
    </>
  )
}

export default LoginPageMobile
