import React from 'react'

import { useMobile } from '../../utils/useMobile'
import { H1 } from './styles'

const Title = ({ children, ...rest }) => {
  const mobile = useMobile()

  return (
    <H1 mobile={mobile} {...rest}>
      {children}
    </H1>
  )
}

export default Title
