import styled from 'styled-components'

export const Container = styled.div`
  width: 100vw;
  height: 80vh;
  display: flex;
  justify-content: center;
`

export const ImageDiv = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -10rem;
  gap: 1rem;
  input[type='file'] {
    display: none;
  }
`
export const FormDiv = styled.div`
  width: 60%;
  height: 100%;
  margin-top: 2rem;
`
export const Image = styled.img`
  width: 12rem;
  height: 12rem;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
  box-shadow: 0 0 5px grey;
`
export const IconDiv = styled.div`
  width: 50%;
  height: 10%;
  border-radius: 50%;
  border-style: solid 2px;
  border-color: black;
`

export const Inputs = styled.div`
  height: 40%;
  margin-bottom: 1rem;
  .Textearea {
    margin-bottom: 10rem;
  }
`
export const Label = styled.h1`
  color: var(--azul-escuro);
  margin-bottom: 2rem;
`

export const PasswordDiv = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
  gap: 1.5rem;
`

export const FirstLine = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  gap: 1.5rem;
`
export const Content = styled.div`
  display: flex;
  justify-content: center;
  width: 90%;
`
export const PhotoDiv = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
`
export const PhotoSend = styled.label`
  input[type='file'] {
    display: none;
  }
  color: var(--azul-escuro);
  display: flex;
  justify-content: center;
  padding: 6px 12px;
  font-size: 1.2rem;
  cursor: pointer;
  font-weight: bold;
  gap: 0.5rem;
`
export const Icone = styled.img`
  width: 1.2rem;
  margin-right: 1rem;
`

export const Username = styled.p`
  color: var(--azul-escuro);
  font-size: 1.2rem;
  font-weight: bold;
`
