import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import React, { useState, useRef } from 'react'

import Dropdown from '../Dropdown'
import * as styled from './styles'

const Select = ({ label, value, responsive, children, ...rest }) => {
  const [showDrop, setShowDrop] = useState(false)
  const focusRef = useRef(null)
  const toggleDropdown = () => {
    setShowDrop(!showDrop)
    console.log('show')
    focusRef.current?.scrollIntoView()
  }

  return (
    <styled.SelectContainer ref={focusRef} responsive={responsive}>
      {label && <styled.Label>{label}</styled.Label>}
      <styled.Select onClick={toggleDropdown}>
        <styled.ArrowIconWrapper rotateAt={showDrop}>
          <ArrowDropDownIcon sx={{ fontSize: 30 }} />
        </styled.ArrowIconWrapper>
        {value || 'Selecione'}
        {showDrop && <Dropdown offset={'3rem'}>{children}</Dropdown>}
      </styled.Select>
    </styled.SelectContainer>
  )
}

export default Select
