import Chrome from './Chrome'
import Firefox from './Firefox'
import Safari from './Safari'

const Install = ({ browser }) => {
  const BrowsersComponents = {
    Chrome: <Chrome />,
    Firefox: <Firefox />,
    Safari: <Safari />
  }

  return BrowsersComponents[browser] || <Chrome />
}

export default Install
