import styled from 'styled-components'

export const Container = styled.div`
  width: '100%';
  margin-bottom: 24px;
  flex: 1;
`

export const Label = styled.p`
  font-family: 'Montserrat';
  font-size: 18px;
  margin-bottom: 8px;
`
