import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1.8fr 4fr 1.5fr;
  border-bottom: 1px solid var(--cinza-medio);
  max-width: 31.25rem;
  width: 100%;
  height: auto;
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 0.313rem;
`

export const ImageContainer = styled.div`
  display: flex;
  place-content: center;
  align-items: center;
  width: 6rem;
  height: 6rem;
  margin: 1rem;
  background-color: var(--azul-escuro);
  border-radius: 50%;
  a {
    padding: 0;
    margin: 0;
    text-decoration: none;
    background-color: none;
  }
`

export const ImageProfile = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;
  justify-content: space-evenly;
`

export const NameUser = styled.h2`
  color: var(--azul-escuro);
  font-weight: 500;
  font-size: 1.35rem;
  padding-bottom: 5px;
`

export const UniversityUser = styled.h4`
  color: grey;
  font-weight: 500;
  padding-bottom: 5px;
`

export const CourseUser = styled.p`
  color: var(--azul-escuro);
  font-weight: 500;
  padding-bottom: 5px;
`

export const ButtonContainer = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: var(--azul-escuro);
  justify-content: center;
  border: none;
  margin-left: 1rem;
  transition: 0.2s;

  :hover {
    filter: brightness(0.9);
  }
`
