/* global Image */
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import DeleteIcon from '@mui/icons-material/Delete'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import PersonIcon from '@mui/icons-material/Person'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import * as styled from './styles'

const DisseminatorCard = ({ disseminator, onDelete, onApprove }) => {
  console.log('disseminator:', disseminator)
  const [transitioning, setTransitioning] = useState(true)
  const [showDesc, setShowDesc] = useState(false)
  const [buttonsHeight, setButtonsHeight] = useState()
  const [imgDimensions, setImgDimensions] = useState({ height: '100%' })
  const cardRef = useRef() // Reference to the card div, used to get the height of the dinamic component

  // Toggles between hidden and shown description
  const toggleShowDesc = () => {
    setTransitioning(true)
    setShowDesc(!showDesc)
  }

  // Function that extracts the current card height and saves it on a state
  const getCardSize = () => {
    const newHeight = cardRef.current.clientHeight
    setButtonsHeight(newHeight)
  }

  // useEffect triggered by the transitioning state.
  // Calls getCardSize function to calculate the new height of
  // the component while the buttons are set to hidden, so they
  // dont interfer with the expected height.
  useEffect(() => {
    if (transitioning) {
      getCardSize()
      setTransitioning(false)
    }
  }, [transitioning])

  useEffect(() => {
    // Sets transitioning to true when the screen
    // size changes, so that the buttons follow the card's height
    const resizeListener = () => setTransitioning(true)
    window.addEventListener('resize', resizeListener)

    // Creates a event listener that changes the profile image
    // styles according to it's size
    const img = new Image()
    img.src = disseminator.photo

    const imgLoadListener = () => {
      setImgDimensions(
        img.naturalHeight > img.naturalWidth
          ? { width: '100%' }
          : { height: '100%' }
      )
    }
    img.addEventListener('load', imgLoadListener)

    // useEffect cleanup function, removing window resize
    // and image load event listeners
    return () => {
      window.removeEventListener('resize', resizeListener)
      img.removeEventListener('load', imgLoadListener)
    }
  }, [])

  return (
    <styled.CardContainer ref={cardRef}>
      <styled.ContentContainer>
        <styled.DataContainer
          as={Link}
          to={`/profile/${disseminator.id}`}
        >
          <styled.ImageContainer>
            {disseminator.photo ? (
              <styled.Image
                src={disseminator.photo}
                style={imgDimensions}
              />
            ) : (
              <PersonIcon style={{ fontSize: '80px' }} />
            )}
          </styled.ImageContainer>
          <styled.DataWrapper>
            <styled.Title>{disseminator.partner}</styled.Title>
            <styled.Name>{disseminator.full_name}</styled.Name>
            <styled.InfoWrapper>
              <styled.Text>Telefone: {disseminator.number}</styled.Text>
              <styled.Text>Email: {disseminator.email}</styled.Text>
              <styled.Text>Site: {disseminator.site}</styled.Text>
            </styled.InfoWrapper>
          </styled.DataWrapper>
        </styled.DataContainer>
        <styled.ArrowWrapper
          onClick={toggleShowDesc}
          rotateAt={showDesc}
        >
          <ArrowDropDownIcon />
          {showDesc ? 'Esconder descrição' : 'Exibir descrição'}
        </styled.ArrowWrapper>
        {showDesc && (
          <styled.Description>
            {disseminator.description}
          </styled.Description>
        )}
      </styled.ContentContainer>
      <styled.ButtonsContainer
        style={{
          height: transitioning ? '0px' : buttonsHeight + 'px'
        }}
        transitioning={transitioning}
      >
        {!disseminator.approved_disseminator ? (
          <>
            <styled.ApproveButton onClick={onApprove}>
              <CheckCircleOutlineIcon style={{ fontSize: '2.5rem' }} />
            </styled.ApproveButton>
            <styled.DeleteButton onClick={onDelete}>
              <HighlightOffIcon style={{ fontSize: '2.5rem' }} />
            </styled.DeleteButton>
          </>
        ) : (
          <styled.ApproveButton onClick={onDelete} single>
            <DeleteIcon style={{ fontSize: '2.5rem' }} />
          </styled.ApproveButton>
        )}
      </styled.ButtonsContainer>
    </styled.CardContainer>
  )
}

export default DisseminatorCard
