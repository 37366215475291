import React from 'react'

import { useMobile } from '../../utils/useMobile'
import ContactMobile from './Mobile'
import ContactWeb from './Web'

const ContactUs = () => {
  if (useMobile()) {
    return <ContactMobile />
  } else {
    return <ContactWeb />
  }
}

export default ContactUs
