import debounce from 'lodash.debounce'
import React, { createContext, useCallback, useContext } from 'react'

const SearchConxtext = createContext()

const SearchProvider = ({ children }) => {
  const queryFunc = (callback) => callback()

  // Creates a memo'ed debounce function every time the queryFunc changes.
  // The queryFunc a callback parameter to the callDebouncer function
  const callDebouncer = useCallback(debounce(queryFunc, 500), [
    queryFunc
  ])

  return (
    <SearchConxtext.Provider value={{ callDebouncer }}>
      {children}
    </SearchConxtext.Provider>
  )
}

export const useSearch = () => useContext(SearchConxtext)

export default SearchProvider
