import React, { useState } from 'react'

import { useAuth } from '../../contexts/AuthContext'
import Button from '../Button'
import Reply from '../Reply'
import Textarea from '../Textarea'
import UserInfo from '../UserInfo'
import * as styled from './styles'

export const Comment = ({
  comment,
  onDelete,
  setReplyText,
  onReply,
  onReplyDelete
}) => {
  const [showReplyBox, setShowReplyBox] = useState(false)
  const { user, signed } = useAuth()

  const canDeleteComment = () => {
    return user?.id === comment?.user.id
  }

  return (
    <styled.CommentContainer>
      <styled.UserContent>
        <UserInfo user={comment.user} />
        {canDeleteComment() && (
          <styled.TrashIcon size="20" onClick={onDelete} />
        )}
      </styled.UserContent>
      <styled.Comment>{comment.description}</styled.Comment>
      <styled.ReplyButton
        type="button"
        onClick={() => {
          setShowReplyBox((prevState) => !prevState)
        }}
      >
        Responder
      </styled.ReplyButton>
      {showReplyBox && (
        <styled.ReplyBoxContainer>
          <Textarea
            onChange={(e) => {
              setReplyText(e.target.value)
            }}
            responsive
            height="6rem"
            disabled={!signed}
          />
          <Button
            onClick={() => {
              onReply(comment.id)
              setShowReplyBox(false)
            }}
          >
            Publicar
          </Button>
        </styled.ReplyBoxContainer>
      )}
      {comment.replies.map((reply) => (
        <Reply
          key={reply.id}
          reply={reply}
          onDelete={() => onReplyDelete(reply.id, comment.id)}
        />
      ))}
    </styled.CommentContainer>
  )
}

export default Comment
