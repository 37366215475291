import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  gap: 0.5rem;

  @media (max-width: 950px) {
    width: 85%;
  }
`

export const PreviewContainer = styled.div`
  max-height: 150vh;
  background-color: rgba(0, 0, 0, 0.85);
  position: relative;
  width: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.15));
`

export const ArrowWrapper = styled.button`
  cursor: pointer;
  box-sizing: border-box;
  position: absolute;
  top: calc(50% - 20px);
  ${(props) => (props.right ? 'right: -55px;' : 'left: -55px;')}
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: var(--cinza-medio);
  color: var(--cinza-escuro);
  border: 0;
  transition: all 300ms;
  &:hover {
    transform: scale(1.1);
    filter: brightness(0.85);
  }
`

export const Image = styled.img`
  width: 100%;
  object-fit: contain;
`

export const NoImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cinza-medio);
  color: var(--cinza-escuro);
  border-radius: 5px;
  width: 100%;
  aspect-ratio: 1;
`

export const DeleteButton = styled.button`
  cursor: pointer;
  background-color: var(--cinza-muito-claro);
  color: var(--azul-escuro);
  display: flex;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 50%;
  transition: all 300ms;
  &:hover {
    background-color: var(--cinza-medio);
  }
`
