import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'

import * as styled from './styles.js'

const PostAttachment = ({ attachment }) => {
  const [isImage, setIsImage] = useState(true)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(false)
    setIsImage(true)
  }, [attachment])

  const handleNotImage = () => {
    setLoading(false)
    setIsImage(false)
  }

  return (
    <styled.ImageContainer>
      {attachment &&
        (isImage ? (
          <styled.Image
            src={attachment}
            onError={handleNotImage}
            alt="Post attachment"
          />
        ) : (
          <ReactPlayer
            controls={!loading}
            volume={0.15}
            url={attachment}
            width="100%"
            height="100%"
            style={{ margin: 'auto' }}
          />
        ))}
    </styled.ImageContainer>
  )
}

export default PostAttachment
