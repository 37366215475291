import { FaTrash } from 'react-icons/fa'
import styled from 'styled-components'

export const ReplyContainer = styled.div`
  margin-left: 3rem;
  margin-bottom: 2rem;
`

export const UserContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ReplyBody = styled.div`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`

export const ReplyButton = styled.button`
  border: none;
  color: var(--azul-escuro);
  font-weight: bold;
  font-size: 1rem;
  background-color: white;
  margin-bottom: 1rem;
`

export const TrashIcon = styled(FaTrash)`
  color: var(--azul-escuro);
  cursor: pointer;
`
