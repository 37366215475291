import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  place-content: center;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;
  max-width: 26.313rem;
  width: 100%;
  gap: 1.5rem;
`

export const Title = styled.h1`
  color: var(--azul-escuro);
  font-weight: 500;
  font-size: 32px;
  margin-bottom: 2rem;
`
