import { animated } from 'react-spring'
import styled, { css } from 'styled-components'

const toastTypes = {
  info: css`
    background: var(--azul-escuro);
    color: white;
  `,
  sucess: css`
    background: #e6fffa;
    color: #2e656a;
  `,
  error: css`
    background: #e9546b;
    color: white;
  `
}

export const Container = styled(animated.div)`
  max-width: 22.5rem;

  position: relative;
  padding: 1rem 2rem 1rem 1rem;
  border-radius: 0.625rem;
  margin-bottom: 0.5rem;

  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);

  display: flex;

  ${(props) => toastTypes[props.type || 'info']}

  > svg {
    margin: 0.25rem 0.75rem 0 0;
  }

  div {
    flex: 1;

    p {
      margin-top: 0.25rem;
      font-size: 0.875rem;
      opacity: 0.8;
      line-height: 1.25rem;
    }
  }

  button {
    position: absolute;
    right: 0.5rem;
    top: 0.975rem;
    opacity: 0.6;
    border: 0;
    background: transparent;
    color: inherit;
  }
`
