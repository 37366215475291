import styled from 'styled-components'

export const Div = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: 100vh;
`

export const SubDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`

export const RegisterDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
`

export const Image = styled.img`
  width: 50%;
  height: 100%;
  object-fit: contain;
`

export const Logo = styled.img`
  width: 10rem;
  height: 3rem;
`

export const Paragraph = styled.p`
  font-size: 1.313rem;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  min-width: 17rem;
  max-width: 40rem;
`

export const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 4rem;
  margin-top: 1rem;
`

export const Label = styled.label`
  font-size: 1.125rem;
`

export const Input = styled.input`
  height: 2.5rem;
  gap: 2rem;
  width: 18rem;
  font-size: 1.125rem;
  padding-left: 0.5rem;
  border: 2px solid var(--cinza-medio);
  border-radius: 0.313rem;
  &:focus {
    border: 2px solid black;
    transition: border-color 0.3s ease-in-out;
    outline: 0;
  }
`

export const PasswordEyeIcon = styled.img`
  cursor: pointer;
  margin-left: -2.5rem;
  margin-bottom: -0.5rem;
`

export const ButtonDiv = styled.div`
  width: 17rem;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`

export const Button = styled.button`
  width: 14rem;
  height: 2.5rem;
  background-color: var(--azul-escuro);
  border-radius: 0.313rem;
  color: white;
  cursor: pointer;
  border: none;
  font-size: 1.125rem;
  transition: 0.2s;
  :hover {
    filter: brightness(0.9);
  }
`
