import PersonIcon from '@mui/icons-material/Person'
import MenuItem from '@mui/material/MenuItem'
import { csv } from 'd3'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'

import cursosGraduação from '../../../assets/csv/cursos_graduacao_unb.csv'
import iesCSV from '../../../assets/csv/ies.csv'
import logoImg from '../../../assets/logo.png'
import privacidade from '../../../assets/Política de Privacidade - Muron.pdf'
import termo from '../../../assets/Termo de Uso - Muron.pdf'
import AutocompleteInput from '../../../components/AutocompleteInput'
import Button from '../../../components/Button'
import SelectInput from '../../../components/SelectInput'
import TextInput from '../../../components/TextInput'
import { useToast } from '../../../hooks/useToast'
import api from '../../../services/api.js'
import * as Styled from './styles.js'

const RegisterSignUpMobile = () => {
  const history = useHistory()
  const {
    getValues,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'all' })
  const [page, setPage] = useState(0)
  const { addToast } = useToast()
  const returnType = () => {
    history.push('type')
    window.location.reload()
  }
  const [cursos, setCursos] = useState([])
  const [faculdades, setFaculdades] = useState([])
  const [colaboradorValue, setColaboradorValue] = useState(false)

  useEffect(() => {
    csv(cursosGraduação).then((data) => setCursos(data))
    csv(iesCSV).then((data) => setFaculdades(data))
  }, [])

  const renderButton = () => {
    if (page < 1) {
      return (
        <Button
          type="button"
          onClick={() => {
            setPage(page + 1)
          }}
        >
          Próximo
        </Button>
      )
    } else if (page === 1) {
      return (
        <Button type="button" onClick={handleSubmit(submitForm)}>
          Cadastrar
        </Button>
      )
    }
  }

  const submitForm = async (values) => {
    if (
      values.Situação === undefined ||
      values.Faculdade === undefined ||
      values.Faculdade === null ||
      (values.Curso === undefined && !colaboradorValue) ||
      (values.Curso === null && !colaboradorValue)
    ) {
      addToast({
        type: 'error',
        title: 'Não foi possível efetuar o cadastro',
        description: 'Preencha todos os campos!'
      })
      setPage(0)
      return
    }
    if (values.password !== values.passwordConfirmation) {
      addToast({
        type: 'error',
        title: 'Não foi possível efetuar o cadastro',
        description: 'As senhas devem ser iguais!'
      })
    } else {
      await api
        .post('/users', {
          username: values.userName,
          full_name: values.NomeCompleto,
          email: values.Email,
          password: values.password,
          password_confirmation: values.passwordConfirmation,
          situation: values.Situação,
          course: values.Curso?.nome || '',
          university: values.Faculdade.nome,
          is_disseminator: false,
          photo: PersonIcon
        })
        .then((res) => {
          if (res.status === 201) {
            addToast({
              type: 'sucess',
              title: 'Cadastro feito com sucesso',
              description: 'Bem-vinda(o) ao MurOn'
            })
            history.push('/email-confirmation/' + res.data.id, {
              fromRegisterPage: true
            })
          }
        })
        .catch(() =>
          addToast({
            type: 'error',
            title: 'Não foi possível efetuar o cadastro',
            description: 'Verifique as informações e tente novamente.'
          })
        )
    }
  }
  return (
    <Styled.Layout>
      <Styled.Content>
        <Link to="/">
          <Styled.ImageLogo src={logoImg} />
        </Link>
        <Styled.FormContainer>
          {page === 0 && (
            <section>
              <Styled.Title>
                Informe seus Dados <br />
                de Cadastro:
              </Styled.Title>
              <TextInput
                name="NomeCompleto"
                label="Nome Completo"
                control={control}
                mobile
                rules={{
                  required: {
                    value: true,
                    message: 'Este campo não pode ficar em branco'
                  }
                }}
                error={errors.NomeCompleto}
                helperText={errors.NomeCompleto?.message}
              />
              <TextInput
                name="Email"
                label="E-mail"
                control={control}
                mobile
                rules={{
                  required: {
                    value: true,
                    message: 'Este campo não pode ficar em branco'
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Endereço de email inválido'
                  }
                }}
                error={errors.Email}
                helperText={errors.Email?.message}
              />
              <SelectInput
                name="Situação"
                label="Situação"
                id="Situação"
                value={getValues('Situação')}
                control={control}
              >
                <MenuItem
                  onClick={() => {
                    setColaboradorValue(false)
                  }}
                  value={'Aluno'}
                >
                  Aluno
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setColaboradorValue(false)
                  }}
                  value={'Ex-aluno'}
                >
                  Ex-aluno
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setColaboradorValue(false)
                  }}
                  value={'Professor'}
                >
                  Professor
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setColaboradorValue(true)
                  }}
                  value={'Colaborador'}
                >
                  Colaborador
                </MenuItem>
              </SelectInput>
              <AutocompleteInput
                name="Faculdade"
                label="Faculdade"
                control={control}
                getOptionLabel={(option) => option.nome}
                options={faculdades}
              />
              <AutocompleteInput
                disabled={colaboradorValue}
                name="Curso"
                label="Curso"
                control={control}
                getOptionLabel={(option) =>
                  `${option.nome} - ${option.turno} (${option.grau_academico})`
                }
                options={cursos}
              />
            </section>
          )}
          {page === 1 && (
            <section>
              <Styled.Title>
                Quase lá! Agora, insira
                <br />
                seus dados de Login:
              </Styled.Title>
              <TextInput
                name="userName"
                label="Nome de Usuário/MurEr"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Este campo não pode ficar em branco'
                  },
                  validate: (value) =>
                    value.search(/muron/i) === -1 &&
                    value.search(/admin/i) === -1 &&
                    value.search(/adm/i) === -1
                }}
                error={errors.userName}
                helperText={
                  errors.userName?.message ||
                  (errors.userName?.type === 'validate' &&
                    'O nome de usuário não pode conter "muron","admin" ou "adm"')
                }
                mobile
              />
              <TextInput
                name="password"
                label="Senha"
                type="password"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Este campo não pode ficar em branco'
                  },
                  minLength: {
                    value: 8,
                    message:
                      'A senha deve conter no mínimo 8 caracteres'
                  }
                }}
                error={errors.password}
                helperText={errors.password?.message}
                mobile
              />
              <TextInput
                name="passwordConfirmation"
                label="Confirme sua Senha"
                type="password"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Este campo não pode ficar em branco'
                  },
                  minLength: {
                    value: 8,
                    message:
                      'A senha deve conter no mínimo 8 caracteres'
                  }
                }}
                error={errors.passwordConfirmation}
                helperText={errors.passwordConfirmation?.message}
                mobile
              />
              <Styled.Terms>
                Ao se cadastrar, você concorda com os{' '}
                <a href={termo} target="_blank" rel="noreferrer">
                  termos de uso
                </a>{' '}
                e com a{' '}
                <a href={privacidade} target="_blank" rel="noreferrer">
                  política de privacidade.
                </a>
              </Styled.Terms>
            </section>
          )}

          <Styled.ButtonDiv>
            <Button
              ghost
              type="button"
              onClick={() => {
                if (page === 0) {
                  returnType()
                } else {
                  setPage(page - 1)
                }
              }}
            >
              Voltar
            </Button>
            {renderButton()}
          </Styled.ButtonDiv>
        </Styled.FormContainer>
      </Styled.Content>
    </Styled.Layout>
  )
}

export default RegisterSignUpMobile
