import React from 'react'

import ResendVerification from '../../../assets/ResendVerification.png'
import Button from '../../../components/Button'
import Logo from '../../../components/Logo'
import Title from '../../../components/Title'
import * as styled from './styles'

const ResendEmailConfirmation = ({ methods }) => (
  <styled.PageContainer>
    <Logo style={{ height: '60px', marginBottom: '1.5rem' }} />
    <styled.ImageContainer>
      <styled.Image
        src={ResendVerification}
        alt="Imagem de reenviar email de verificação."
      />
    </styled.ImageContainer>
    <styled.TextContainer>
      <Title>Sua conta no MurOn foi cadastrada com sucesso!</Title>
      <styled.Text>
        Enviamos um email para confirmação da criação da conta. O email
        pode demorar alguns instantes para chegar.
      </styled.Text>
      <styled.Text>
        Ainda não recebeu o email? Verifique sua caixa de spam ou clique
        no botão abaixo para reenviar o email.
      </styled.Text>
      <Button onClick={methods.handleResend}>Reenviar email</Button>
      <styled.Text>
        Que tal instalar o MurOn no seu dispositivo?{' '}
        <a href="/about#instalar">Saiba mais</a>
      </styled.Text>
    </styled.TextContainer>
  </styled.PageContainer>
)

export default ResendEmailConfirmation
