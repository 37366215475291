import styled from 'styled-components'

export const Layout = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const FormContainer = styled.div`
  width: 80vw;
`
export const Title = styled.p`
  margin-bottom: 24px;
  text-align: center;
  font-size: 1.313rem;
`

export const ImageLogo = styled.img`
  width: 10rem;
  height: 3rem;
  object-fit: contain;
  margin-bottom: 40px;
`
export const Image = styled.img`
  margin-top: 1rem;
  width: 6rem;
  height: 2rem;
  object-fit: contain;
  align-self: center;
`
export const ImageDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
`

export const ButtonDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const Button = styled.button`
  width: 8rem;
  height: 2.5rem;
  background-color: white;
  border-radius: 3px;
  color: #3370e5;
  border-color: #3370e5;
  border-width: 0.01rem;
  font-size: 18px;
  :hover {
    background-color: #3370e5;
    color: white;
  }
`
