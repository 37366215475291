import React from 'react'

import * as styled from './styles'

const Button = ({ ghost, responsive, children, ...rest }) => {
  return (
    <>
      {ghost ? (
        <styled.GhostButton responsive={responsive} {...rest}>
          {children}
        </styled.GhostButton>
      ) : (
        <styled.Button responsive={responsive} {...rest}>
          {children}
        </styled.Button>
      )}
    </>
  )
}

export default Button
