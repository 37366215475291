import SearchIcon from '@mui/icons-material/Search'
import React from 'react'

import { useSearch } from '../../contexts/SearchContext'
import * as styled from './styles'

const SearchInput = ({ responsive, onSearch, ...rest }) => {
  const { callDebouncer } = useSearch()

  const handleSearch = (e) => {
    callDebouncer(() => onSearch(e.target.value))
  }

  return (
    <styled.ComponentContainer responsive={responsive}>
      <SearchIcon style={{ fontSize: '30px' }} />
      <styled.Input {...rest} onChange={handleSearch} />
    </styled.ComponentContainer>
  )
}

export default SearchInput
