import styled from 'styled-components'

export const SelectContainer = styled.div`
  width: 100%;
  ${(props) => !props.responsive && 'max-width: 350px;'}
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
`

export const Label = styled.h1`
  font-size: 18px;
  font-weight: 400;
`

export const Select = styled.div`
  cursor: pointer;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  height: 3rem;
  display: flex;
  align-items: center;
  background-color: white;

  font-size: 18px;
  border: 2px solid var(--cinza-medio);
  border-radius: 5px;
  padding: 0 0.5rem;

  &:focus {
    outline: none;
  }
`

export const ArrowIconWrapper = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(0deg);
  color: var(--cinza-escuro);
  transition: all 200ms ease-out;
  transform: ${(props) => (props.rotateAt ? 'rotate(180deg)' : '')};
`
