import DeleteIcon from '@mui/icons-material/Delete'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import commentsImage from '../../../assets/commentsImage.svg'
import UserInfo from '../../../components/UserInfo'
import { useAuth } from '../../../contexts/AuthContext.jsx'
import formatDate from '../../../utils/formatDate.js'
import ConfirmationModalMobile from '../../ConfirmationModal/Mobile'
import PostImages from '../../PostImages'
// eslint-disable-next-line import/namespace
import * as styled from './styles.js'

const Posts = ({ post }) => {
  const { user } = useAuth()
  const [modalConfirmation, setModalConfirmation] = useState(false)

  const date = formatDate(post.created_at)

  const handleDeletePostModal = () => {
    setModalConfirmation(true)
  }

  const handleDeletePostModalClose = () => {
    setModalConfirmation(false)
  }

  return (
    <>
      <styled.Container>
        <styled.UserContainer>
          <UserInfo user={post.creator} />
        </styled.UserContainer>
        <styled.ImageAjust>
          {post.attachments.length > 1 ? (
            <styled.ImageSide>
              <PostImages files={post.attachments} />
            </styled.ImageSide>
          ) : (
            <styled.ImageSideOne>
              <PostImages files={post.attachments} />
            </styled.ImageSideOne>
          )}
        </styled.ImageAjust>
        <styled.Title>
          <Link to={`/post/${post.id}`}>{post.title}</Link>
        </styled.Title>
        <styled.PostText>
          {post.content.length > 290
            ? post.content.substring(0, 290) + '...'
            : post.content}
        </styled.PostText>
        <styled.FooterContainer>
          <styled.IconsContainer>
            {!!user && user.is_admin && (
              <styled.DeleteButton onClick={handleDeletePostModal}>
                <DeleteIcon
                  style={{
                    color: 'var(--azul-escuro)',
                    fontSize: '2.2rem'
                  }}
                />
              </styled.DeleteButton>
            )}
            <Link to={`/post/${post.id}#comment`}>
              <styled.CommentsImage src={commentsImage} />
            </Link>
          </styled.IconsContainer>
          <styled.PostDate>{date}</styled.PostDate>
          <Link to={`/post/${post.id}`}>Ver publicação</Link>
        </styled.FooterContainer>
      </styled.Container>
      <ConfirmationModalMobile
        isOpen={modalConfirmation}
        post={post}
        handleDeletePostModalClose={handleDeletePostModalClose}
        text={'Deseja mesmo deletar esse post?'}
      />
    </>
  )
}

export default Posts
