import React, { useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai'
import { FiLogOut, FiSearch } from 'react-icons/fi'
import { GiHamburgerMenu } from 'react-icons/gi'
import { useHistory } from 'react-router-dom'

import Logo from '../../../assets/logo.png'
import UserInfo from '../../../components/UserImage'
import { useAuth } from '../../../contexts/AuthContext'
import { useSearch } from '../../../contexts/SearchContext'
import {
  BackDiv,
  CloseSearch,
  FilterLink,
  FilterLink2,
  FirstDiv,
  Header,
  Image,
  InputSearch,
  Search,
  SearchButton,
  SecondDiv,
  SideDivLoged,
  SideDivUnloged,
  ThirdDiv
} from './styles'

const HeaderMobile = ({ onSearch }) => {
  const history = useHistory()
  const { signed, logoutUser, user } = useAuth()
  const [open, setOpen] = useState(false)
  const [find, setFind] = useState(false)
  const [query, setQuery] = useState('')
  const { callDebouncer } = useSearch()

  const showSearch = onSearch !== undefined

  const LogOut = () => {
    logoutUser()
    history.push('/')
  }

  const handleChange = (e) => {
    setQuery(e.target.value)
    callDebouncer(() => onSearch(query))
  }

  const clearSearchAndCloseMenu = () => {
    setQuery('')
    setOpen(false)
    if (onSearch) onSearch('')
  }

  const openMenu = () => setOpen(true)
  const closeMenu = () => setOpen(false)

  if (signed) {
    return (
      <>
        {open ? (
          <>
            <SideDivLoged>
              <div>
                <FirstDiv>
                  <UserInfo
                    username={user.username}
                    photo={user.photo}
                  ></UserInfo>
                </FirstDiv>
                <SecondDiv>
                  <FilterLink to="/" onClick={clearSearchAndCloseMenu}>
                    Mural
                  </FilterLink>
                  <FilterLink to="/1" onClick={clearSearchAndCloseMenu}>
                    Divulgação
                  </FilterLink>
                  <FilterLink to="/2" onClick={clearSearchAndCloseMenu}>
                    Estágio/Trabalho
                  </FilterLink>
                  <FilterLink to="/3" onClick={clearSearchAndCloseMenu}>
                    Projeto
                  </FilterLink>
                </SecondDiv>
                <SecondDiv>
                  {user.is_admin ? (
                    <FilterLink2 to="/backoffice">
                      Administração
                    </FilterLink2>
                  ) : (
                    <></>
                  )}
                  <FilterLink2 to="/about">Sobre nós</FilterLink2>
                  <FilterLink2 to="/contact">Fale Conosco</FilterLink2>
                </SecondDiv>
              </div>
              <ThirdDiv
                onClick={() => {
                  LogOut()
                }}
              >
                <FiLogOut color="white" size={25} />
                <p>Sair</p>
              </ThirdDiv>
            </SideDivLoged>
            <BackDiv onClick={closeMenu}></BackDiv>
            <Header>
              <GiHamburgerMenu
                size={20}
                className="hamburguer"
                onClick={openMenu}
              />
              <FilterLink to="/" onClick={clearSearchAndCloseMenu}>
                <Image src={Logo}></Image>
              </FilterLink>
              <AiOutlineSearch
                size={20}
                className="search"
                color={showSearch ? 'black' : 'transparent'}
              />
            </Header>
          </>
        ) : (
          <Header>
            <GiHamburgerMenu
              size={20}
              className="hamburguer"
              onClick={openMenu}
            />
            <FilterLink to="/" onClick={clearSearchAndCloseMenu}>
              <Image src={Logo}></Image>
            </FilterLink>
            <SearchButton>
              <AiOutlineSearch
                size={20}
                className="search"
                color={showSearch ? 'black' : 'transparent'}
                onClick={showSearch ? () => setFind(true) : null}
              />
            </SearchButton>
            {find ? (
              <>
                <Search>
                  <FiSearch size="22px" color="#C5C5C5"></FiSearch>
                  <InputSearch
                    placeholder="Faça aqui sua pesquisa"
                    type="text"
                    value={query}
                    onChange={handleChange}
                    autoFocus
                  />
                </Search>
                <CloseSearch
                  onClick={() => setFind(false)}
                ></CloseSearch>
              </>
            ) : null}
          </Header>
        )}
      </>
    )
  } else {
    return (
      <>
        {open ? (
          <>
            <SideDivUnloged>
              <FirstDiv>
                <FilterLink to="/login">Faça Login</FilterLink>
                <FilterLink to="/type">Cadastre-se</FilterLink>
              </FirstDiv>
              <SecondDiv>
                <FilterLink to="/" onClick={clearSearchAndCloseMenu}>
                  Mural
                </FilterLink>
                <FilterLink to="/1" onClick={clearSearchAndCloseMenu}>
                  Divulgação
                </FilterLink>
                <FilterLink to="/2" onClick={clearSearchAndCloseMenu}>
                  Estágio/Trabalho
                </FilterLink>
                <FilterLink to="/3" onClick={clearSearchAndCloseMenu}>
                  Projeto
                </FilterLink>
              </SecondDiv>
              <SecondDiv>
                <FilterLink2 to="/about">Sobre nós</FilterLink2>
                <FilterLink2 to="/contact">Fale Conosco</FilterLink2>
              </SecondDiv>
            </SideDivUnloged>
            <BackDiv onClick={closeMenu}></BackDiv>
            <Header>
              <GiHamburgerMenu
                size={20}
                className="hamburguer"
                onClick={openMenu}
              />
              <FilterLink to="/" onClick={clearSearchAndCloseMenu}>
                <Image src={Logo}></Image>
              </FilterLink>
              <AiOutlineSearch
                size={20}
                className="search"
                color={showSearch ? 'black' : 'transparent'}
              />
            </Header>
          </>
        ) : (
          <Header>
            <GiHamburgerMenu
              size={20}
              className="hamburguer"
              onClick={openMenu}
            />
            <FilterLink to="/" onClick={clearSearchAndCloseMenu}>
              <Image src={Logo}></Image>
            </FilterLink>
            <SearchButton>
              <AiOutlineSearch
                size={20}
                className="search"
                color={showSearch ? 'black' : 'transparent'}
                onClick={showSearch ? () => setFind(true) : null}
              />
            </SearchButton>
            {find ? (
              <>
                <Search>
                  <FiSearch size="22px" color="#C5C5C5"></FiSearch>
                  <InputSearch
                    placeholder="Faça aqui sua pesquisa"
                    type="text"
                    value={query}
                    onChange={handleChange}
                    autoFocus
                  />
                </Search>
                <CloseSearch
                  onClick={() => setFind(false)}
                ></CloseSearch>
              </>
            ) : null}
          </Header>
        )}
      </>
    )
  }
}

export default HeaderMobile
