import React from 'react'

import ResendVerification from '../../../assets/ResendVerification.png'
import VerificationSuccess from '../../../assets/VerificationSuccess.png'
import Logo from '../../../components/Logo'
import TextLink from '../../../components/TextLink'
import Title from '../../../components/Title'
import * as styled from './styles'

const ConfirmEmail = ({ state, methods }) => (
  <>
    {!state.loading && (
      <styled.PageContainer>
        {state.success ? (
          <>
            <styled.ImageContainer>
              <styled.Image
                src={VerificationSuccess}
                alt="Imagem de verificação de email."
              />
            </styled.ImageContainer>
            <styled.TextContainer>
              <Logo style={{ height: '60px', marginBottom: '1rem' }} />
              <Title>Email verificado com sucesso!</Title>
              <styled.Text>
                Bem vindo ao MurOn!
                <br />
                <TextLink to="/login">Faça login na sua conta</TextLink>
              </styled.Text>
            </styled.TextContainer>
          </>
        ) : (
          <>
            <styled.ImageContainer>
              <styled.Image
                src={ResendVerification}
                alt="Imagem de reenviar email de verificação."
              />
            </styled.ImageContainer>
            <styled.TextContainer>
              <Logo style={{ height: '60px', marginBottom: '1rem' }} />
              <Title>Ops! Não foi possível verificar seu email.</Title>
              <styled.Text>
                Seu email de verificação está expirado.
                <br />
                <TextLink to="#" onClick={methods.resendToken}>
                  Reenviar email de confirmação?
                </TextLink>
              </styled.Text>
            </styled.TextContainer>
          </>
        )}
      </styled.PageContainer>
    )}
  </>
)

export default ConfirmEmail
