import React from 'react'

import * as styled from './styles'

const Textarea = ({ label, responsive, height, ...rest }) => {
  return (
    <styled.TextareaContainer responsive={responsive}>
      {label && <styled.Label>{label}</styled.Label>}
      <styled.Textarea height={height} {...rest} />
    </styled.TextareaContainer>
  )
}

export default Textarea
