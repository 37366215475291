import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import React, { useState } from 'react'
import { Controller } from 'react-hook-form'

import * as Styled from './styles'

export default function TextInput({
  name,
  label,
  rules,
  control,
  mobile,
  style = {},
  ...props
}) {
  const [showPassword, setShowPassword] = useState(false)

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Styled.Container>
          <Styled.Label>{label}</Styled.Label>
          <TextField
            {...field}
            {...props}
            style={{ width: mobile ? '100%' : 350, ...style }}
            id="outlined-basic"
            variant="outlined"
            size="small"
            type={
              props.type === 'password' && !showPassword
                ? 'password'
                : 'text'
            }
            InputProps={{
              endAdornment:
                props.type === 'password' ? (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ) : null
            }}
            fullWidth
          />
        </Styled.Container>
      )}
    />
  )
}
